import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Theme, createTheme, Components } from '@mui/material';
import { colors } from './color';

const typographyOptions: TypographyOptions = {
  fontFamily: 'Poppins',
  h1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '42px',
    color: colors.basics.white,
    textAlign: 'center',
  },
  h2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
    color: colors.basics.white,
    textAlign: 'center',
  },
  body1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '30px',
    color: colors.basics.white,
  },
  body2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '180%',
    color: colors.basics.white,
  },
  subtitle1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '21px',
    letterSpacing: '-0.02em',
    textTransform: 'capitalize',
    color: colors.basics.white,
  },
  subtitle2: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: colors.basics.white,
  },
};

const componentOptions: Components<Theme> = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        boxSizing: 'border-box',
        borderRadius: 8,
        textTransform: 'capitalize',
      },
      contained: ({ theme }) => ({
        boxShadow: theme.shadows[0],
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        height: '50px',
        lineHeight: '24px',
        alignItems: 'center',
        color: colors.basics.theme,
        background: colors.basics.theme,
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        '&:disabled': {
          background: colors.basics.theme,
          color: colors.basics.theme,
        },
      }),
      outlined: ({ theme }) => ({
        boxShadow: theme.shadows[0],
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        height: '50px',
        lineHeight: '24px',
        alignItems: 'center',
        color: colors.basics.primary,
        border: `1px solid ${colors.basics.primary}`,
        background: colors.dark[800],
        '&:hover': {
          boxShadow: theme.shadows[0],
          background: colors.dark[800],
          border: `1px solid ${colors.basics.primary}`,
        },
        '&:disabled': {
          color: colors.basics.primary,
        },
      }),
      text: ({ theme }) => ({
        maxHeight: '24px',
        minWidth: 0,
        height: '100%',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        alignItems: 'center',
        color: colors.basics.primary,

        '& .MuiButtonBase-root': {
          p: 0,
          background: 'red',
        },
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: '100%',
        maxHeight: '52px',
        height: '100%',
        border: '0',
        borderRadius: '10px',
        background: colors.dark[600],
        '& fieldset': {
          maxHeight: '52px',
          borderWidth: '0',
          height: '100%',
        },
        '& input::placeholder': {
          fontStyle: 'normal',
          fontWeight: 500,
          // fontSize: 16,
          lineHeight: '180%',
          alignItems: 'center',
          color: colors.gray[500],
          opacity: 1,
        },
        '&:active': {
          outline: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
        '& .MuiOutlinedInput-input': {
          paddingLeft: '5px',
          '& fieldset': {
            borderWidth: 0,
          },
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          color: colors.basics.white,
        },

        '& .MuiOutlinedInput-root': {
          paddingRight: '10px',
          paddingLeft: '10px',
        },
        '& .Mui-focused fieldset': {
          border: 'none',
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 60,
        height: 30,
        padding: 0,
        borderRadius: '80px',
        background: '#343434',
        '& .MuiSwitch-switchBase': {
          padding: 3,
          margin: 2,

          '&.Mui-checked': {
            width: '80%',
            color: '#fff',
          },
        },
        '& .MuiSwitch-thumb': {
          width: 20,
          height: 20,
          p: 0,
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          background: colors.basics.theme,
        },
      }),
    },
  },

  MuiMenu: {
    styleOverrides: {
      root: ({ ownerState, theme }: { ownerState: any; theme: Theme }) => ({
        '& .MuiMenuItem-root': {
          background: colors.dark[600],
          padding: '2px',
        },
        '& .MuiPaper-root': {
          overflow: 'auto',
          overflowX: 'none !important',
          overflowY: 'none !important',
          '& ul': {
            width: `unset !important`,
          },
        },
        '& .MuiMenu-paper': {
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;',
          maxHeight: '30% !important',
          borderRadius: '6px',
          marginTop: '10px',
          background: colors.dark[600],
        },
        '& .Mui-selected': {
          // background:`${colors.dark[100]} !important`
          background: '#2a2a2c !important',
          // width:'none',
        },
        '& .MuiList-root-MuiMenu-list': {
          // width:"0 !important"
        },
        '& .MuiMenu-list': {
          width: 'none',
          borderRadius: '10px',
          // paddingTop: '5px',
          // paddingBottom: '5px',
          // paddingRight: '20px !important',
          // paddingLeft: '20px !important',
          background: colors.dark[600],
          // '& ul':{

          // },
          '& li': {
            paddingTop: '14px',
            paddingBottom: '14px',
            background: colors.dark[600],
            borderBottom: `1px solid ${colors.dark[100]} `,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            height: '100%',
            maxHeight: '240px',
            '& p': {
              padding: '0px 10px',
            },

            '&:last-child': {
              borderBottom: 'none',
            },
          },
        },
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ ownerState, theme }: { ownerState: any; theme: Theme }) => ({
        background: `${colors.dark[600]} !important`,
        '&:hover': {
          background: colors.dark[600],
        },
        '&.Mui-selected:hover': {
          background: colors.dark[600],
        },
        '&.Mui-selected': {
          background: colors.dark[600],
        },
        '&.Mui-focusVisible': {
          background: colors.dark[600],
        },
      }),
    },
  },
};

let theme: Theme = createTheme({
  typography: typographyOptions,
  components: componentOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1024,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: colors.basics.primary,
    },
  },
});

export { theme };
