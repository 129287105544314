import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
const ProtectedRoute = ({ children }) => {
  const [token] = useCookies('accessToken');
  const func = useCallback(() => {
    if (typeof token?.accessToken === 'string') {
      return true;
    } else {
      return false;
    }
  }, [token]);

  return func() ? children : <Navigate to='/sign-in' />;
};

export default ProtectedRoute;
