import { createContext, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {  useNavigate } from 'react-router-dom';

const authContext = createContext();

export function AuthContextProvider({ children }){

  const [cookie] = useCookies();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!cookie?.accessToken) {
  //     navigate('/');
  //   }
  // }, [cookie]);

  return (
    <authContext.Provider
      value={{
        accessToken: cookie?.accessToken,
        refreshToken: cookie?.refreshToken,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
