import { Box, Button, TextField, Typography } from "@mui/material"
import Close from "../../assets/images/Close.png"
import Image from "../common/Image"
import { makeStyles, createStyles } from '@mui/styles';
import { useRef, useState } from "react";
import { colors } from "../../styles/color";
import { TextAreaInput } from "../TextAreaInput";
import FileAttachment from "../common/FileAttachment";
import LinkImage from "../../assets/images/Link.png"
import { useDispatch } from "react-redux";
import { setDialogState, setLoading } from "../../redux/features";
import ReCAPTCHA from "react-google-recaptcha"
import { sendInquiry } from "../../redux/features/sendInquiry/sendInquiry";
import { ToastContainer } from "react-toastify";
import { ToastMessage } from "../../helpers/ToastMessage";
import * as yup from 'yup';
import { useParams } from "react-router-dom";
import AddLink from "../common/AddLinks";
import ChatFilesDisplayContent from "../common/ChatFilesDisplayContent";
import { isValidUrl } from "../../utils/commanFunctions/commanFunctions";
import { ImageIcon } from "../../assets/icons";
import ErrorTextBox from "../common/ErrorTextBox";
import CkEditoComponent from "../common/CkEditorComponent";

const useStyles: any = makeStyles(theme =>
    createStyles({
        close: {
            marginLeft: "auto"
        },
        linkImage: {
            padding: "13px 15px",
            border: `1.5px solid ${colors.gray[500]}`,
            borderRadius: "23%"
        }, fileInputIcon: {
            padding: "12px 20px",
            border: `1.5px solid ${colors.gray[500]}`,
            borderRadius: "23%"
        }, svgImage: {
            display: "block",
            margin: "auto"
        },
        subjectBg: {
            background: colors.basics.black,
            borderRadius: "10px",
            padding: "20px"
        },
        input: {
            borderRadius: "0px !important",
            "&.Mui-focused": {
                borderBottom: "1px solid red"
            }
        },
    })
);

interface FormErrors {
    subject?: string;
    description?: string;
    captcha?: string;
}
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

const InquiryModal = (props: any) => {
    let params = useParams();
    const [subject, setSubject] = useState("")
    const [openLink, setOpenLink] = useState(false)
    const [description, setDescription] = useState("")
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [formErrorsUrl, setFormErrorsUrl] = useState("");
    const [files, setFiles] = useState<File[]>([]);
    const [url, setUrl] = useState("")
    const [multipleUrls, setMultipleUrls] = useState<any>([])

    const captchaRef = useRef<any>(null);
    const classes = useStyles();
    const dispatch = useDispatch()

  
    const schema = yup.object().shape({
        subject: yup
            .string()
            .required('Subject is required')
            .min(3, 'Subject must be at least 3 characters'),
        description: yup
            .string()
            .required('Description is required')
            .min(3, 'Description must be at least 3 characters'),
        captcha: yup
            .string()
            .required('Captcha is required')
    });

    const urlSchema = yup.object().shape({
        url: yup
            .string()
            .required('Please enter Url')
    })

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const imageFiles: any = event.target.files;
        let isValid = true
        for (var i = 0; i < imageFiles.length; i++) {
            const fileSize = imageFiles[i].size;
            var fileSizes = Math.round((fileSize / 1024));
            if (fileSizes > 1024 * 5) {
                isValid = false
            }
        }

        if (isValid) {
            setFiles(Array.prototype.slice.call(imageFiles))
        } else {
            ToastMessage({
                type: "error",
                message: "Please select a file less than 5MB."
            })
        }

    }

    const successValidation = async () => {
        setFormErrors({});
        dispatch(setLoading(true))
        const formData = new FormData()
        formData.append("channel_id", params.id || "");
        formData.append("subject", subject);
        if (multipleUrls.length > 0) {
            formData.append("link", multipleUrls.toString());
        }

        if (files.length > 0) {
            files.map((f, i) => {
                formData.append(`attachment[${i}]`, f);
            })
        }

        formData.append("message", description);

        if (files.length > 5) {
            ToastMessage({
                type: 'error',
                message: "Maximum 5 attachments allowed."
            });
            dispatch(setLoading(false))
        } else if (multipleUrls.length > 1) {
            ToastMessage({
                type: 'error',
                message: "Only 1 url at a time"
            });
            dispatch(setLoading(false))
        } else {
            if (files.length > 0) {
                files.forEach((f, i) => {
                    formData.append(`attachment[${i}]`, f)
                })
            }
            const res = await dispatch(sendInquiry(formData));

            if (res.error) {
                ToastMessage({
                    type: 'error',
                    message: res.error.message,
                });
                dispatch(setLoading(false))
            } else {
                setSubject("")
                setDescription("")
                setMultipleUrls([])
                setUrl("")
                setFormErrorsUrl("")
                setFiles([])
                dispatch(setLoading(false))
                ToastMessage({
                    type: 'success',
                    message: res.payload.message,
                });
                dispatch(
                    setDialogState({
                        loginDialog: false,
                        registerDialog: false,
                        modal: false,
                        otpDialog: { otpDialog: false, fromPageName: '' },
                        privacyDialog: false,
                        resetPassword: false,
                        successReset: false,
                        termsDialog: false,
                        forgotPassword: false,
                        sendInquiry: false
                    })
                );
            }
        }
    }

    const sendDetails = async (e: any) => {
        const obj = {
            subject: subject,
            description: description,
            link: multipleUrls.toString(),
            captcha: captchaRef.current.getValue()
        }

        e.preventDefault();
        schema
            .validate(obj, { abortEarly: false })
            .then(async () => {
                successValidation()
            })
            .catch((err) => {
                const errors: any = {};
                err?.inner?.forEach((e: any) => {
                    errors[e.path] = e.message;
                });
                setFormErrors(errors);
            });
    }

    const linkModalClose = () => {
        setOpenLink(false)
        setUrl("")
        setFormErrorsUrl("")
    }

    const linkSuccess = () => {
        const obj = {
            url: url
        }
        urlSchema
            .validate(obj, { abortEarly: false })
            .then(async () => {
                if (isValidUrl(url)) {
                    const urlArr = [...multipleUrls]
                    urlArr.push(url)
                    setMultipleUrls(urlArr)
                    setOpenLink(false)
                    setUrl("")
                    setFormErrorsUrl("");
                } else {
                    setFormErrorsUrl("Enter Valid Url");
                }

            })
            .catch((err: any) => {
                const errors: any = {};
                err?.inner?.forEach((e: any) => {
                    errors[e.path] = e.message;
                });
                setFormErrorsUrl(errors.url);
            });
    }

    const handleDeleteUrl = (index: any) => {
        const newUploadedFiles = [...multipleUrls];
        newUploadedFiles.splice(index, 1);
        setMultipleUrls(newUploadedFiles);
    }

    const handleDeleteFiles = (index: any) => {
        const newUploadedFiles = [...files];
        newUploadedFiles.splice(index, 1);
        setFiles(newUploadedFiles);
    }

    return (
        <Box sx={{ padding: { sm: "25px", xs: "15px 0" } }}>
            <ToastContainer />
            <Box sx={{ display: "flex", height: "77px" }}>
                <Typography variant="h2">Inquiry</Typography>
                <Image style={{ cursor: "pointer" }} height={37} width={37} onClick={() => {
                    dispatch(
                        setDialogState({
                            loginDialog: false,
                            registerDialog: false,
                            modal: false,
                            otpDialog: { otpDialog: false, fromPageName: '' },
                            privacyDialog: false,
                            resetPassword: false,
                            successReset: false,
                            termsDialog: false,
                            forgotPassword: false,
                            sendInquiry: false
                        })
                    );
                }} className={classes.close} src={Close} />
            </Box>

            <Box className={classes.subjectBg}>
                <TextField className={classes.input} sx={{
                    borderBottom: `1px solid ${colors.dark[100]}`,
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "white !important",
                    background: colors.basics.black,
                }} placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                {formErrors.subject && <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: "3px" }}>{formErrors.subject}</div>}
                <Box>
                    <Box sx={{ "& .ck-editor ": {
                    backgroundColor:  colors.basics.black,
                    padding: "20px",
                    // borderRadius: "10px",
                    height:"200px",
                    overflow:'auto'
                  },
                  ".ck.ck-editor__main>.ck-editor__editable": {
                    backgroundColor: "transparent",
                    border: 0
                  },
                  "& .ck-editor__top ": {
                    display: "none"
                  },

                  "& .ck-content p ": {
                    background: "none",
                    border: "none",
                    marginTop: "0 !important",
                    marginBottom: "15px !important"
                  },
                  "& .ck-content p a": {
                    color: "#A175FF !important",
                    textDecoration: "none"
                  },
                  "& .ck-content p:last-child ": {
                    marginBottom: "0 !important"
                  },
                  '& .ck-editor':{
                    paddingLeft:"5px",
                    fontStyle:"normal",
                    fontWeight:"500",
                    fontSize:"16px",
                    lineHeight:"24px",
                    color:"#FFFFFF"},
                    '& .ck.ck-editor__editable_inline':{
                        padding:'0'
                    }
                  }}>

                <CkEditoComponent data={description} setData={setDescription} name={'inputText'} placeholder={'Write Your description here...'} />
                    </Box>

                    {/* <TextAreaInput
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "24px",
                            colors: colors.gray[300]
                        }}
                        background={colors.basics.black}
                        title=""
                        name='description'
                        value={description}
                        onChange={(e: any) => {
                            setDescription(e.target.value)
                        }}
                        placeholder='Write Your description here...'>
                    </TextAreaInput> */}
                </Box>
                {formErrors.description && <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: "3px" }}>{formErrors.description}</div>}
            </Box>
            <ReCAPTCHA
                className={classes.recaptcha}
                sitekey={siteKey}
                ref={captchaRef}
                style={{
                    backgroundColor: colors.dark[800],
                    paddingTop:'20px',
                }}
            />
            <ErrorTextBox>{formErrors.captcha}</ErrorTextBox>
            <Box
                // borderTop={`1px solid ${colors.dark[100]}`}
                display='flex'
                sx={{
                    width: '100%',
                    margin: "10px 0px",
                    overflowY: 'hidden',
                    '&::-webkit-scrollbar ': {
                        height: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        color: 'red',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                }}
            >
                {files.length > 0 && files.map((file: any, index: any) => {
                    return (
                        <ChatFilesDisplayContent image={<ImageIcon />}
                            name={file.name}
                            handleDeleteClick={(index: any) => handleDeleteFiles(index)}
                            index={index} />
                    )
                })}
            </Box>

            <Box
                borderTop={files.length > 0 && multipleUrls.length > 0 ? `1px solid ${colors.dark[100]}` : "0"}
                display='flex'
                sx={{
                    width: '100%',
                    overflowY: 'hidden',
                    '&::-webkit-scrollbar ': {
                        height: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        color: 'red',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                }}
            >
                {multipleUrls.length > 0 && multipleUrls.map((file: any, index: any) => {
                    return (
                        <ChatFilesDisplayContent image={<Image src={LinkImage} />}
                            name={file}
                            handleDeleteClick={(index: any) => handleDeleteUrl(index)}
                            index={index} />
                    )
                })}
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
                <Button sx={{
                    minWidth: "142px"
                }} onClick={(e: any) => {
                    sendDetails(e)
                }} >Send</Button>
                <Box className="cursor-pointer" sx={{ margin: { sm: "0 20px", xs: "0 10px" } }}>
                    <FileAttachment svgImage={classes.svgImage} inputClassName={classes.fileInputIcon} handleFileInputChange={(e: any) => handleFileInputChange(e)} multiple={true} />
                </Box>
                <Box className="cursor-pointer" sx={{ position: "relative" }}>
                    <Image onClick={() => {
                        setOpenLink(true)
                    }} className={classes.linkImage} src={LinkImage} />
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        transform: "translate(0%, -118%)",
                        width: "290px",
                        borderRadius: "8px",
                        "& > .MuiBox-root": {
                            border: "1px solid #3e3e3e",
                        }
                    }}>
                        {openLink && <AddLink
                            label={"Link Url"}
                            error={formErrorsUrl}
                            value={url}
                            onChange={(e: any) => setUrl(e.target.value)}
                            onSuccess={() => { linkSuccess() }}
                            onClose={() => linkModalClose()}
                        />}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default InquiryModal