import { Box, Button, Dialog, Typography } from '@mui/material';
import { colors } from '../../styles/color';
import { CompleteLogo } from '../../assets/icons';
import { useAppSelector } from '../../hooks/useAppReducer';
import { ApplicationStateType } from '../../redux/store';
import { useCookies } from 'react-cookie';
import { setProfileState } from '../../redux/features/profile/profileSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ResetPassword = (props: any) => {
  const navigate = useNavigate();
  const {
    dialog: { resetPassword: resetPassword },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);
  const [token, __, removeAccessTokenToken] = useCookies([
    'accessToken',
    'refreshToken',
    'googleToken',
    'role',
  ]);
  const dispatch = useDispatch();
  const onResetClick = () => {
    if (token.role === '2') {
      removeAccessTokenToken('accessToken');
      removeAccessTokenToken('refreshToken');
      removeAccessTokenToken('googleToken');
      removeAccessTokenToken('role');
      localStorage.removeItem('login');
      dispatch(setProfileState({}));
      navigate('/sign-in');
    } else {
      removeAccessTokenToken('accessToken');
      removeAccessTokenToken('refreshToken');
      removeAccessTokenToken('googleToken');
      removeAccessTokenToken('role');
      localStorage.removeItem('login');
      dispatch(setProfileState({}));
      navigate('/');
    }
  };

  return (
    <Box textAlign="center" m={'36px'}>
      <Box
        sx={{
          '& svg': {
            width: '120px',
            height: '120px',
          },
        }}
      >
        <CompleteLogo />
      </Box>
      <Box mt={'30px'}>
        <Typography variant="body1" fontWeight={600}>
          Password reset
        </Typography>
        <Typography variant="body2" color={colors.gray[300]} mt={'12px'} p={'0px 14px'}>
          Your password has been reset successfully
        </Typography>
      </Box>
      <Box width={'100%'} mt={'32px'}>
        <Button
          sx={{ width: '100%', maxWidth: resetPassword ? '438px' : 'auto' }}
          onClick={() => onResetClick()}
        >
          {resetPassword ? 'Back to Login' : 'Ok'}
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPassword;
