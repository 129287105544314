import { Autocomplete, InputAdornment, TextField, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../styles/color';

const useStyles: any = makeStyles({
  paper: {
    background: `${colors.dark[600]} !important`,
    '&.MuiAutocomplete-paper': {
      borderRadius: '10px',
      color: colors.basics.white,
      '&:hover': {
        background: `${colors.dark[600]} !important`,
      },
      '& .MuiAutocomplete-option': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
      },
      '& .MuiAutocomplete-noOptions': {
        background: colors.dark[600],
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: { md: '16px', xs: '13px' },
        lineHeight: '24px',

        color: colors.basics.white,
      },
    },
    '& .MuiAutocomplete-listbox': {
      paddingLeft: '0',
      paddingRight: '0',
    },
    '&:hover': {
      background: colors.dark[600],
    },
    '& ul': {
      borderRadius: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingRight: '20px !important',
      paddingLeft: '20px !important',
      background: colors.dark[600],
      '&:hover': {
        background: colors.dark[600],
        borderRadius: '10px',
      },
    },
    '& li': {
      paddingTop: '20px',
      paddingBottom: '20px',
      background: colors.dark[600],
      borderBottom: `1px solid ${colors.dark[100]}`,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: { md: '16px', xs: '13px' },
      lineHeight: '24px',
      height: '100%',
      maxHeight: '240px',
      paddingRight: '0',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
});

interface autoCompleteProps {
  data?: any;
  value?: any;
  EndIcon?: JSX.Element;
  disableClearable?: boolean;
  onChange?: any;
  style?: React.CSSProperties;
  renderGroup?: any;
  open?: boolean;
  renderOption?: any;
  title?: string;
  placeholder?: string;
  StartIcon?: any;
  getOptionLabel?: any;
  readOnly?: any;
}

export default function AutoComplete(props: autoCompleteProps) {
  const {
    data,
    value,
    EndIcon,
    disableClearable,
    onChange,
    style,
    renderGroup,
    open,
    title,
    StartIcon,
    getOptionLabel,
    readOnly,
  } = props;
  const classes = useStyles();

  return (
    <Box>
      {title && (
        <Typography
          sx={{ color: colors.gray[300] }}
          variant='body2'
          lineHeight='140%'
          mb='8px'
          pl='2px'
          textTransform='capitalize'
        >
          {title}
        </Typography>
      )}
      <Autocomplete
        // freeSolo
        readOnly={readOnly}
        open={open}
        getOptionLabel={getOptionLabel}
        value={value}
        disableClearable={disableClearable}
        options={data?.map((option: any) => option)}
        renderOption={(props, option: any) => (
          <Box
            component='li'
            sx={{
              display: 'flex',
              justifyContent: 'space-between !important',

              '& .MuiAutocomplete-noOptions': {
                color: colors.basics.white,
              },

              background: colors.dark[600],
            }}
            {...props}
          >
            <Box>{option} </Box>
            <Button variant='text'>Add</Button>
          </Box>
        )}
        renderGroup={renderGroup}
        classes={{ paper: classes.paper }}
        onChange={(event: any, newValue: any | null) => {
          onChange && onChange(newValue);
        }}
        sx={{
          width: '100%',
          borderRadius: '10px',
          background: `${colors.dark[600]} !important`,
          color: colors.basics.white,
          '&:hover': {
            background: colors.dark[600],
          },
          '& .MuiAutocomplete-inputRoot': {
            paddingRight: '0px !important',
          },
          '& input::placeholder': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { md: '16px', xs: '13px' },
            lineHeight: '24px',
            color: colors.gray[500],
          },
          ...style,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Select channel'
            InputProps={{
              ...params.InputProps,
              type: 'text',
              endAdornment: EndIcon ? (
                <InputAdornment position='start' sx={{ marginRight: { xs: '20px' } }}>
                  <Box display='flex'>{EndIcon}</Box>
                </InputAdornment>
              ) : (
                <></>
              ),
              startAdornment: StartIcon ? (
                <InputAdornment position='start' sx={{ marginRight: { xs: '20px' } }}>
                  <Box display='flex'>{StartIcon}</Box>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
