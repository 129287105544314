import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles/color';
import Topbar from '../../components/common/Topbar';
import {
  ArrowLeftGray,
  CallIcon,
  Close,
  Download,
  ImageIcon,
  MoreOption,
  SendIcon,
} from '../../assets/icons';
import Image from '../../components/common/Image';
import VideoPlayer from '../../assets/images/VideoPlayer2.png';
import InquiryChat from '../../components/common/InquiryChat';
import ReplyChat from '../../components/common/ReplyChat';

export const InquiryDetails = (props: any) => {
  const navigate = useNavigate();
  const { inquiryMessageList, loading, fetchList } = props;

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
          display: 'flex',
          justifyContent: 'space-around',
        },
      }}
    >
      <Topbar>
        <Box
          display={'flex'}
          alignItems={'center'}
          textAlign={'center'}
          width='100%'
          sx={{ pl: { xl: 0, xs: '20px' } }}
        >
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box mr={'15px'} onClick={() => navigate('/dashboard/inquiry')} sx={{ cursor: 'pointer' }}>
              <Typography variant='body1' fontSize={'18px'} lineHeight='27px' color={colors.gray[300]}>
                Inquiry
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} mr={'15px'}>
              <ArrowLeftGray />
            </Box>
            <Box>
              <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                Inquiry Details
              </Typography>
            </Box>
          </Box>
        </Box>
      </Topbar>
      {!loading ? (
        <Box p='0px 16px' mt={'16px'} mb={'16px'}>
          <Box bgcolor={colors.dark[800]} borderRadius='12px'>
            <Box
              height={{ md: 'calc(100vh - 175px)', xs: 'calc(100vh - 160px)' }}
              flexDirection='column'
              flex='1'
              display={'flex'}
              p={{ md: '20px 40px', xs: '20px' }}
              overflow={'auto'}
              sx={{
                pt: '70px',
                pb: '30px',
              }}
            >
              {Object.keys(inquiryMessageList).length > 0 && (
                <Box
                  display={{ sm: 'flex', xs: 'display' }}
                  justifyContent={'space-between'}
                  textAlign='left'
                  borderBottom={`1px solid ${colors.dark[100]}`}
                  pb='10px'
                >
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                    <Box display={'flex'} mr='20px'>
                      <Typography variant='body2' color={colors.gray[300]}>
                        {inquiryMessageList?.inquiry_id}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Image width={26} height={18} src={VideoPlayer} />
                      <Typography variant='body2' color={colors.gray[300]} marginLeft={'12px'}>
                        {inquiryMessageList?.channel?.channel_name}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography
                        variant='h2'
                        sx={{ textAlign: 'left', lineHeight: '1.5', fontSize: '18px' }}
                      >
                        {inquiryMessageList?.subject}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              <InquiryChat
                inquiry={Object.keys(inquiryMessageList).length > 0 ? inquiryMessageList?.messages : []}
              />

              {Object.keys(inquiryMessageList).length > 0 && (
                <ReplyChat fetchList={() => fetchList()} profileImage={inquiryMessageList?.profile} />
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
