import { YouTubePage } from '../pages/social/YouTubePage';
import SocialPage from '../pages/social/SocialPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import InquiryPage from '../pages/inquiry/InquiryPage';
import ProfilePage from '../pages/profile/ProfilePage';
import SettingsPage from '../pages/settings/SettingPage';
import HelpCategarys from '../pages/settings/HelpCategarys';
import { AddChannel } from '../pages/social/AddChannel';
import { EditProfile } from '../pages/profile/EditProfile';
import { ChangePassword } from '../pages/profile/ChangePassword';
import ProtectedRoute from '../components/ProtectedRoute';
import HelpPage from '../pages/help/HelpPage';
import {
  Dashboard,
  Help,
  InquiryIcon,
  ProfileIcon,
  SettingIcon,
  SocialIcon,
  YoutubeIcon,
} from '../assets/icons';
import CommanChat from '../components/common/CommanChat';

const appRoutes: any = [
  {
    color: true,
    flag: true,
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
    state: 'dashboard',
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <Dashboard />,
    },
  },
  {
    color: true,
    flag: true,
    path: '/dashboard/social',
    element: (
      <ProtectedRoute>
        <SocialPage />
      </ProtectedRoute>
    ),
    state: 'social',
    sidebarProps: {
      displayText: 'Social',
      icon: <SocialIcon />,
    },
    child: [
      {
        color: false,
        flag: true,
        path: '/dashboard/social/youtube',
        element: (
          <ProtectedRoute>
            <YouTubePage />
          </ProtectedRoute>
        ),
        state: 'social.youtube',
        sidebarProps: {
          displayText: 'YouTube',
          icon: <YoutubeIcon />,
        },
      },
      // {
      //   color: false,
      //   flag: true,
      //   path: '/dashboard/social/youtube',
      //   element: (
      //     <ProtectedRoute>
      //       <YouTubePage />
      //     </ProtectedRoute>
      //   ),
      //   state: 'social.youtube',
      //   sidebarProps: {
      //     displayText: 'Instagram',
      //     icon: <YoutubeIcon />,
      //   },
      // },
    ],
  },
  {
    flag: false,
    path: '/dashboard/add-channel',
    element: (
      <ProtectedRoute>
        <AddChannel />
      </ProtectedRoute>
    ),
    state: 'add-channel',
  },
  {
    color: true,
    flag: true,
    path: '/dashboard/inquiry',
    element: (
      <ProtectedRoute>
        <InquiryPage />
      </ProtectedRoute>
    ),
    state: 'inquiry',
    sidebarProps: {
      displayText: 'Inquiry',
      icon: <InquiryIcon />,
    },
  },
  {
    flag: false,
    path: '/dashboard/inquiry/:id',
    element: (
      <ProtectedRoute>
        <CommanChat />
      </ProtectedRoute>
    ),
    state: 'inquiry',
    sidebarProps: {
      displayText: 'Inquiry',
      icon: <InquiryIcon />,
    },
  },
  {
    color: true,
    flag: true,
    path: '/dashboard/profile',
    element: (
      <ProtectedRoute>
        <ProfilePage />
      </ProtectedRoute>
    ),
    state: 'profile',
    sidebarProps: {
      displayText: 'Profile',
      icon: <ProfileIcon />,
    },
  },
  {
    flag: false,
    path: '/dashboard/edit-profile',
    element: (
      <ProtectedRoute>
        <EditProfile />
      </ProtectedRoute>
    ),
    state: 'edit-profile',
  },
  {
    flag: false,
    path: '/dashboard/change-password',
    element: (
      <ProtectedRoute>
        <ChangePassword />
      </ProtectedRoute>
    ),
    state: 'change-password',
  },
  {
    color: true,
    flag: true,
    path: '/dashboard/settings',
    element: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    ),
    state: 'settings',
    sidebarProps: {
      displayText: 'Settings',
      icon: <SettingIcon />,
    },
  },

  {
    color: true,
    flag: true,
    path: '/dashboard/other',
    element: (
      <ProtectedRoute>
        <HelpPage />
      </ProtectedRoute>
    ),
    state: 'other',
    sidebarProps: {
      displayText: 'Other',
      icon: <Help />,
    },
  },
  {
    flag: false,
    path: '/dashboard/other/:categary',
    element: (
      <ProtectedRoute>
        <HelpCategarys />
      </ProtectedRoute>
    ),
  },
  // {
  //   flag: false,
  //   path: '/dashboard/help/terms-conditions',
  //   element: (
  //     <ProtectedRoute>
  //       <HelpCategarys />
  //     </ProtectedRoute>
  //   ),
  //   state: 'help.terms-conditions',
  //   sidebarProps: {
  //     displayText: 'Inquiry',
  //     icon: <InquiryIcon />,
  //   },
  // },
  {
    flag: false,
    path: '/dashboard/other/:categary',
    element: (
      <ProtectedRoute>
        {/* <PrivacyPolicyPage /> */}
        <HelpCategarys />
      </ProtectedRoute>
    ),
    state: 'other',
    sidebarProps: {
      displayText: 'Inquiry',
      icon: <InquiryIcon />,
    },
  },
];
export default appRoutes;
