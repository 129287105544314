import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchAudioDetails: any = createAsyncThunk('audioDetails/fetchAudioDetails', async () => {
  const response = await API.get(`/audio-list`);
  return response.data?.data;
});

const initialState = {
  audioData: [],
  status: '',
};

const audioSlice = createSlice({
  name: 'audioDetails',
  initialState,
  reducers: {
    setAudioState: (state, { payload }) => {
      state.audioData = payload.payload;
    },
  },
});

export const { setAudioState } = audioSlice.actions;
export default audioSlice.reducer;
