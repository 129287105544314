import { Box, Typography } from "@mui/material"
import { BackButton } from "../../assets/icons"
import InquiryChat from "../../components/common/InquiryChat"
import { useNavigate, useParams } from "react-router-dom"
import ReplyChat from "../../components/common/ReplyChat"
import { CustomContainer } from "../../components/CustomContainer"
import { colors } from "../../styles/color"

const BusinessInquiryChat = (props: any) => {
    const navigate = useNavigate()
    const { inquiryMessageList, loading, fetchList } = props

    return (
        <CustomContainer maxWidth={"1352px !important"}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                padding: "0 10px "
            }}>

                {!loading ? Object.keys(inquiryMessageList).length > 0 && <Box sx={{
                    maxWidth: "900px",
                    width: "100%",
                    // marginLeft: "150px",
                    display: "flex",
                    flexDirection: "column",
                    height: {sm:"calc(100vh - 120px)", xs:"calc(100vh - 95px)"}

                }}>
                    <Box sx={{ display: { md: "", xs: "flex" }, alignItems: "center" }}>
                        <Box sx={{
                            marginRight: { md: 0, xs: "15px" },
                            position: { md: "absolute" }, top: { md: 0 }, left: { md: 0 }
                        }}>
                            <BackButton className="cursor-pointer" onClick={() => { navigate("/inquiry") }} />
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                {inquiryMessageList?.subject}
                            </Typography>
                            <Box sx={{ display: "flex",alignItems:"center" }}>
                                <Typography variant="body2" sx={{
                                    color: colors.gray[300]
                                }}>
                                    {inquiryMessageList?.inquiry_id}
                                </Typography>
                                {inquiryMessageList?.channel?.channel_name && <Typography  
                                    p={'0px 10px'}
                                    variant='subtitle1'
                                    fontWeight={400}
                                    fontSize='30px'
                                    color={colors.gray[300]}
                                >
                                  ·
                                </Typography>}
                                <Typography variant="body2" sx={{
                                    color: colors.basics.primary,
                                    textDecoration: "underline"
                                }}>
                                    {inquiryMessageList?.channel?.channel_name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <InquiryChat
                        maxHeight={"500px"}
                        inquiry={Object.keys(inquiryMessageList).length > 0 ? inquiryMessageList?.messages : []}
                    />

                    {Object.keys(inquiryMessageList).length > 0 && <ReplyChat fetchList={() => fetchList()} profileImage={inquiryMessageList?.profile} />}
                </Box> : <></>}
            </Box>
        </CustomContainer>
    )
}

export default BusinessInquiryChat