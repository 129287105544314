import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../components/common/Topbar';
import { colors } from '../../styles/color';
import { ArrowLeftGray } from '../../assets/icons';
import { useDispatch } from 'react-redux';
import { setAppState } from '../../redux/features/appStateSlice';
const HelpPage = () => {
  //! Variables
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setAppState("other"))
  }, [dispatch])
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      <Topbar>
        <Box
          sx={{
            '& .MuiToolbar-root': {
              pl: { xl: 0, xs: '20px' },
            },
          }}
        >
          <Box pl={{ xl: 0, sm: '20px', xs: "10px" }}>
            <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
              Other
            </Typography>
          </Box>
        </Box>
      </Topbar>
      <Box sx={{ p: '0px 16px', mt: '16px', mb: '16px' }}>
        <Box bgcolor={colors.dark[800]} borderRadius={'12px'} minHeight={'calc(100vh - 132px)'}>
          <Box p={{ xl: '60px', sm: '35px', xs: '20px' }}>
            <Box
              sx={{
                background: '#1D1D1D',
                borderRadius: '10px',
                maxWidth: '469px',
                display: 'flex',
                justifyContent: 'space-between',
                p: '27px 30px 27px 20px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/dashboard/other/terms-conditions')}
            >
              <Box>
                <Typography variant='body2' lineHeight={'24px'}>
                  Terms & Conditions
                </Typography>
              </Box>
              <Box>
                <ArrowLeftGray />
              </Box>
            </Box>{' '}
            <Box
              sx={{
                mt: '10px',
                background: '#1D1D1D',
                borderRadius: '10px',
                maxWidth: '469px',
                display: 'flex',
                justifyContent: 'space-between',
                p: '27px 30px 27px 20px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/dashboard/other/privacy-policy')}
            >
              <Box>
                <Typography variant='body2' lineHeight={'24px'}>
                  Privacy Policy
                </Typography>
              </Box>
              <Box>
                <ArrowLeftGray />
              </Box>
            </Box>
            <Box
              sx={{
                mt: '10px',
                background: '#1D1D1D',
                borderRadius: '10px',
                maxWidth: '469px',
                display: 'flex',
                justifyContent: 'space-between',
                p: '27px 30px 27px 20px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/dashboard/other/data-safety')}
            >
              <Box>
                <Typography variant='body2' lineHeight={'24px'}>
                  Data Safety
                </Typography>
              </Box>
              <Box>
                <ArrowLeftGray />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpPage;
