import {
  Button,
  Chip,
  Dialog,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import Dropzone from 'react-dropzone';
import { colors } from '../../styles/color';
import AutoComplete from '../../components/AutoComplete';
import TextInput from '../../components/TextInput';
import CountryAutoComplete from '../../components/CountryAutoComplete';
import { SelectInput } from '../../components/SelectInput';
import { channelData, durationTime } from '../../constants/menuItems';
import { fetchDeleteChannelData } from '../../redux/features/deleteChannel/deleteChannelSlice';
import { fetchAddChannelData, setaddChannelState } from '../../redux/features/addChannel/addChannelSlice';
import { setLoading } from '../../redux/features/loader/loaderSlice';
import { fetchCountryeDetails, setCountryState } from '../../redux/features/country/countrySlice';
import {
  fetchCategoryDetails,
  setCategoryState,
} from '../../redux/features/channelCategory/channelCategorySlice';
import { fetchAudioDetails, setAudioState } from '../../redux/features/channelAudioList/channelAudioSlice';
import { API } from '../../api/services';
import { ToastMessage } from '../../helpers/ToastMessage';
import {
  Close,
  Doller,
  InfoEmail,
  Line,
  RemoveChannelIcon,
  Search,
  EditThumbnail,
  UploadIcon,
} from '../../assets/icons';
import { setChannelDetailsState } from '../../redux/features/channelDetails/channelDetailsSlice';
import ErrorTextBox from '../../components/common/ErrorTextBox';
import { isEmpty, onlyNumbers, validateMinSec } from '../../utils/commanFunctions/commanFunctions';
import BreadCrumb from '../../components/common/BreadCrumb';
import { setProfileState } from '../../redux/features/profile/profileSlice';
import CkEditoComponent from '../../components/common/CkEditorComponent';
import {
  fetchContentCategoryDetails,
  setContentCategory,
} from '../../redux/features/contentCategory/contentCategorySlice';
import { toast } from 'react-toastify';
import ContentCategoryAutoComplete from '../../components/layout/ContentCategoryAutoComplete';
import { useCookies } from 'react-cookie';

interface Props {
  toggle?: boolean;
  setToggle?: any;
  channelDetails?: any;
}

interface FormErrors {
  category?: string;
  uploadTime?: string;
  country?: any;
  language?: string;
  addChannel?: string;
  email?: string;
  description?: string;
  file?: string;
  valueData?: string;
  videoType?: any;
  contentCat?: any;
  // minimumPrice: any;
}

export const EditChannel = ({ toggle, setToggle, channelDetails }: Props) => {
  //! Hooks
  const { countryData } = useSelector((state: any) => state.country);
  const { audioData } = useSelector((state: any) => state?.audio);
  const { categoryData } = useSelector((state: any) => state?.category);
  const [addChannel, setAddChannel] = React.useState<string[]>([]);
  const [category, setCategory] = React.useState<any>('');
  const [shortsPrice, setShortsPrice] = useState<any>();
  let [minimumPrice, setMinimumPrice] = React.useState<any>();
  const [uploadTime, setUploadTime] = React.useState<any>('');
  const [valueData, setValueData] = React.useState('');
  const [country, setCountry] = React.useState<any[]>([]);
  const [email, setEmail] = React.useState<any>('');
  const [language, setLanguage] = React.useState<any[]>([]);
  const [contentCatData, setContentcatData] = useState<any[]>([]);
  const [contentCat, setContentCat] = useState<any>([]);
  const [videoType, setVideoType] = useState<any>(1);
  const [description, setDescription] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [thumbnail, setThumbnail] = React.useState<any>(null);
  const [formErrors, setFormErrors] = React.useState<FormErrors>({});
  const [isVideoFieldDisplay, setIsVideoFieldDisplay] = useState(false);
  const [isShortVideoDisplay, setIsShortVideoDisplay] = useState(false);
  const [bothFiled, setIsBothField] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);

  const [channelId, setChannelId] = React.useState(null);

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const schema = yup.object().shape({
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    country: yup.array().min(1, 'Please select at least one country').required('Please select a country'),
    description: yup
      .string()
      .min(10, 'Description must be at least 10 characters')
      .required('Description is required'),
    category: yup.string().required('Please select a category'),
    uploadTime: yup.string().required('Please select a upload time'),
    language: yup.array().min(1, 'Please select video audio language').required('Please select at least one video audio language'),
    // language: yup
    //   .array()
    //   .required('Please select video audio language')
    //   .min(1, 'Please select at least one video audio language'),
    contentCat: yup
      .array()
      .min(1, 'Please select at least one content category')
      .required('Please select a content category'),
    videoType: yup.number().required('Please select a video type'),
    minimumPrice: yup.number().max(6, 'Minimum price not be more than 6 digit.'),
    valueData: yup
      .string()
      .test(
        'not-00-0e',
        'Value cannot be "00:0e" or contain invalid characters, only numbers are allowed',
        (value: any) => {
          if (value === '00:0e' || /[^\d:]/.test(value)) {
            return false;
          }
          return true;
        }
      ),
  });

  useEffect(() => {
    const contentCategoryData = async () => {
      const data = await dispatch(fetchContentCategoryDetails());

      if (data) {
        setContentcatData(data?.payload);
      } else {
        toast.error('Data not found');
      }
    };
    contentCategoryData();
  }, []);

  const handleDrop = React.useCallback((acceptedFiles: File[]) => {
    const data: any = acceptedFiles && acceptedFiles[0];
    if (data) {
      const fileSize = data.size;
      var fileSizes = Math.round(fileSize / 1024);
      if (fileSizes > 1024 * 5) {
        // setFile(null)
        ToastMessage({
          type: 'error',
          message: 'Please select a file less than 5MB.',
        });
      } else {
        setFile(data);
      }
    }
  }, []);

  const handleClear = () => {
    setFile(null);
  };

  const useFetchData = (fetchFunc: any, setFunc: any) => {
    useEffect(() => {
      const fetchData = async () => {
        const res = await dispatch(fetchFunc());
        dispatch(setFunc(res));
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  useFetchData(fetchCountryeDetails, setCountryState);
  useFetchData(fetchCategoryDetails, setCategoryState);
  useFetchData(fetchAudioDetails, setAudioState);
  useFetchData(fetchContentCategoryDetails, setContentCategory);

  useEffect(() => {
    dispatch(setLoading(true));

    const getAddChannelData = async () => {
      const res = await dispatch(fetchAddChannelData());
      dispatch(setaddChannelState(res));
    };
    getAddChannelData();

    dispatch(setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (channelDetails) {
      setChannelId(channelDetails?.channel_id);
      setUploadTime(channelDetails?.upload_time);
      setEmail(channelDetails?.email);
      setValueData(isEmpty(channelDetails?.video_length));
      setMinimumPrice(channelDetails?.minimum_price? parseInt(channelDetails?.minimum_price):0);
      setShortsPrice(channelDetails?.minimum_short_price ? parseInt(channelDetails?.minimum_short_price):0);
      setVideoType(parseInt(channelDetails?.video_type));
      setContentCat(
        channelDetails?.content_category?.flatMap((innerArray: any) => innerArray?.content_category_details)
      );
      setAudioState(channelDetails?.audio?.id);
      setCountry(channelDetails?.countries);
      setCategory(channelDetails?.category?.id);
      setLanguage(channelDetails?.audio);
      setDescription(channelDetails?.description);
      setThumbnail(channelDetails?.thumbnail);
    }
  }, [channelDetails]);

  useEffect(() => {
    if (parseInt(channelDetails?.video_type) === 2) {
      setIsVideoFieldDisplay(true);
      setIsShortVideoDisplay(false);
    } else if (parseInt(channelDetails?.video_type) === 3) {
      setIsShortVideoDisplay(true);
      setIsVideoFieldDisplay(false);
    } else if (parseInt(channelDetails?.video_type) === 1) {
      setIsBothField(true);
    }
  }, [channelDetails?.video_type]);

  // const selectedLanguageIds = language?.map(
  //   (language) => audioData?.find((audioData: any) => audioData?.name === language)?.id
  //   );
  //   console.log('selectedLanguageIds :', selectedLanguageIds);
  const successApi = async () => {
    try {
      setFormErrors({});
      dispatch(setLoading(true));
      const formData = new FormData();

      if (file) formData.append('thumbnail', file);
      formData.append('id', channelDetails?.id);
      if (channelId) {
        formData.append('youtube_channel_id', channelId);
      }
      formData.append('category_id', category);
      formData.append('upload_time', uploadTime);
      formData.append('email', email);
      if (valueData !== null) {
        formData.append('video_length', valueData);
      }
      formData.append('minimum_price', minimumPrice);

      // selectedLanguageIds.map((id) => {
      //   formData.append('audio_id[]', id);
      // });

      language.forEach((LanguageDetails) => {
        formData.append('audio_id[]', LanguageDetails.id);
      });
      formData.append('description', description);
      country.forEach((countryDetails) => {
        formData.append('country[]', countryDetails.id);
      });
      contentCat.forEach((contentCatDetails: any) => {
        formData.append('content_cat_id[]', contentCatDetails?.id);
      });
      formData.append('video_type', videoType);
      formData.append('minimum_short_price', shortsPrice);

      try {
        const response = await API.post(`/channal`, formData);
        dispatch(setChannelDetailsState(response?.data?.data));
        if (response.status) {
          ToastMessage({ type: 'success', message: response.data.message });
          setToggle(false);
        } else {
          ToastMessage({ type: 'error', message: response.error });
        }
      } catch (error: any) {
        ToastMessage({ type: 'error', message: error.message });
        if (error.message === 'Unauthenticated') {
          ToastMessage({ type: 'error', message: error.message });
          removeToken('accessToken', { path: '/' });
          removeToken('refreshToken', { path: '/' });
          removeToken('googleToken', { path: '/' });
          removeToken('role', { path: '/' });
        }
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmitSave = async () => {
    let isValidate = true;
    if (formErrors.valueData === '' || formErrors.valueData === undefined) {
      isValidate = true;
    } else {
      isValidate = false;
    }

    if (isValidate) {
      schema
        .validate(
          {
            category,
            uploadTime,
            language,
            country: country?.map((c) => c.name),
            contentCat: contentCat?.map((ct: any) => ct),
            videoType,
            addChannel,
            valueData,
            description,
            email,
            file,
          },
          { abortEarly: false }
        )
        .then(async () => {
          successApi();
        })
        .catch((err) => {
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
          // ToastMessage({ type: 'error', message: err.message });
        });
    }
  };
  const handleDelete = async () => {
    dispatch(setLoading(true));
    const res = await dispatch(fetchDeleteChannelData(Number(channelDetails?.id)));
    if (res.error) {
      ToastMessage({
        type: 'error',
        message: res.error.message,
      });
      dispatch(setLoading(false));
    } else {
      dispatch(setProfileState({}));
      dispatch(setChannelDetailsState(null));
      ToastMessage({
        type: 'success',
        message: res.payload.message,
      });
      setToggle(false);
      dispatch(setLoading(false));
      navigate('/dashboard/social/youtube');
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (selectedOptions: any) => {
    const categoriesToRemove = country.filter(
      (existingCategory: any) =>
        !selectedOptions.some((selectedOption: any) => existingCategory.id === selectedOption.id)
    );
    const categoriesToAdd = selectedOptions.filter(
      (selectedOption: any) =>
        !country.some((existingCategory: any) => existingCategory.id === selectedOption.id)
    );
    const updatedCategories = country.filter(
      (existingCategory: any) =>
        !categoriesToRemove.some((categoryToRemove: any) => categoryToRemove.id === existingCategory.id)
    );
    updatedCategories.push(...categoriesToAdd);
    setCountry(updatedCategories);
  };

  const handleContentcategory = (selectedOptions: any[]) => {
    const categoriesToRemove = contentCat.filter(
      (existingCategory: any) =>
        !selectedOptions.some((selectedOption) => existingCategory.id === selectedOption.id)
    );
    const categoriesToAdd = selectedOptions.filter(
      (selectedOption) =>
        !contentCat.some((existingCategory: any) => existingCategory.id === selectedOption.id)
    );
    const updatedCategories = contentCat.filter(
      (existingCategory: any) =>
        !categoriesToRemove.some((categoryToRemove: any) => categoryToRemove.id === existingCategory.id)
    );
    updatedCategories.push(...categoriesToAdd);
    setContentCat(updatedCategories);
  };

  const languageHandleChange = (selectedOptions: any) => {
    const categoriesToRemove = audioData.filter(
      (existingCategory: any) =>
        !selectedOptions.some((selectedOption: any) => existingCategory.id === selectedOption.id)
    );
    const categoriesToAdd = selectedOptions.filter(
      (selectedOption: any) =>
      !language.some((existingCategory: any) => existingCategory.id === selectedOption.id)
      );
    const updatedCategories = language.filter(
      (existingCategory: any) =>
      !categoriesToRemove.some((categoryToRemove: any) => categoryToRemove.id === existingCategory.id)
      );
    updatedCategories.push(...categoriesToAdd);

    setLanguage(updatedCategories);
  };
  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      <BreadCrumb
        isClickable={false}
        name1={'Social'}
        name2={'YouTube'}
        name3={'Edit Channel'}
        isClickable2={true}
        onClick2={() => {
          setToggle(false);
        }}
        onClick={() => {
          navigate('/dashboard/social/youtube');
        }}
      />
      <Box p='0px 16px' mt={'16px'}>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '12px',
            color: 'white',
            p: { md: '70px 100px 20px', xs: '20px' },
          }}
        >
          <Grid container sx={{ justifyContent: 'space-between', display: 'flex' }}>
            <Grid item sm={5.9} xs={12}>
              <Box
                sx={{
                  '& .MuiOutlinedInput-input': {
                    color: `${colors.gray[500]} !important`,
                  },
                }}
              >
                <AutoComplete
                  placeholder='Search by channel name or link'
                  title='Edit Channel'
                  readOnly={true}
                  data={channelData?.map((option) => option)}
                  value={channelDetails?.channel_name}
                  disableClearable
                  onChange={(value: any) => setAddChannel(value)}
                />
              </Box>

              <Box mt={'24px'}>
                <Typography variant='body2' sx={{ color: 'gray' }}>
                  Video Type
                </Typography>

                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='all'
                  name='radio-buttons-group'
                  value={videoType}
                  onChange={(event: any) => {
                    setVideoType(event.target.value);
                    if (event.target.value === '2') {
                      setIsVideoFieldDisplay(true);
                      setIsShortVideoDisplay(false);
                      setIsBothField(false);
                    } else if (event.target.value === '3') {
                      setIsShortVideoDisplay(true);
                      setIsVideoFieldDisplay(false);
                      setIsBothField(false);
                    } else if (event.target.value === '1') {
                      setIsBothField(true);
                      setIsShortVideoDisplay(false);
                      setIsVideoFieldDisplay(false);
                    }
                  }}
                  sx={{
                    '& .MuiRadio-root': {
                      color: colors.gray[500],
                    },
                    '& .Mui-checked': {
                      color: colors.basics.primary,
                      '& svg': {
                        fill: colors.basics.primary,
                      },
                    },
                    '& .MuiFormControlLabel-label': {
                      color: colors.gray[300],
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '21px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      backgroundColor: '#1D1D1D',
                      paddingY: '6px',
                      paddingX:'16px',
                      borderRadius: '8px',
                    }}
                  >
                    <FormControlLabel value='1' control={<Radio />} label='Both' />
                    <FormControlLabel value='2' control={<Radio />} label='Full Video' />
                    <FormControlLabel value='3' control={<Radio />} label='Shorts (Reels)	' />
                  </Box>
                </RadioGroup>
                <ErrorTextBox>{formErrors?.videoType}</ErrorTextBox>
              </Box>
              {isVideoFieldDisplay || bothFiled ? (
                <Box
                  sx={{
                    mt: '24px',
                    '& .MuiOutlinedInput-input': {
                      pl: '20px',
                    },
                  }}
                >
                  <TextInput
                    title='Minimum Price for Video (Optional)'
                    placeholder='Enter minimum price'
                    value={minimumPrice === null || minimumPrice == 0 ? '' : minimumPrice}
                    onChange={(e: any) => {
                      if (onlyNumbers(e?.target?.value) || e?.target?.value === '') {
                        if (e?.target?.value >= 1 || e?.target?.value === '') {
                        let a =e?.target?.value === "" ?0:e?.target?.value
                          setMinimumPrice(a);
                        }
                      }
                    }}
                    name='minimum_price'
                    StartIcon={
                      <Box display={'flex'} alignItems={'center'}>
                        <Box pr={'20px'}>
                          <Doller />
                        </Box>
                        <Box>
                          <Line />
                        </Box>
                      </Box>
                    }
                  />
                </Box>
              ) : (
                ''
              )}

              {isShortVideoDisplay || bothFiled ? (
                <Box
                  sx={{
                    mt: '24px',
                    '& .MuiOutlinedInput-input': {
                      pl: '20px',
                    },
                  }}
                >
                  <TextInput
                    title='Minimum Price for Shorts (Optional)'
                    placeholder='Enter minimum shorts price'
                    value={shortsPrice === null || shortsPrice == 0 ? '' : shortsPrice}
                    onChange={(e: any) => {
                      if (onlyNumbers(e?.target?.value) || e?.target?.value === '') {
                        if (e?.target?.value >= 1 || e?.target?.value === '') {
                          let a =e?.target?.value === "" ?0:e?.target?.value
                          setShortsPrice(a);
                        }
                      }
                    }}
                    name='shorts_minimum_price'
                    StartIcon={
                      <Box display={'flex'} alignItems={'center'}>
                        <Box pr={'20px'}>
                          <Doller />
                        </Box>
                        <Box>
                          <Line />
                        </Box>
                      </Box>
                    }
                  />
                </Box>
              ) : (
                ''
              )}

              <Box mt={'24px'}>
                <TextInput
                  title='Average video upload duration length (optional)'
                  placeholder='05:50  min:sec'
                  value={valueData}
                  onKeyDown={(e: any) => {
                    if (e.target.value.length === 2 && e.keyCode !== 8) {
                      if (e.target.value.indexOf(':') === -1) {
                        e.target.value = e.target.value + ':';
                      }
                      var str = e.target.value.split(':');
                      if (str[0].length === 1) {
                        e.target.value = '0' + str[0];
                      }
                    }
                  }}
                  onChange={(e: any) => {
                    let input = e.target.value;

                    if (input !== '00:00' && input.length <= 5) {
                      if (validateMinSec(input) || input === '') {
                        setValueData(input);
                        setFormErrors({
                          ...formErrors,
                          valueData: '',
                        });
                      } else {
                        setValueData(input);
                        setFormErrors({
                          ...formErrors,
                          valueData: 'Invalid time format',
                        });
                      }
                    } else if (input === '') {
                      setValueData(input);
                      setFormErrors({
                        ...formErrors,
                        valueData: '',
                      });
                    }
                  }}
                  name='minimum_price'
                />
                <ErrorTextBox>{formErrors.valueData}</ErrorTextBox>
              </Box>

              <Box
                mt={'24px'}
                sx={{
                  '&::placeholder': {
                    color: 'red',
                  },
                }}
              >
                <CountryAutoComplete 
                 placeholder={`${country?.length ? '' : 'Search country'}`}
                  title={'Target Audience Country'}
                  options={countryData?.map((option: any) => option)}
                  EndIcon={<Search />}
                  disableClearable
                  value={country}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(value) => handleChange(value)}
                  renderTags={(value: any, getTagProps: any) =>
                    value?.map((option: any, index: any) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                        deleteIcon={<Close />}
                      />
                    ))
                  }
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        component='li'
                        sx={{
                          display: 'flex',
                          '& .MuiAutocomplete-noOptions': {
                            color: colors.basics.white,
                          },
                          background: colors.dark[600],
                        }}
                        {...props}
                      >
                        <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                          {' '}
                          <ReactCountryFlag countryCode={option.code} svg />
                        </Typography>
                        <Typography variant='body2'>{option.name} </Typography>
                      </Box>
                    );
                  }}
                />
                <ErrorTextBox>{formErrors.country}</ErrorTextBox>
              </Box>
            </Grid>
            <Grid item sm={5.9} xs={12}>
              <Box sx={{ mt: { sm: '0', xs: '20px' } }}>
                <SelectInput
                  placeholder='Select Category'
                  children={categoryData?.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item.id}>
                        <Typography variant='body2' lineHeight='24px'>
                          {item.name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                  renderValue={(selected: any) => {
                    if (selected === '') {
                      return (
                        <Typography variant='body2' sx={{ color: colors.gray[500] }}>
                          {''}
                        </Typography>
                      );
                    }
                    const selectedItem = categoryData.find((item: any) => item.id === selected);
                    return selectedItem ? selectedItem.name : '';
                  }}
                  title='Channel Category'
                  data={categoryData.map((category: any) => category.name)}
                  onChange={(e) => {
                    const categoryId = e.target.value;
                    setCategory(categoryId);
                  }}
                  value={category}
                  defaultOpen={false}
                />
                <ErrorTextBox>{formErrors.category}</ErrorTextBox>
              </Box>{' '}
              <Box
                mt={'24px'}
                sx={{
                  '&::placeholder': {
                    color: 'red',
                  },
                }}
              >
                <ContentCategoryAutoComplete
                  title={'Content Category'}
                  options={contentCatData?.map((option: any) => option)}
                  EndIcon={<Search />}
                  disableClearable
                  value={contentCat}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(value) => handleContentcategory(value)}
                  renderTags={(value: any, getTagProps: any) =>
                    value.map((option: any, index: any) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                        deleteIcon={<Close />}
                      />
                    ))
                  }
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        component='li'
                        sx={{
                          display: 'flex',
                          '& .MuiAutocomplete-noOptions': {
                            color: colors.basics.white,
                          },
                          background: colors.dark[600],
                        }}
                        {...props}
                      >
                        <Typography
                          mr='14px'
                          variant='body2'
                          alignItems={'center'}
                          display={'flex'}
                        ></Typography>
                        <Typography variant='body2'>{option.name} </Typography>
                      </Box>
                    );
                  }}
                />
                <ErrorTextBox>{formErrors?.contentCat}</ErrorTextBox>
              </Box>
              <Box
                sx={{
                  mt: '24px',
                  '& .MuiTooltip-tooltip ': {
                    background: 'red',
                    color: 'pink',
                  },
                  '& .MuiInputAdornment-root': {
                    p: '10px',
                  },
                }}
              >
                <TextInput
                  title='Contact Email'
                  name='email'
                  value={email}
                  placeholder='Enter your email address'
                  onChange={(e) => setEmail(e.target.value)}
                  EndIcon={
                    <Tooltip
                      className='custom-tooltip'
                      sx={{ background: 'red' }}
                      placement='bottom-start'
                      title='Add an Email associated with your YouTube channel account.'
                      arrow
                    >
                      <InfoEmail />
                    </Tooltip>
                  }
                />
                <ErrorTextBox>{formErrors.email}</ErrorTextBox>
              </Box>
              <Box sx={{ mt: '24px' }}>
                <SelectInput
                  title='Video upload duration time'
                  placeholder={'Video upload duration time'}
                  renderValue={(selected: any) => {
                    if (selected === '') {
                      return (
                        <Typography variant='body2' sx={{ color: colors.gray[500] }}>
                          {''}
                        </Typography>
                      );
                    }
                    const selectedItem = durationTime.find((item: any) => item.id === selected);
                    return selectedItem ? selectedItem?.time : '';
                  }}
                  data={durationTime}
                  // onChange={(e) => setUploadTime(e?.target?.value)}
                  onChange={(e) => {
                    const uploadTimeId = e.target.value;
                    const selectedTime = durationTime.find(
                      (option) => option.id === parseInt(uploadTimeId)
                    )?.time;
                    setUploadTime(uploadTimeId);
                  }}
                  value={uploadTime}
                  defaultOpen={false}
                  children={durationTime?.map((option: any, index: any) => {
                    return (
                      <MenuItem
                        value={option.id}
                        key={option.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                          {option.time}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                />
                <ErrorTextBox>{formErrors.uploadTime}</ErrorTextBox>
              </Box>
              <Box mt={'24px'}>
                {/* <SelectInput
                  multiple={true}
                  title='Video audio language'
                  placeholder={'Select language'}
                  // multiple={true}
                  renderValue={(selected: string[]) => {
                    if (selected?.length === 0) {
                      return (
                        <Typography variant='body2' sx={{ color: 'gray' }}>
                          Select language
                        </Typography>
                      );
                    }
                    return selected?.join(', ');
                  }}
                  children={audioData?.map((option: any, index: any) => {
                    return (
                      <MenuItem
                        value={option.name}
                        key={option.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                          {option.name}
                        </Typography>
                        <Typography variant='body2'>{option.CountryName}</Typography>
                      </MenuItem>
                    );
                  })}
                  value={language}
                  onChange={handleChangLanguage}
                /> */}
                  <CountryAutoComplete 
                  placeholder={`${language?.length ? '' : 'Select language'}`}
                  title={'Video audio language'}
                  options={audioData?.map((option: any) => option)}
                  EndIcon={<Search />}
                  disableClearable
                  value={language}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(value) => languageHandleChange(value)}
                  renderTags={(value: any, getTagProps: any) =>
                    value?.map((option: any, index: any) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        {...getTagProps({ index })}
                        deleteIcon={<Close />}
                      />
                    ))
                  }
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        component='li'
                        sx={{
                          display: 'flex',
                          '& .MuiAutocomplete-noOptions': {
                            color: colors.basics.white,
                          },
                          background: colors.dark[600],
                        }}
                        {...props}
                      >
                        <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                          {' '}
                          {/* <ReactCountryFlag countryCode={option.code} svg /> */}
                        </Typography>
                        <Typography variant='body2'>{option.name} </Typography>
                      </Box>
                    );
                  }}
                />
                <ErrorTextBox>{formErrors.language}</ErrorTextBox>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={'24px'}>
                <Typography
                  sx={{ color: colors.gray[300] }}
                  variant='body2'
                  lineHeight='140%'
                  mb='6px'
                  textAlign='left'
                  textTransform='capitalize'
                >
                  Description
                </Typography>
                <Box
                  sx={{
                    '& .ck-editor ': {
                      backgroundColor: colors.dark[600],
                      padding: '20px',
                      borderRadius: '10px',
                    },
                    '.ck.ck-editor__main>.ck-editor__editable': {
                      backgroundColor: 'transparent',
                      border: 0,
                    },
                    '& .ck-editor__top ': {
                      display: 'none',
                    },

                    '& .ck-content p ': {
                      background: 'none',
                      border: 'none',
                      marginTop: '0 !important',
                      marginBottom: '15px !important',
                    },
                    '& .ck-content p a': {
                      color: '#A175FF !important',
                      textDecoration: 'none',
                    },
                    '& .ck-content p:last-child ': {
                      marginBottom: '0 !important',
                    },
                  }}
                >
                  <CkEditoComponent data={description} setData={setDescription} />
                </Box>
                <ErrorTextBox>{formErrors.description}</ErrorTextBox>
              </Box>
              <Box mt={'24px'}>
                <Box mb={'10px'}>
                  <Typography variant='body2' lineHeight={'140%'} color={colors.gray[300]}>
                    Upload Thumbnail
                  </Typography>
                </Box>
                <Dropzone accept={{ 'image/png': ['.png', '.jpeg', '.jpg'] }} onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'left',
                        padding: thumbnail ? '0px' : '25px',
                        border: thumbnail ? 'none' : `1px dashed ${colors.dark[100]}`,
                        borderRadius: '10px',

                        position: 'relative',
                      }}
                    >
                      <input {...getInputProps()} accept='image/*' />
                      {file || thumbnail ? (
                        <Box
                          sx={{
                            position: 'relative',
                            textAlign: 'left',
                            justifyContent: 'left',
                            display: 'flex',
                          }}
                        >
                          <Box
                            sx={{ maxWidth: '100%', maxHeight: '200px', position: 'relative' }}
                            {...getRootProps()}
                          >
                            <img
                              src={file ? URL?.createObjectURL(file) : thumbnail}
                              alt='Preview'
                              style={{
                                minWidth: '166px',
                                maxHeight: '166px',
                                borderRadius: '8px',
                                height: '100%',
                                width: '100%',
                                cursor: 'pointer',
                              }}
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                background: '#1d1d1d99',
                                transform: 'translate(-50%, -50%)',
                                top: '50%',
                                left: '50%',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                p: '8px 8px 8px 8px',
                                textAlign: 'left',
                                justifyContent: 'left',
                                display: 'flex',
                              }}
                              onClick={handleClear}
                            >
                              <EditThumbnail />
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box textAlign={'center'}>
                          <Box>
                            <UploadIcon />
                          </Box>
                          <Box>
                            <Typography variant='body2' lineHeight={'24px'}>
                              Drag and drop or browse
                            </Typography>
                            <Typography
                              variant='body2'
                              fontSize={'14px'}
                              lineHeight={'140%'}
                              color={colors.gray[300]}
                            >
                              Image size should be 1080px x 1080px (Max. File size: 4MB)
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Dropzone>
              </Box>
              <Box display={{ sm: 'flex', xs: 'block' }}>
                <Box
                  alignItems='center'
                  display={'flex'}
                  mt={'20px'}
                  mb={'10px'}
                  onClick={() => navigate('/dashboard/social/youtube')}
                >
                  <Button sx={{ width: { sm: '180px', xs: '100%' } }} onClick={handleSubmitSave}>
                    Save changes
                  </Button>
                </Box>
                <Box
                  mt={'20px'}
                  mb={'10px'}
                  ml={{ sm: '15px', xs: 0 }}
                  sx={{
                    width: '100%',
                    '& .MuiButtonBase-root': {
                      border: '1px solid #E33437',
                      '&:hover': {
                        background: colors.dark[800],
                        border: '1px solid #E33437',
                      },
                    },
                  }}
                >
                  <Button
                    sx={{
                      background: colors.dark[800],
                      color: '#E33437',
                      width: { sm: '180px', xs: '100%' },
                    }}
                    variant='outlined'
                    onClick={handleClickOpen}
                  >
                    Remove channel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Dialog
              className='edit-modal'
              open={open}
              maxWidth={'xl'}
              onClose={handleClose}
              sx={{
                '& .MuiDialog-paper': {
                  background: colors.dark[600],
                  borderRadius: '12px',
                },
              }}
            >
              <Box p={'28px'}>
                <Box m={'auto'} textAlign='center' p={'0px 12px'}>
                  <Box>
                    <RemoveChannelIcon />
                  </Box>
                  <Box mt='30px'>
                    <Typography variant='body1' fontWeight={700}>
                      Are you sure?
                    </Typography>
                  </Box>
                  <Box mt='15px'>
                    <Typography variant='body2' color={colors.gray[300]}>
                      Do you really want to remove this channel?
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' justifyContent={'space-between'} width='100%' mt={'32px'}>
                  <Box width='100%' mr='10px'>
                    <Button variant='outlined' sx={{ width: '100%' }} onClick={handleClose}>
                      Cancel
                    </Button>
                  </Box>
                  <Box
                    width='100%'
                    ml='10px'
                    sx={{
                      '& .MuiButtonBase-root': {
                        '&:hover': {
                          background: '#E33437',
                        },
                      },
                    }}
                  >
                    <Button sx={{ width: '100%', background: '#E33437' }} onClick={handleDelete}>
                      Remove
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
