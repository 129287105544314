import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from '../../styles/color';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaticDataDetails, setstatiDataState } from '../../redux/features/staticPage/staticSlice';
import { setLoading } from '../../redux/features/loader/loaderSlice';
import BreadCrumb from '../../components/common/BreadCrumb';
import { ToastMessage } from '../../helpers/ToastMessage';
import { useCookies } from 'react-cookie';
import { setAppState } from '../../redux/features/appStateSlice';
import { ContactUs } from './ContactUs';

const HelpCategarys = () => {
  const { staticData } = useSelector((state: any) => state?.staticData);
  const params = useParams();
  const dispatch = useDispatch();
  const [token] = useCookies();
  const navigate = useNavigate();
  const isInfluencer = token.role === '2';
  const categary =
    params.categary === 'privacy-policy'
      ? 1
      : params.categary === 'terms-conditions'
      ? 2
      : params.categary === 'data-safety'
      ? 3
      : params.categary === 'disclaimer'
      ? 4
      : params.categary === 'about-us'
      ? 5
      : params.categary === 'dmcapolicy'
      ? 6
      : 7;

  const title =
    categary === 1
      ? 'Privacy Policy'
      : categary === 2
      ? 'Terms & Conditions'
      : categary === 3
      ? 'Data Safety'
      : categary === 4
      ? 'Disclaimer'
      : categary === 5
      ? 'About Us'
      : categary === 6
      ? 'DMCA Policy'
      : 'Contact Us';
  useEffect(() => {
    dispatch(setAppState('other'));
    if (categary !== 7) {
      dispatch(setLoading(true));
      const getStaticData = async () => {
        let obj = {
          type: isInfluencer ? 2 : 3,
          page:
            categary === 1
              ? 'privacy'
              : categary === 2
              ? 'terms'
              : categary === 3
              ? 'safety'
              : categary === 4
              ? 'disclaimer'
              : categary === 5
              ? 'aboutus'
              : categary === 6
              ? 'dmcapolicy'
              : 7,
        };
        const res = await dispatch(fetchStaticDataDetails(obj));
        if (res?.error) {
          ToastMessage({
            type: 'error',
            message: res.error.message,
          });
        }
        dispatch(setstatiDataState(res?.payload));
      };
      getStaticData();
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 1000);
    }
  }, [categary, dispatch]);

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'visible';
  //   };
  // }, []);

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      {isInfluencer ? (
        <BreadCrumb
          isClickable={true}
          name1={'Other'}
          name2={title}
          onClick={() => {
            dispatch(setstatiDataState({}));
            navigate('/dashboard/other');
          }}
        />
      ) : (
        <Typography
          sx={{
            marginTop: '57px',
          }}
          mb={5}
          variant='h2'
        >
          {title}
        </Typography>
      )}

      <Box sx={isInfluencer ? { p: '0px 16px', mt: '16px', mb: '16px' } : {}}>
        <Box bgcolor={colors.dark[800]} borderRadius={'12px'} overflow='hidden'>
          <Box
            sx={{
              '&::-webkit-scrollbar': {
                width: 0,
              },
              mt: isInfluencer ? { sm: '50px', xs: '30px' } : {},
              mb: '20px',
              borderRadius: '12px',
              height: isInfluencer ? { sm: 'calc(100vh - 230px)', xs: 'calc(100vh - 200px)' } : '100%',
              overflow: 'auto',
              color: 'white',
              maxWidth: isInfluencer ? 'auto' : '926px',
              margin: 'auto',
              p: isInfluencer
                ? {
                    md: '0px 60px 30px 60px',
                    xs: '0px 30px 20px 30px',
                  }
                : {
                    xs: '0px 15px',
                  },
              '& .MuiTypography-root': {
                '&:nth-of-type(odd)': {
                  color: colors.basics.white,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: '150%',
                },
                '&:nth-of-type(even)': {
                  color: colors.gray[300],
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 15,
                  lineHeight: '180%',
                },
              },
            }}
          >
            <Box>
              <Box></Box>
              <Box mb='119px'>
                {categary !== 7 ? (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: staticData?.data?.description,
                    }}
                  />
                ) : (
                  <ContactUs />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpCategarys;
