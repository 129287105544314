interface TabPanelProps {
  children?: JSX.Element;
  tab: string;
  currentTab: string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, tab, currentTab, ...other } = props;
  return (
    <div role='tabpanel' id={`simple-tabpanel-${tab}`} aria-labelledby={`simple-tab-${tab}`} {...other}>
      <div hidden={currentTab !== tab}>{children}</div>
    </div>
  );
};
