import { Box, Dialog, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '../../styles/color';
import { useNavigate } from 'react-router-dom';
import { Close, NotificationSettings } from '../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/features';
import { instance } from '../../api/services/axiosClient';
import { fetchNotification, setNotificationState } from '../../redux/features/notificationDetails/notificationDetails';
import Bell from "../../assets/images/Bell.png"
import Image from '../../components/common/Image';
import NoDataFound from '../../components/common/NoDataFound';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@mui/styles';
import { formatDate } from '../../utils/commanFunctions/commanFunctions';
import userImage from '../../assets/images/p1.png';
interface modalProps {
  open?: any;
  setOpen?: any;
  settingNavigatePath?: any
}
const useStyles: any = makeStyles({
  wordBreak: {
    wordBreak: "break-word"
  },
  responsiveImage: {
    width: "56px",
    height: "56px",
    objectFit: "cover",
    '@media (max-width: 556px)': {
      width: "42px",
      height: "42px",
    }
  },
  header: {
    background: colors.dark[600],
    color: 'white',
    m: '23px 25px !important',
    overflow: 'hidden',
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }
})

const NotificationModal = ({ open, setOpen, settingNavigatePath }: modalProps) => {
  const classes = useStyles();
  const { notificationDetails } = useSelector((state: any) => state.notification)
  // const [notificationDetails, setAllNotification] = useState<any>({})
  const dispatch = useDispatch()
  const [token] = useCookies();
  const navigate = useNavigate();

  const getNotification = useCallback(async () => {
    const res = await dispatch(fetchNotification())
    const data = res?.payload?.data
    if (data) {
      dispatch(setNotificationState(data))
      dispatch(setLoading(false))
    } else {
      dispatch(setNotificationState({}))
      dispatch(setLoading(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (token.accessToken) {
      getNotification()
    }
  }, [getNotification, token])

  const markAllRead = async () => {
    dispatch(setLoading(true))
    await instance.get("read-all").then((res: any) => {
      if (res.data.status) {
        getNotification()
      }
      dispatch(setLoading(false))
    }).catch((e: any) => {
      dispatch(setLoading(false))
    })
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box >
      <Dialog
        id="notification"
        open={open}
        maxWidth={'xl'}
        onClose={handleClose}
        PaperProps={{
          sx: {
            margin: "0",
            position: 'fixed',
            top: {sm:"120px",xs:"80px"},
            borderRadius: '12px',
            right: '15px',
            display: 'flex',
            width: "calc(100% - 30px)",
            // height: "100%",
            maxWidth: '480px',
            height: {sm:"660px",xs:'400px'},
            maxHeight: {xs:"calc(100% - 110px)"},
            background: colors.dark[600],
          },
        }}
      >
        <Box className={classes.header} sx={{
          background: colors.dark[600],
          color: 'white',
          m: '23px 25px',
          overflow: 'hidden',
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }} >
          <Box display='flex' alignItems='center' mb='23px'>
            <Box
              sx={{
                // float: 'left',
                width: '60%',
              }}
            >
              <Typography>Notifications</Typography>
            </Box>
            <Box
              sx={{
                // float: 'left',
                // width: '20%',
                // textAlign: 'right',
                display: "flex",
                marginLeft: "auto",
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(settingNavigatePath)
                handleClose()
              }}
            >
              <NotificationSettings />
            </Box>
            <Box
              sx={{
                // float: 'right',
                // width: '20%',
                // textAlign: 'right',
                marginLeft: "20px",
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <Close />
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent={'space-between'}
            borderTop={`1px solid ${colors.dark[100]} `}
            pt={Object.keys(notificationDetails).length > 0 && notificationDetails?.notifications.length > 0 ? '20px' : '0'}
          >

            {Object.keys(notificationDetails).length > 0 && notificationDetails?.notifications.length > 0 && <Box>
              <Typography sx={{ cursor: "pointer" }} variant='body2' onClick={() => {
                markAllRead()
              }} lineHeight='24px' color={colors.basics.primary}>
                Mark all as read
              </Typography>
            </Box>}
          </Box>{' '}
          <Box sx={{
            margin: Object.keys(notificationDetails).length > 0 && notificationDetails?.notifications.length > 0 ? '0':"auto",
            '& .notification-item:not(:last-child) ': {
              borderBottom: `1px solid ${colors.dark[100]}`,
            }
          }}
            overflow='auto'
          >
            <Box p='5px'>
              {Object.keys(notificationDetails).length > 0 && notificationDetails?.notifications.length > 0 ? notificationDetails?.notifications.map((n: any, i: any) => {
                return (
                  <Box sx={{ cursor: "pointer" }} key={i} className="notification-item" onClick={() => {
                    if (token.role === "2") {
                      navigate(`/dashboard/inquiry/${n.related_id}`)
                    } else {
                      navigate(`/inquiry/${n.related_id}`)
                    }
                  }} display='flex' mt={'34px'} pb={'14px'}  >
                    <Box>
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            width: '14px',
                            height: '14px',
                            borderRadius: '15px',
                            bgcolor: 'red',
                            position: 'absolute',
                            mt: '-8px',
                            ml: '-5px',
                            border: `2px solid ${colors.dark[600]}`,
                          }}
                        ></Box>
                        <Image className={classes.responsiveImage} height='56px' width='56px' src={n?.sender?.profile ? n?.sender?.profile : userImage} alt='user' />
                      </Box>
                    </Box>
                    <Box ml='18px' sx={{'& a':{color:colors.basics.primary ,'& p':{marginTop:0}}}}>
                      <Typography variant='body2' className={classes.wordBreak} fontWeight={400} lineHeight='24px'>
                        {n.title}{' '}
                      </Typography>
                      <Typography variant='body2' dangerouslySetInnerHTML={{__html: n.body,}} sx={{fontSize:"14px",'& p':{marginTop:0}}} className={classes.wordBreak} fontWeight={400} lineHeight='24px' color={colors.gray[300]}>
                      </Typography>
                      <Typography variant='body2' sx={{fontSize:"14px",}} fontWeight={500} lineHeight='24px' color={colors.gray[500]}>
                      {formatDate(n.date)} {n.time}
                      </Typography>
                    </Box>
                  </Box>
                )
              }) :
                <NoDataFound svg={<Image src={Bell}></Image>} text={"No Notification"} />
              }
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NotificationModal;
