
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchStaticDataDetails: any = createAsyncThunk('staticPageDetails/fetchStaticPageDetails', async (obj:any) => {
  const response = await API.get(`/static-page?type=${obj.type}&page=${obj.page}`);
  return response.data;
});

const initialState = {
 staticData: {},
  status: '',
  loading: false,
};

const staticDataSlice = createSlice({
  name: 'statiDataDetails',
  initialState,
  reducers: {
    setstatiDataState: (state, { payload }) => {
      state.staticData = payload;
    },
  },
  
});

export const { setstatiDataState } = staticDataSlice.actions;
export default staticDataSlice.reducer;
