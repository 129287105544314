import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchCountryeDetails: any = createAsyncThunk('countryDetails/fetchCountryDetails', async () => {
  const response = await API.get(`/country-list`);
  return response.data?.data;
});

const initialState = {
  countryData: [],
  status: '',
};

const countrySlice = createSlice({
  name: 'countryDetails',
  initialState,
  reducers: {
    setCountryState: (state, { payload }) => {
      state.countryData = payload.payload;
    },
  },
});

export const { setCountryState } = countrySlice.actions;
export default countrySlice.reducer;
