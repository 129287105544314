import { Box, Button, Dialog, Typography } from "@mui/material"
import Topbar from "./Topbar"
import { colors } from "../../styles/color"
import { SwitchControlled } from "../Switch"
import { ArrowLeftGray, RemoveChannelIcon } from "../../assets/icons"
import { useCookies } from "react-cookie"

const CommonSetting = (props: any) => {
    const [token] = useCookies()
    const { isInfluencer, switchState, handleSwitchChange, navigate, handleClickOpen, open, handleClose, handleDelete } = props
    return (
        <Box
            sx={{
                '& .MuiAppBar-root': {
                    position: 'sticky',
                },
            }}
        >
            {isInfluencer && <Topbar>
                <Box
                    sx={{
                        '& .MuiToolbar-root': {
                            pl: { xl: 0, xs: '20px' },
                        },
                    }}
                >
                    <Box pl={{ xl: 0, sm: '20px', xs: '10px' }}>
                        <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                            Settings
                        </Typography>
                    </Box>
                </Box>
            </Topbar>}
            <Box sx={isInfluencer ? { p: '0px 16px', mt: '16px', mb: '16px' } : {}}>
                <Box bgcolor={isInfluencer ? colors.dark[800] : ""} borderRadius={'12px'} minHeight={isInfluencer ? 'calc(100vh - 132px)' : "auto"}>
                    <Box p={isInfluencer && { xl: '60px', sm: '35px', xs: '20px' }}>
                        <Box mb={'20px'}>
                            <Typography variant='body2' textAlign={"left"} fontSize={'18px'}>
                                Notifications
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                background: colors.dark[600],
                                borderRadius: '10px',
                                maxWidth: '500px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: '27px 20px',
                            }}
                        >
                            <Box>
                                <Typography variant='body2' lineHeight={'24px'}>
                                    Email Notification
                                </Typography>
                            </Box>
                            <Box>
                                <SwitchControlled
                                    sx={{ "& .MuiSwitch-track": { backgroundColor: colors.dark[100], opacity: 1 } }}
                                    name='notification'
                                    checked={switchState?.notification}
                                    onChange={(e) => handleSwitchChange(e)}
                                />
                            </Box>
                        </Box>
                        {/* <Box
                            sx={{
                                background: colors.dark[600],
                                borderRadius: '10px',
                                maxWidth: '500px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: '27px 20px',
                                mt: '10px',
                            }}
                        >
                            <Box>
                                <Typography sx={{ textAlign: "left" }} variant='body2' lineHeight={'24px'}>
                                    {isInfluencer ? "Full profile visible for all business" : "Promotional or best offer email"}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    '& .MuiSwitch-root': {
                                        background: '#343434',
                                    },
                                }}
                            >
                                <SwitchControlled
                                    sx={{ "& .MuiSwitch-track": { backgroundColor: colors.dark[100], opacity: 1 } }}
                                    name={isInfluencer ? "isProfileVisible" : 'promotional'}
                                    checked={isInfluencer ? switchState.isProfileVisible : switchState?.promotional}
                                    onChange={(e) => handleSwitchChange(e)}
                                />
                            </Box>
                        </Box>{' '} */}

                        {!token.googleToken &&
                            <>
                                <Box mt={'36px'} mb={'20px'}>
                                    <Typography textAlign={"left"} variant='body2' lineHeight={'24px'}>
                                        Manage Account
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        background: '#1D1D1D',
                                        borderRadius: '10px',
                                        maxWidth: '500px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: '27px 30px 27px 20px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate(isInfluencer ? '/dashboard/change-password' : "/change-password")}
                                >
                                    <Box>
                                        <Typography variant='body2' lineHeight={'24px'}>
                                            Change Password
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ArrowLeftGray />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        background: '#1D1D1D',
                                        borderRadius: '10px',
                                        maxWidth: '500px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: '27px 30px 27px 20px',
                                        mt: '10px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    <Box>
                                        <Typography variant='body2' lineHeight={'24px'} color='#E33437'>
                                            Delete Account
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ArrowLeftGray />
                                    </Box>
                                </Box>
                            </>}
                        <Box>
                            <Dialog
                                open={open}
                                maxWidth={'xl'}
                                onClose={handleClose}
                                sx={{
                                    '& .MuiDialog-paper': {
                                        background: colors.dark[600],
                                        borderRadius: '12px',
                                    },
                                }}
                            >
                                <Box p={'28px'}>
                                    <Box m={'auto'} textAlign='center' p={'0px 12px'}>
                                        <Box>
                                            <RemoveChannelIcon />
                                        </Box>
                                        <Box mt='30px'>
                                            <Typography variant='body1' fontWeight={700}>
                                                Are you sure?
                                            </Typography>
                                        </Box>
                                        <Box mt='15px'>
                                            <Typography variant='body2' color={colors.gray[300]}>
                                                {' '}
                                                Do you really want to delete this account?
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display='flex' justifyContent={'space-between'} width='100%' mt={'32px'}>
                                        <Box width='100%' mr='10px'>
                                            <Button variant='outlined'
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: "transparent"
                                                }}
                                                onClick={handleClose}>
                                                Cancel
                                            </Button>
                                        </Box>
                                        <Box
                                            width='100%'
                                            ml='10px'
                                            sx={{
                                                '& .MuiButtonBase-root': {
                                                    '&:hover': {
                                                        background: '#E33437',
                                                    },
                                                },
                                            }}
                                        >
                                            <Button sx={{ width: '100%', background: '#E33437' }} onClick={handleDelete}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Dialog>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CommonSetting