import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SidebarItem from './SidebarItem';
import { colors } from '../../styles/color';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationStateType } from '../../redux/store';
import { setAppState } from '../../redux/features/appStateSlice';

type Props = {
  item: any;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { appState } = useSelector((state: ApplicationStateType) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [appState, item]);

  return item.sidebarProps ? (
    <>
      <Box
        sx={{
          mr: '10px',
          ml: '10px',
          backgroundColor: open ? colors.dark[600] : '',
          borderRadius: '10px',
        }}
      >
        <ListItemButton
          onClick={() => {
            dispatch(setAppState(item.state));
            setOpen(!open);
          }}
          sx={{
            '& svg': {
              color: open ? colors.basics.white : colors.gray[300],
            },
            paddingY: '12px',
            borderRadius: '6px',
            background: appState === item.state ? colors.dark[600] : 'unset',
          }}
        >
          <ListItemIcon
            sx={{
              color: colors.gray[300],
              '&.MuiListItemIcon-root ': {
                pr: '10px',
                minWidth: '0',
              },
              '& svg': {
                color: open ? colors.basics.white : colors.gray[300],
              },
            }}
          >
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2' sx={{ color: open ? colors.basics.white : colors.gray[300] }}>
                {item.sidebarProps.displayText}
              </Typography>
            }
          />
          {open ? <ExpandMoreOutlinedIcon /> : <KeyboardArrowRightIcon fontSize='medium' />}
        </ListItemButton>
        <Collapse
          in={open}
          sx={{
            borderTop: `1px solid ${colors.dark[100]}`,
          }}
          timeout='auto'
        >
          <List>
            {item.child?.map((route: any, index: any) =>
              route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} />
                ) : (
                  <SidebarItem item={route} key={index} />
                )
              ) : null
            )}
          </List>
        </Collapse>
      </Box>
    </>
  ) : null;
};

export default SidebarItemCollapse;
