import { Navigate, Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import { useAuth } from './context/AuthContext';
import { useEffect } from 'react';

const WithNav = () => {
  const token = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  });

  return token.accessToken ? (
    token.role === '2' ? (
      <Navigate to={'/dashboard'} />
    ) : (
      <Navigate to='/' />
    )
  ) : (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

export default WithNav;
