import { Switch } from '@mui/material';
import React from 'react';
interface switchProps {
  checked?: any;
  name?: string;
  sx?:any;
  onChange?: (e: any) => void;
}
export const SwitchControlled = (props:switchProps) => {
  const { checked,onChange,name} = props
  return <Switch sx={props.sx} checked={checked} name={name} onChange={(e) => onChange && onChange(e)} />;
};
