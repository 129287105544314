import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import NotificationModal from './NotificationModal';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyDashboard } from './EmptyDashboard';
import { DataDashboard } from './DataDashboard';
import { setLoading } from '../../redux/features';

export const YoutubeDashboardPage = (props: any) => {
  const profileData = props.profileData
  const { loading } = useSelector((state: any) => state.loader);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(true)
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileData !== undefined) {
      if (Object.keys(profileData).length > 0) {
        const getProfileData = async () => {
          setTimeout(() => {
            setLoader(false)
          }, 100);
        };
        getProfileData();
      } else {
        setTimeout(() => {
          setLoader(false)
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <Box>
      <Box
        sx={{
          '& .MuiAppBar-root': {
            position: 'sticky',
            top: 0,
          },
          '& .MuiPaper-root': {
            pr: 'none',
          },
        }}
      >
        {!loader ? profileData?.channel_count === 0 ? <EmptyDashboard /> : <DataDashboard profileData={profileData} /> : <></>}
      </Box>
      {/* <NotificationModal open={open} setOpen={setOpen} /> */}
    </Box>
  );
};

