import { Box, Typography } from "@mui/material"
import { colors } from "../../styles/color"

const NoDataFound = (props: any) => {
    const { svg, text, sx, parentSx } = props

    const commanStyles = {
        // position: 'absolute',
        // top: ' 50%',
        // left: '50%',
        // textAlign: 'center',
        // transform: 'translate(-50%, -50%)',
    }

    return (
        <Box className='box-wrapper' sx={parentSx ? parentSx : {}} >
            <Box
                m='auto'
                sx={sx ? sx : commanStyles}
            >
                <Box sx={{textAlign:'center'}}>
                    {svg}
                </Box>
                <Box mt={'28px'}>
                    <Typography variant='body1' color={colors.gray[300]}>
                        {text}
                    </Typography>
                </Box>
            </Box>{' '}
        </Box >
    )
}

export default NoDataFound