import { Autocomplete, InputAdornment, TextField, Box, Typography, Chip, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../styles/color';
const useStyles: any = makeStyles({
  paper: {
    background: `${colors.dark[600]} !important`,
    marginTop: '10px',
    '&.MuiAutocomplete-paper': {
      borderRadius: '10px',
      color: colors.basics.white,
      maxWidth: '100%',
      '&:hover': {
        background: `${colors.dark[600]} !important`,
      },
      '& .MuiAutocomplete-noOptions': {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: colors.basics.white,
      },
      '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused ': {
        background: '#252525',
      },
      '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
        background: '#252525',
      },
      '& .MuiAutocomplete-option': {
        paddingLeft: '0',
      },
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: '20vh',
    },
    '& .MuiAutocomplete-popper': {
      width: '20px',
    },
    '&:hover': {
      background: colors.dark[600],
    },
    '& ul': {
      borderRadius: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingRight: '20px !important',
      paddingLeft: '20px !important',
      background: colors.dark[600],
      '&:hover': {
        background: colors.dark[600],
        borderRadius: '10px',
      },
    },
    '& li': {
      paddingTop: '14px',
      paddingBottom: '14px',
      background: colors.dark[600],
      borderBottom: `1px solid ${colors.dark[100]}`,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      height: '100%',
      maxHeight: '240px',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
});

interface autoCompleteProps {
  data?: any;
  value?: any;
  EndIcon?: JSX.Element;
  disableClearable?: boolean;
  onChange?: (e: any) => void;
  style?: React.CSSProperties;
  renderGroup?: any;
  open?: boolean;
  renderOption?: any;
  options?: any;
  renderInput?: any;
  getOptionLabel?: any;
  multiple?: any;
  title?: any;
  renderTags?: any;
  placeholder:any;
}

export default function CountryAutoComplete(props: autoCompleteProps) {
  const {
    title,
    value,
    EndIcon,
    disableClearable,
    onChange,
    style,
    renderGroup,
    renderOption,
    options,
    getOptionLabel,
    multiple,
    renderTags,placeholder
  } = props;
  const classes = useStyles();

  return (
    <Box>
      {title && (
        <Typography
          sx={{ color: colors.gray[300] }}
          variant='body2'
          lineHeight='140%'
          mb='8px'
          pl='2px'
          textTransform='capitalize'
        >
          {title}
        </Typography>
      )}
      <Box sx={{ backdropFilter: 'blur(5px)' }}>
        <Autocomplete
          disableListWrap
          value={value}
          disableClearable={disableClearable}
          options={options}
          multiple
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderGroup={renderGroup}
          classes={{ paper: classes.paper }}
          onChange={(event: any, newValue: any | null) => {
            onChange && onChange(newValue);
          }}
          renderTags={renderTags}
          sx={{
            width: '100%',
            borderRadius: '10px',
            height: '100%',
            color: colors.basics.white,
            '&:hover': {
              background: colors.dark[600],
            },
            '& .MuiPopover-paper': {
              backdropFilter: 'blur(5px)',
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              width: '300px',
              maxHeight: '300px',
            },

            '& .MuiOutlinedInput-root ': {
              background: colors.dark[600],
              borderRadius: '10px',
              height: '100%',
              padding: '6px',
            },

            '& .MuiAutocomplete-inputRoot': {
              paddingRight: '0px !important',
            },
            '& input::placeholder': {
              fontStyle: 'normal',
              fontWeight: 400,
              // fontSize: '16px',
              lineHeight: '24px',
              color: colors.gray[500],
            },
            '& .MuiAutocomplete-tag ': {
              background: colors.dark[600],
              color: colors.basics.white,
              border: `1px solid ${colors.dark[100]}`,
              borderRadius: '8px',
            },

            '& .MuiChip-deleteIcon': {
              color: `${colors.basics.white} !important`,
              mr: '10px',
              width: '11px',
              height: '11px',
              fontWeight: '600',
              '&:hover': {
                color: `${colors.basics.white} !important`,
              },
            },
            ...style,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder} 
              sx={{ maxHeight: 'unset' }}
              InputProps={{
                ...params.InputProps,
                type: 'text',
                endAdornment:
                  EndIcon && value?.length === 0 ? (
                    <InputAdornment position='start' sx={{ marginRight: { xs: '20px' } }}>
                      <Box display='flex'>{EndIcon}</Box>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}
