import { ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import InfluencerRoute from './components/layout/InfluencerRoute';
import { AuthContextProvider } from './context/AuthContext';
import { Loader } from './helpers/Loader';
import CompleteSetup from './pages/CompleteSetup';
import EmailCheck from './pages/EmailCheck';
import { ForgotPassword } from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import OtpVerification from './pages/OtpVerification';
import ResetPassword from './pages/ResetPassword';
import SignIn from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { routes } from './routes';
import WithNav from './ShowNav';
import { theme } from './styles/theme';
import BusinessDashboard from './pages/Business-site/Dashboard/BusinessDashboard';
import { ToastContainer } from 'react-toastify';
import { AccountSetUp } from './pages/AccountSetUp';
import ChannelDetails from './pages/ChannelDetails';
import BusinessRoutes from './routes/businessRoutes';
import { useCookies } from 'react-cookie';
import { Suspense, useCallback, useEffect } from 'react';
import WithNavWithLogin from './routes/withNavWithLogin';
import { CookiePolicy } from './components/CookiePolicy';
import { CookieAcceptPopup } from './components/CookieAcceptPopup';
import ScrollToTop from './helpers/ScrollToTop';
import RevokeToken from './helpers/RevokeToken';
import StatusActiveInactine from './helpers/StatusActiveInactine';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogState, setLoading } from './redux/features';
import { fetchProfileDetails, setProfileState } from './redux/features/profile/profileSlice';
import { ToastMessage } from './helpers/ToastMessage';
import { API } from './api/services';
import { cleanChannelListState } from './redux/features/channelList/channelListSlice';
import { cleanChannelDetailsState } from './redux/features/channelDetails/channelDetailsSlice';

function App() {
  const dispatch = useDispatch();
  // const [token] = useCookies();
  const { profileData } = useSelector((state: any) => state?.profile);
  const [token, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  const navigate = useNavigate()
  const cancel = () => {
    dispatch(
      setDialogState({
        loginDialog: false,
        registerDialog: false,
        modal: false,
        otpDialog: { otpDialog: false, fromPageName: '' },
        privacyDialog: false,
        resetPassword: false,
        successReset: false,
        termsDialog: false,
        forgotPassword: false,
        logout: false,
      })
    );
  };

  const logout = async () => {
    dispatch(setLoading(true));
    try {
      const response: any = await API.get(`/logout`);
      if (response.data.status) {
        removeToken('accessToken', { path: '/' });
        removeToken('refreshToken', { path: '/' });
        removeToken('googleToken', { path: '/' });
        removeToken('role', { path: '/' });
        localStorage.removeItem('login');
        dispatch(
          setDialogState({
            loginDialog: false,
            registerDialog: false,
            modal: false,
            otpDialog: { otpDialog: false, fromPageName: '' },
            privacyDialog: false,
            resetPassword: false,
            successReset: false,
            termsDialog: false,
            forgotPassword: false,
            logout: false,
          })
        );
        dispatch(setProfileState({}));
        ToastMessage({
          type: 'success',
          message: response.data.message,
        });
        // window.location.href = "/";
        setTimeout(() => {
          dispatch(setLoading(false));
          cancel();
          if (token.role === '2') {
            navigate('/sign-in');
          } else {
            navigate('/');
          }
        }, 200);
      } else {
        ToastMessage({
          type: 'error',
          message: response.data.message,
        });
      }
    } catch (error: any) {
      if (error.status === false) {
        removeToken('accessToken', { path: '/' });
        removeToken('refreshToken', { path: '/' });
        removeToken('googleToken', { path: '/' });
        removeToken('role', { path: '/' });
        if (token.role === '2') {
          navigate('/sign-in');
        } else {
          navigate('/');
        }
        localStorage.removeItem('login');
        dispatch(
          setDialogState({
            loginDialog: false,
            registerDialog: false,
            modal: false,
            otpDialog: { otpDialog: false, fromPageName: '' },
            privacyDialog: false,
            resetPassword: false,
            successReset: false,
            termsDialog: false,
            forgotPassword: false,
            logout: false,
          })
        );
        dispatch(setProfileState({}));
      }
      dispatch(setLoading(false));
    }
    dispatch(setLoading(false));
    await dispatch(cleanChannelListState());
    await dispatch(cleanChannelDetailsState());
  };

  const getProfileData = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const res = await dispatch(fetchProfileDetails());
      const data = res?.payload;
      if (data?.message === "Unauthenticated") {
        dispatch(setProfileState({}));
        logout();
      } else {
        dispatch(setProfileState(data));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.error,
      });
      dispatch(setLoading(false));
    }
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 100);
  }, []);

  useEffect(() => {
    if (token?.accessToken && Object.keys(profileData).length === 0) {
      getProfileData();
    }
  }, [token, profileData]);

  return (
    <ThemeProvider theme={theme}>
      {/* <Router basename="/"> */}
        <AuthContextProvider>
          <ScrollToTop />
          {/* <RevokeToken /> */}
          {/* <StatusActiveInactine /> */}
          <Box
            className="App"
            sx={
              token.role === '3'
                ? {
                    '& > .MuiBox-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      '& > .MuiBox-root:last-child': {
                        marginTop: 'auto',
                      },
                    },
                  }
                : {}
            }
          >
            <Routes>
              <Route path="/dashboard" element={<InfluencerRoute />}>
                {routes}
              </Route>

              {BusinessRoutes.map((r: any, i: number) => {
                return (
                  <Route
                    key={i}
                    path={r.path}
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <BusinessDashboard>{r.element}</BusinessDashboard>
                      </Suspense>
                    }
                  />
                );
              })}
              <Route path="*" element={<Navigate to={'/'} />} />
              <Route element={<WithNav />}>
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="email-check" element={<EmailCheck />} />
                <Route path="new-password" element={<NewPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="setup-complete" element={<CompleteSetup />} />
                <Route path="otp-verification" element={<OtpVerification />} />
              </Route>
              <Route element={<WithNavWithLogin />}>
                <Route path="account-setup" element={<AccountSetUp />} />
                <Route path="youtube-channel" element={<ChannelDetails />} />
              </Route>
              <Route path="cookie-policy" element={<CookiePolicy />} />
            </Routes>

            <Loader />
          </Box>
        </AuthContextProvider>
        <CookieAcceptPopup />
      {/* </Router> */}
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
