import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setstatiDataState,
} from "../redux/features/staticPage/staticSlice";
import { ToastMessage } from "../helpers/ToastMessage";
import { setLoading } from "../redux/features";
import { API } from "../api/services";

export const CookiePolicy = () => {
  const { staticData } = useSelector((state: any) => state?.staticData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    const getStaticData = async () => {
      const res = await API.get(`/static-page?type=3&page=cookieconsent`);
      dispatch(setstatiDataState(res?.data));
      dispatch(setLoading(false));
    };
    getStaticData();
  }, [dispatch]);
  return (
    <Box sx={{maxWidth:'900px',margin:'auto',padding:'30px'}}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: staticData?.data?.description,
        }}
      />
    </Box>
  );
};
