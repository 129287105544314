import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

export const fetchChannelSuggestionsList: any = createAsyncThunk(
  'channelSuggestions/fetchList',
  async (googleToken: any) => {
    const response = await API.get(`/channel-suggestions-list?accesstoken=${googleToken}`);
    return response.data;
  }
);
const initialState = {
  channelSuggestionList: [],
};
const channelSuggestionsSlice = createSlice({
  name: 'channelSuggestions',
  initialState,
  reducers: {
    setChannelSuggestionsState: (state, { payload }) => {
      state.channelSuggestionList = payload;
    },
  },
});
export const { setChannelSuggestionsState } = channelSuggestionsSlice.actions;
export default channelSuggestionsSlice.reducer;
