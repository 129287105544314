import { styled } from '@mui/material/styles';
import { colors } from '../styles/color';
export const UnCheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  border: `2px solid ${colors.dark[100]} `,
  width: 25,
  height: 25,
  backgroundColor: colors.dark[800],

  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(57,75,89,.5)',
  },
  '& .MuiButtonBase-root': {
    width: 'none',
  },
}));

export const CheckedIcon = styled(UnCheckedIcon)({
  borderWidth: '2px',
  borderImage: 'linear-gradient(180.53deg, #A175FF 7.8%, #563AFF 119.47%) 1',
  backgroundImage: 'linear-gradient(180.53deg, #A175FF 7.8%, #563AFF 119.47%)',
  '&:before': {
    display: 'flex',
    width: 25,
    height: 25,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});
