import { createSlice } from '@reduxjs/toolkit';
type initialProp = {
  dialog: {
    loginDialog: boolean;
    registerDialog: boolean;
    modal: boolean;
    otpDialog: { otpDialog: boolean; fromPageName: string };
    privacyDialog: boolean;
    resetPassword: boolean;
    successReset: boolean;
    termsDialog: boolean;
    forgotPassword: boolean;
    sendInquiry: boolean;
    addLink: boolean;
    newPassword: boolean;
    logout: boolean
  };
};
const initialState: initialProp = {
  dialog: {
    loginDialog: false,
    registerDialog: false,
    modal: false,
    otpDialog: { otpDialog: false, fromPageName: '' },
    privacyDialog: false,
    resetPassword: false,
    successReset: false,
    termsDialog: false,
    forgotPassword: false,
    sendInquiry: false,
    addLink: false,
    newPassword: false,
    logout: false
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialogState: (state, { payload }) => {
      state.dialog = payload;
    },
    resetDialogState: (state) => {
      state.dialog = {
        loginDialog: false,
        registerDialog: false,
        modal: false,
        otpDialog: { otpDialog: false, fromPageName: '' },
        privacyDialog: false,
        resetPassword: false,
        successReset: false,
        termsDialog: false,
        forgotPassword: false,
        sendInquiry: false,
        addLink: false,
        newPassword: false,
        logout: false
      };
    },
  },
});

export const { setDialogState, resetDialogState } = dialogSlice.actions;

export default dialogSlice.reducer;
