import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { colors } from '../styles/color';
import SignInImg from '../assets/images/SignInImg.png';
import TextInput from '../components/TextInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setLoginState } from '../redux/features/login/loginSlice';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import Axios from 'axios';
import { baseURL } from '../api/url';
import { useGoogleLogin } from '@react-oauth/google';
import { setLoading } from '../redux/features/loader/loaderSlice';
import { CheckedIcon, EyeOff, EyeOn, Google, UnCheckedIcon } from '../assets/icons';
import { ToastMessage } from '../helpers/ToastMessage';
import * as yup from 'yup';
import Cookies from 'js-cookie';

interface FormErrors {
  email?: string;
  password?: string;
}
type props = {
  access_token?: any;
  accesstoken?: any;
  role_id?: any;
};
const SignIn = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({
    role: '2',
    email: JSON.parse(localStorage.getItem('email') || '""'),
    password: JSON.parse(localStorage.getItem('password') || '""'),
  });
  const [rememberMe, setRememberMe] = useState<any>(
    JSON.parse(localStorage.getItem('rememberMe') || 'false')
  );
  const [expireTime, setExpireTime] = useState<number | undefined>(30);

  const [accessTon, setAccessToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const onSuccessData = async (response: props) => {
    dispatch(setLoading(true));
    const token = {
      role: 2,
      token: response?.access_token,
    };
    const responseData = await Axios.post(`${baseURL}/google-login`, token);
    const loginData = responseData?.data;
    if (loginData?.access_token) {
      setAccessToken('accessToken', loginData?.access_token, {
        path: '/',
        httpOnly: false,
        expires: dayjs().add(loginData.expires_in, 'seconds').toDate(),
        secure: true,
        sameSite: 'strict',
      });
      setAccessToken('refreshToken', loginData?.refresh_token, {
        path: '/',
        httpOnly: false,
        expires: dayjs().add(loginData.expires_in, 'seconds').toDate(),
        secure: true,
        sameSite: 'strict',
      });
      setAccessToken('googleToken', response?.access_token, {
        path: '/',
        httpOnly: false,
        expires: dayjs().add(loginData.expires_in, 'seconds').toDate(),
        secure: true,
        sameSite: 'strict',
      });

      setAccessToken('role', loginData.data?.role_id, {
        path: '/',
        httpOnly: false,
        expires: dayjs().add(loginData.expires_in, 'seconds').toDate(),
        secure: true,
        sameSite: 'strict',
      });
      ToastMessage({
        type: 'success',
        message: loginData.message,
      });
      dispatch(setLoading(false));

      if (loginData?.data?.channel_count === 1) {
        navigate('/dashboard');
      } else {
        navigate('/account-setup');
      }
    } else {
      ToastMessage({
        type: 'error',
        message: loginData.message,
      });
      dispatch(setLoading(false));
    }
  };

  const login = useGoogleLogin({
    onSuccess: (res) => onSuccessData(res),
  });

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const schema = yup.object().shape({
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
  });

  const handleTokenExpiration = () => {
    removeAccessToken('accessToken');
    removeAccessToken('refreshToken');
    removeAccessToken('role');
    // window.location.reload();
  };

  useEffect(() => {
    const expirationTimeInSeconds = expireTime ? expireTime : 30;
    const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;
    const timer = setTimeout(handleTokenExpiration, expirationTimeInMilliseconds);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const LoginAPICall = async () => {
    setFormErrors({});
    dispatch(setLoading(true));
    try {
      const response = await Axios.post(`${baseURL}/login`, formData);
      const data = response.data.data;
      if (response?.data?.access_token) {
        dispatch(setLoginState(response.data));
        setExpireTime(response?.data?.expires_in);
        setAccessTokenWithExpiry('accessToken', response?.data?.access_token, response?.data?.expires_in);
        setAccessTokenWithExpiry('refreshToken', response?.data?.refresh_token, response?.data?.expires_in);
        setAccessTokenWithExpiry('role', response?.data?.data?.role_id, response?.data?.expires_in);
        localStorage.setItem('login', JSON.stringify(data));
        if (rememberMe == true) {
          localStorage.setItem('email', JSON.stringify(formData.email));
          localStorage.setItem('password', JSON.stringify(formData.password));
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        dispatch(setLoading(false));
        ToastMessage({
          type: 'success',
          message: response.data.message,
        });
        navigate('/dashboard');
      } else {
        ToastMessage({
          type: 'error',
          message: response.data.message,
        });
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoading(false));
    }
  };

  const setAccessTokenWithExpiry = (key: string, value: string, expiryInSeconds: number) => {
    const options = {
      path: '/',
      httpOnly: false,
      expires: dayjs().add(expiryInSeconds, 'seconds').toDate(),
      secure: true,
      sameSite: 'none' as 'none',
    };

    Cookies.set(key, value, options);

    setTimeout(() => {
      removeAccessToken(key);
    }, expiryInSeconds * 1000);
  };
  const removeAccessToken = (key: any) => {
    navigate(location?.pathname);
  };
  const handleLogin = async (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        LoginAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };
  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value.trim();
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Box>
      <CustomContainer>
        <form onSubmit={handleLogin} method="post">
          <Box
            sx={{
              background: colors.dark[800],
              borderRadius: '24px',
              color: 'white',
              p: { md: '60px 70px', xs: '10px 10px' },
              display: { md: 'flex', xs: 'block' },
            }}
          >
            <Grid
              container
              sx={{
                display: { md: 'flex', xs: 'block' },
                '&.MuiGrid-root ': { ml: '0' },
                '& .grid-root': {
                  paddingLeft: { md: '60px', xs: '0' },
                },
              }}
            >
              <Grid item md={6} xs={12}>
                <CardMedia
                  component="img"
                  image={SignInImg}
                  alt="sign-in-img"
                  sx={{
                    borderRadius: '20px',
                  }}
                />
                <Box mt={'25px'}>
                  <Typography variant="body2" color={colors.gray[300]} fontWeight="400">
                    <strong>Step into the Spotlight :</strong> Welcome back to our influencer community! Sign
                    in to your account and shine bright with the latest tips, trends, and collaborations.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                className="grid-root"
                item
                md={6}
                xs={12}
                sx={{
                  '& .MuiGrid-item': {
                    background: 'red',
                    paddingLeft: '0',
                    paddingRight: '60px',
                  },
                }}
              >
                <Box sx={{ p: { md: '0 10px', xs: '0 0' }, width: '100%' }}>
                  <Box pt={{ md: 0, xs: '10px' }}>
                    <Typography variant="h1">Welcome to QuickFluence</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '36px',
                    }}
                  >
                    <TextInput
                      title="Email"
                      onChange={handleChange}
                      value={formData.email}
                      name="email"
                      placeholder="Enter your email address"
                    />{' '}
                    {formErrors.email && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.email}
                      </div>
                    )}
                  </Box>
                  <Box sx={{ mt: '17px' }}>
                    <TextInput
                      title="Password"
                      name="password"
                      onChange={handleChange}
                      value={formData.password || ''}
                      placeholder="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      EndIcon={
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <EyeOn /> : <EyeOff />}
                        </IconButton>
                      }
                    />
                    {formErrors.password && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.password}
                      </div>
                    )}
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pt={'20px'}
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        mr: '0',
                      },
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        sx={{
                          '& .MuiTypography-body1': {
                            color: colors.gray[300],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{
                              '& span': {
                                width: { xs: '17px', sm: '20px !important' },
                                height: { xs: '17px', sm: '20px !important' },
                              },
                              '& span::before': {
                                width: { xs: '17px !important', sm: '20px !important' },
                                height: { xs: '17px !important', sm: '20px !important' },
                              },
                            }}
                            name="rememberMe"
                            checkedIcon={<CheckedIcon />}
                            icon={<UnCheckedIcon />}
                            disableRipple
                            checked={rememberMe}
                            onChange={(evt: any) => {
                              setRememberMe(evt.target.checked);
                              localStorage.setItem('rememberMe', evt.target.checked.toString());
                            }}
                          />
                        }
                        label={'Remember me'}
                      />
                    </Box>
                    <Box>
                      <Link
                        to="/forgot-password"
                        style={{
                          color: colors.gray[300],
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '24px',
                          textDecoration: 'none',
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Box>
                  </Box>
                  <Box pt={'35px'}>
                    <Button type="submit" sx={{ width: '100%' }}>
                      Login
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      pt: '42px',
                      '& .MuiDivider-root': {
                        '&::before': {
                          borderTop: `2px solid ${colors.dark[100]}`,
                        },
                        '&::after': {
                          borderTop: `2px solid ${colors.dark[100]}`,
                        },
                        '& .MuiDivider-wrapper ': {
                          pl: '26px',
                          pr: '26px',
                        },
                      },
                    }}
                  >
                    <Divider sx={{ color: colors.gray[300] }}>or continue with</Divider>
                  </Box>
                  <Box pt={'30px'}>
                    <Button
                      onClick={() => login()}
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: 'white',
                        border: `2px solid ${colors.dark[100]}`,
                        fontWeight: '500',
                      }}
                      startIcon={<Google />}
                    >
                      Sign in with google
                    </Button>
                  </Box>
                  <Box mt={{ md: '50px', xs: '20px' }} mb={{ md: '0', xs: '20px' }} textAlign="center">
                    <Typography
                      variant="body2"
                      fontWeight="400"
                      lineHeight={'180%'}
                      sx={{ color: colors.gray[300] }}
                    >
                      Don’t have an account?{' '}
                      <Link
                        to="/sign-up"
                        // to='/account-setup'
                        style={{
                          color: colors.basics.primary,
                          textDecoration: 'none',
                        }}
                      >
                        Sign Up
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </CustomContainer>
    </Box>
  );
};

export default SignIn;
