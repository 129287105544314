import { Button, Drawer, List, Stack, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../styles/color';
import sizeConfigs from '../../styles/sizeConfigs';
import Logo from '../../assets/images/QuickFluenceLogo.png';
import SidebarItemCollapse from './SidebarItemCollapse';
import SidebarItem from './SidebarItem';
import appRoutes from '../../routes/appRoutes';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { setDrawerState } from '../../redux/features/drawer/drawerSlice';
import LeftDrawer from '../LeftDrawer';
import { useCookies } from 'react-cookie';
import { LogOut } from '../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppReducer';
import { ApplicationStateType } from '../../redux/store';
import { setDialogState, setLoading } from '../../redux/features';
import LogOutModel from '../../pages/logoutModel/LogoutModel';

const Sidebar = () => {
  const drawerState = useAppSelector((state: ApplicationStateType) => state.drawer.drawerState);
  const logout = useAppSelector((state: ApplicationStateType) => state.dialog.dialog.logout);
  const [direction, setDirection] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [token, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);

  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  useEffect(() => {
    if (token.accessToken) {
    } else {
      navigate('/sign-in');
    }
  }, []);

  return (
    <Box>
      <Drawer
        variant='permanent'
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          display: { xl: 'flex', xs: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            borderRight: '0px',
            backgroundColor: colors.dark[800],
            color: colors.basics.primary,
            overflowY: 'unset',
            width: '234px',
          },
        }}
      >
        <List disablePadding>
          <Box onClick={() => navigate('/dashboard')} sx={{cursor:"pointer"}}>
          <Toolbar sx={{ marginBottom: '20px' }} >
            <Stack sx={{ width: '100%' }} direction='row' justifyContent='center' mt={'38px'}>
              <img src={Logo} width={'166px'} height={'35px'} alt='logo' />
            </Stack>
          </Toolbar></Box>

          {appRoutes.map((route: any, index: any) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
        <Box
          sx={{
            position: 'absolute',
            display: 'inline-block',
            bottom: 10,
            left: '15px',
            width: ' 100%',
            ml: '10px',
          }}
        >
          <Button
            onClick={() => {
              dispatch(
                setDialogState({
                  loginDialog: false,
                  registerDialog: false,
                  modal: true,
                  otpDialog: { otpDialog: false, fromPageName: '' },
                  privacyDialog: false,
                  resetPassword: false,
                  successReset: false,
                  termsDialog: false,
                  forgotPassword: false,
                  logout: true,
                })
              );
            }}
            variant='text'
            startIcon={<LogOut />}
            sx={{ color: colors.gray[300] }}
          >
            Logout
          </Button>
        </Box>
      </Drawer>
      <Box>
        <LeftDrawer />
      </Box>
      {logout && <LogOutModel />}
    </Box>
  );
};

export default Sidebar;
