import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
const initialState = {
  influencerList: [],
};

export const fetchInfluencerListDetails = createAsyncThunk(
  'influencerDetails/fetchInfluencerListDetails',
  async () => {
    const responseUpdate = await API.get(`/top-influencer`);
    return responseUpdate.data;
  }
);

const getTopInfluencerSlice = createSlice({
  name: 'influencerList',
  initialState,
  reducers: {
    setInfluencerListState: (state, { payload }) => {
      state.influencerList = payload;
    },
  },
});

export const { setInfluencerListState } = getTopInfluencerSlice.actions;

export default getTopInfluencerSlice.reducer;
