import { Button, Typography, Box } from '@mui/material';
import { colors } from '../../styles/color';
import { ReactComponent as EditWhiteIcon } from '../../assets/icons/EditWhiteIcon.svg';
import Topbar from '../../components/common/Topbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import { useEffect, useState } from 'react';
import { setLoading } from '../../redux/features/loader/loaderSlice';
import { EditProfile } from './EditProfile';
import { API } from '../../api/services';
import parse from 'html-react-parser';
import { ToastMessage } from '../../helpers/ToastMessage';
import { isEmpty } from '../../utils/commanFunctions/commanFunctions';
import { setAppState } from '../../redux/features/appStateSlice';

const ProfilePage = () => {
  const { profileData } = useSelector((state: any) => state?.profile);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [coverImg, setCoverImg] = useState<any>();

  useEffect(() => {
    dispatch(setAppState('profile'));
    const getProfileData = async () => {
      try {
        dispatch(setLoading(true));
        const res = await dispatch(fetchProfileDetails());
        dispatch(setProfileState(res?.payload));
        dispatch(setLoading(false));
      } catch (error: any) {
        ToastMessage({
          type: 'error',
          message: error.error,
        });
        dispatch(setLoading(false)); // Make sure to set loading to false even in case of an error
      }
    };
    if (Object.keys(profileData).length === 0) getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data: any = e.target.files && e.target.files[0];

    if (data) {
      const fileFormat = data.type;
      const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];

      if (!validFormats.includes(fileFormat)) {
        ToastMessage({
          type: 'error',
          message: 'Please select a valid image file (PNG, JPG, JPEG).',
        });
      } else {
        const fileSize = data.size;
        const fileSizes = Math.round(fileSize / 1024);
        if (fileSizes > 1024 * 5) {
          ToastMessage({
            type: 'error',
            message: 'Please select a file less than 5MB.',
          });
        } else {
          const formData = new FormData();
          if (data) formData.append('cover', data);
          setCoverImg(data);
          await API.post(`/cover-image`, formData, {});
        }
      }
    }
  };
  const handleEditSubmit = () => {
    setToggle(true);
  };
  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      {!toggle ? (
        <>
          <Topbar>
            <Box
              sx={{
                '& .MuiToolbar-root': {
                  pl: { xl: 0, xs: '20px' },
                },
              }}
            >
              <Box pl={{ xl: 0, sm: '20px', xs: '10px' }}>
                <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                  Profile
                </Typography>
              </Box>
            </Box>
          </Topbar>
          <Box sx={{ p: '0px 16px', mt: '16px', mb: '16px' }}>
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                '& .MuiCircularProgress-root': {
                  color: colors.basics.primary,
                },
              }}
            ></Box>
            <Box bgcolor={colors.dark[800]} borderRadius='12px'>
              <Box>
                <img
                  src={coverImg ? URL.createObjectURL(coverImg) : profileData?.cover}
                  alt='cover'
                  style={{
                    width: '100%',
                    height: '30vh',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    minHeight: '263px',
                    display: 'block',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: 'auto',
                    p: '30px',
                    '& svg': {
                      color: 'red',
                    },
                    position: 'absolute',
                    right: '10px',
                    top: '103px',
                  }}
                >
                  <Box>
                    <input
                      type='file'
                      style={{ display: 'none' }}
                      name='file'
                      id='multiFiles'
                      // accept='image/jpeg, image/png, image/jpg'
                      onChange={handleChange}
                    />
                  </Box>
                  <label
                    htmlFor='multiFiles'
                    style={{
                      cursor: 'pointer',
                      background: '#0d0d0d4d',
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '3px',
                    }}
                  >
                    <EditWhiteIcon />
                  </label>
                </Box>
              </Box>
              <Box ml={{ sm: '60px ', xs: '20px' }} mr={'20px'} pb='24px'>
                <Box display={'flex'}>
                  <Box mt={'-60px'}>
                    <img
                      src={profileData?.profile}
                      width={'118px'}
                      height={'118px'}
                      alt='bg-img'
                      style={{
                        border: `2px solid ${colors.basics.primary}`,
                        borderRadius: '50%',
                        padding: '5px',
                        background: colors.dark[800],
                        width: '118px',
                        height: '118px',
                        display: 'block',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  <Box
                    ml={{ sm: '30px', xs: '15px' }}
                    mt='18px'
                    sx={{
                      '& .MuiButton-root': {
                        p: { sm: '6px 0px', xs: '0' },
                        textDecorationLine: 'underline',
                        '&:hover': {
                          textDecorationLine: 'underline',
                        },
                      },
                    }}
                  >
                    <Typography
                      variant='h2'
                      fontSize={{ sm: '24px', xs: '20px' }}
                      textAlign={'left'}
                      mt={'6px'}
                    >
                      {profileData?.name}
                    </Typography>
                    <Button onClick={handleEditSubmit} variant='text'>
                      Edit Profile
                    </Button>
                  </Box>
                </Box>
                <Box
                  bgcolor={colors.dark[600]}
                  maxWidth='450px'
                  p={'14px 20px'}
                  borderRadius='10px'
                  mt={'39px'}
                >
                  <Typography variant='subtitle1' fontWeight={500} color={colors.gray[300]}>
                    Email
                  </Typography>
                  <Typography variant='body2' fontWeight={400} lineHeight={'24px'} mt={'6px'}>
                    {profileData?.email}
                  </Typography>
                </Box>
                <Box
                  bgcolor={colors.dark[600]}
                  maxWidth='450px'
                  p={'14px 20px'}
                  borderRadius='10px'
                  mt={'10px'}
                >
                  <Typography variant='subtitle1' fontWeight={500} color={colors.gray[300]}>
                    Contact Number
                  </Typography>
                  <Typography variant='body2' fontWeight={400} lineHeight={'24px'} mt={'6px'}>
                    {profileData?.contact}
                  </Typography>
                </Box>
                <Box
                  bgcolor={colors.dark[600]}
                  maxWidth='450px'
                  minHeight='242px'
                  p={'14px 20px'}
                  borderRadius='10px'
                  mt={'10px'}
                >
                  <Typography variant='subtitle1' fontWeight={500} color={colors.gray[300]}>
                    About
                  </Typography>
                  <Typography variant='body2' fontWeight={400} lineHeight={'24px'} mt={'6px'}>
                    {isEmpty(profileData?.bio) === '' ? '' : parse(profileData?.bio)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <EditProfile toggle={toggle} setToggle={setToggle} />
      )}
    </Box>
  );
};

export default ProfilePage;
