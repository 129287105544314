import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../styles/color';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BackButton } from '../assets/icons';
import { CustomContainer } from '../components/CustomContainer';
import { StoreChannel } from '../components/common/StoreChannel';
const ChannelDetails = () => {
  const [show, setShow] = React.useState(false);
  //! Hooks
  const [_, __, removeAccessTokenToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  //! Variables
  const navigate = useNavigate();
  return (
    <CustomContainer>
      <>
        <Box
          sx={{
            background: colors.dark[800],
            borderTopRightRadius: '24px',
            borderTopLeftRadius: '24px',
            color: 'white',
            p: { md: '30px 30px', xs: '20px 15px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ flex: { md: '0 0 40%', xs: '0 0 30%' } }}>
              <Button
                variant='text'
                onClick={() => {
                  navigate('/dashboard');
                }}
              >
                <BackButton />
              </Button>
            </Box>
            <Box sx={{ flex: { md: '0 0 40%', xs: '0 0 70%' } }}>
              <Typography sx={{ fontSize: { md: '20px', xs: '16px' } }} fontWeight={600}>
                Setup YouTube channel details
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            '& .MuiDivider-root': {
              borderTop: `1px solid ${colors.dark[100]}`,
            },
          }}
        >
          <Divider sx={{ color: colors.gray[300] }} />
        </Box>
        <Box
          sx={{
            height: '100%',
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
            borderBottomRightRadius: '24px',
            borderBottomLeftRadius: '24px',
            background: colors.dark[800],
            color: 'white',
            p: { md: '20px 100px', xs: '20px 10px' },
          }}
        >
          <StoreChannel show={show} setShow={setShow} />
        </Box>
      </>
    </CustomContainer>
  );
};

export default ChannelDetails;
