import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

export const fetchNotification: any = createAsyncThunk('getNotification/fetchNotification', async () => {
  const response = await API.get(`/notification`);
  return response?.data;
});

const initialState = {
  notificationDetails: {},
};

const NotificationDetails = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationState: (state, { payload }) => {
      state.notificationDetails = payload;
    },
  },
});

export const { setNotificationState } = NotificationDetails.actions;
export default NotificationDetails.reducer;