import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

export const fetchProfileUpdateDetails: any = createAsyncThunk(
  'profileUpdateDetails/fetchProfileUpdateDetails',
  async (profileEditData: any) => {
    const responseUpdate = await API.post(`/profile`, profileEditData);
    return responseUpdate.data;
  }
);
const initialState = {
  profileEditData: {},
  status: '',
  loading: false,
};

const profileEditSlice = createSlice({
  name: 'profileEditDetails',
  initialState,
  reducers: {
    setProfileUpadteState: (state, { payload }) => {
      state.profileEditData = payload;
    },
  },
  extraReducers: {
    [fetchProfileUpdateDetails.pending]: (state) => {
      state.status = 'PENDING';
      state.loading = true;
    },
    [fetchProfileUpdateDetails.fulfilled]: (state, { payload }) => {
      if (state.status !== 'SUCCESS') {
        state.profileEditData = payload;
        state.loading = false;
      }
      state.status = 'SUCCESS';
      state.profileEditData = payload;
      state.loading = false;
    },
    [fetchProfileUpdateDetails.rejected]: (state) => {
      state.status = 'REJECTED';
      state.loading = false;
    },
  },
});

export const { setProfileUpadteState } = profileEditSlice.actions;
export default profileEditSlice.reducer;
