import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles/color';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  BarElement,
} from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRight, RightBackButton, Subscribers, ViewsIcon, VideoUploadIcon } from '../../assets/icons';
import NoInquiry from '../../assets/images/NoInquiry.png';
import { fetchDashboardDetails, setDashboardState } from '../../redux/features/dashboard/dashboardSlice';
import { setLoading } from '../../redux/features';
import NoDataFound from '../../components/common/NoDataFound';
import Image from '../../components/common/Image';
import { nFormatter } from '../../utils/commanFunctions/commanFunctions';
import userImage from '../../assets/images/p1.png';
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const data = {
  datasets: [
    {
      label: '# of Votes',
      data: [15, 8, 5, 3],
      backgroundColor: ['#45A5FF', ' #45D56D', ' #FFB257', '#F96767'],
      borderWidth: 0,
      radius: '100%',
      circumference: 300,
      hoverOffset: 20,
      spacing: -4,
      cutout: 90,
    },
  ],
};

export const DataDashboard = (props: any) => {
  const [loader, setLoader] = useState(true);
  const { dashboardData } = useSelector((state: any) => state?.dahsboard);
  const navigate = useNavigate();
  const profileData = props.profileData;

  const userData = [
    {
      value: `${
        dashboardData?.subscriber_count === undefined ? '' : nFormatter(dashboardData?.subscriber_count, 2)
      }`,
      titile: '  Subscribers',
      logo: <Subscribers />,
      bgColor: '#45A5FF',
    },
    {
      value: `${dashboardData?.view_count === undefined ? 0 : nFormatter(dashboardData?.view_count, 2)}`,
      titile: '  Views',
      logo: <ViewsIcon />,
      bgColor: '#FFB257',
    },
    {
      value: `${dashboardData?.video_count === undefined ? 0 : nFormatter(dashboardData?.video_count, 2)}`,
      titile: '  Videos Uploaded',
      logo: <VideoUploadIcon />,
      bgColor: '#F96767',
    },
  ];
  const dispatch = useDispatch();

  const getDashboardData = async (channelId: any) => {
    let obj = {
      channel_id: channelId,
    };
    const res = await dispatch(fetchDashboardDetails(obj));
    if (res?.payload?.data) {
      dispatch(setDashboardState(res?.payload?.data));
    } else {
      dispatch(setDashboardState({}));
    }
    setTimeout(() => {
      dispatch(setLoading(false));
      setLoader(false);
    }, 100);
  };

  useEffect(() => {
    if (profileData !== undefined) {
      if (Object.keys(profileData).length > 0 && loader) {
        dispatch(setLoading(true));
        const getProfileData = async () => {
          if (profileData?.channels?.length > 0) {
            let channelId = profileData.channels[0]?.id;
            getDashboardData(channelId);
          }
        };
        dispatch(setLoading(false));
        getProfileData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <Box>
      {!profileData.status ? (
        <Box
          sx={{
            '& .MuiAppBar-root': {
              position: 'sticky',
              top: 0,
            },
            '& .MuiPaper-root': {
              pr: 'none',
            },
          }}
        >
          {!loader ? (
            <Box sx={{ p: '16px 16px' }}>
              <Box display={{ md: 'flex', xs: 'block' }} flexDirection={{ md: 'row', xs: 'column' }}>
                {userData.map((item, index) => {
                  return (
                    <Box
                      className={'data'}
                      key={index}
                      flex={'25%'}
                      flexWrap='wrap'
                      mb={{ md: '0px', xs: '16px' }}
                      mr={{ md: '16px', xs: '0px' }}
                      display={'flex'}
                      sx={{
                        background: colors.dark[800],
                        p: '20px',
                        borderRadius: '12px',
                        '&:last-child': {
                          mr: { md: '0px', xs: '0px' },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background: item.bgColor,
                          borderRadius: '10px',
                          mr: '20px',
                          width: { lg: '56px', xs: '50px' },
                          height: { lg: '56px', xs: '50px' },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {item.logo}
                      </Box>
                      <Box>
                        <Typography
                          variant='body1'
                          fontSize='18px'
                          lineHeight='27px'
                          fontWeight={600}
                          pb={'8px'}
                        >
                          {item.value}
                        </Typography>
                        <Typography variant='subtitle1' fontWeight={500}>
                          {item.titile}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box sx={{ width: '100%', display: { md: 'flex', xs: 'block' } }}>
                <Box
                  width='100%'
                  display={{ md: 'flex', xs: 'block' }}
                  justifyContent={'space-between'}
                  mt='16px'
                >
                  <Box
                    sx={{ background: colors.dark[800], pb: '30px' }}
                    mb={{ md: '0px', xs: '20px' }}
                    borderRadius={'10px'}
                    flex='0 0 calc(50% - 8px)'
                    mr={{ md: '16px', xs: '0px' }}
                  >
                    <Box p={'25px'}>
                      <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                        Latest inquiry
                      </Typography>
                    </Box>
                    {Object.keys(dashboardData).length > 0 && dashboardData?.inquirys.length > 0 ? (
                      dashboardData?.inquirys.map((item: any, index: any) => {
                        return (
                          <Box key={index}>
                            <Box
                              onClick={() => navigate(`/dashboard/inquiry/${item.id}`)}
                              className='border-remove'
                              sx={{
                                cursor: 'pointer',
                              }}
                              m={'0px 25px'}
                              display='flex'
                              borderBottom={`1px solid ${colors.dark[100]}`}
                              alignItems='center'
                              justifyContent={'flex-start'}
                            >
                              <Box p={'15px 15px 15px 0px'}>
                                <img
                                  src={
                                    item?.sender_profile?.profile ? item?.sender_profile?.profile : userImage
                                  }
                                  width={44}
                                  height={44}
                                  style={{ objectFit: 'cover' }}
                                  alt='ImquiryImg'
                                />
                              </Box>
                              <Box>
                                <Box>
                                  <Typography variant='body2' lineHeight={'24px'} mb={'5px'}>
                                    {item?.subject}
                                  </Typography>
                                </Box>
                                <Box display='flex'>
                                  {' '}
                                  <Typography variant='subtitle1' fontWeight={400} color={colors.gray[300]}>
                                    {item.date}
                                  </Typography>
                                  <Typography
                                    p={'0px 10px'}
                                    variant='subtitle1'
                                    fontWeight={400}
                                    fontSize='30px'
                                    color={colors.gray[300]}
                                  >
                                    ·
                                  </Typography>
                                  <Typography variant='subtitle1' fontWeight={400} color={colors.gray[300]}>
                                    {item.time}
                                  </Typography>
                                </Box>
                              </Box>{' '}
                              <Box display={'flex'} justifyContent={'flex-end'} flexGrow={1}>
                                <ArrowRight />
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <NoDataFound
                        sx={{
                          textAlign: 'center',
                          margin: 'auto',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        svg={<Image src={NoInquiry} />}
                        text={'You have no inquiries yet'}
                      />
                    )}
                    {Object.keys(dashboardData).length > 0 && dashboardData?.inquirys.length > 0 && (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        p={'30px 0px 0px 0px'}
                        onClick={() => navigate('/dashboard/inquiry')}
                      >
                        <Button variant='text' endIcon={<RightBackButton />}>
                          View All Inquiries
                        </Button>
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ background: colors.dark[800] }} borderRadius={'10px'} flex='0 0 calc(50% - 8px)'>
                    <Box p={'25px'}>
                      <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                        Channels
                      </Typography>
                    </Box>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={'0px 25px'}>
                      {dashboardData?.channels?.map((item: any, index: any) => {
                        return (
                          <Grid item xs={12} sm={4} md={6} lg={6} xl={4} key={index}>
                            <Box sx={{ display: 'flex' }} key={index}>
                              <Box
                                onClick={() => navigate('/dashboard/social/youtube')}
                                sx={{
                                  background: colors.dark[600],
                                  borderRadius: '10px',
                                  textAlign: 'center',
                                  m: 'auto',
                                  display: 'block',
                                  pl: '0px 25px',
                                  p: '20px 45px',
                                  mb: '20px',
                                  maxWidth: '165px',
                                  height: '100%',
                                  width: '100%',
                                }}
                              >
                                <Box sx={{ mb: '12px' }}>
                                  <img
                                    src={item.image}
                                    height='70px'
                                    width='70px'
                                    style={{ borderRadius: '100px' }}
                                    alt='bg-img'
                                  />
                                </Box>
                                <Box sx={{ mb: '16px', whiteSpace: 'pre-wrap' }}>
                                  <Typography variant='body2' lineHeight={'24px'} color={colors.gray[300]}>
                                    {item.channel_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button variant='text'>
                                    <RightBackButton />
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <NoDataFound
          sx={{
            height: 'calc(100vh - 110px)',
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          svg={<Image src={NoInquiry} />}
          text={'Account is deactivated. Please contact admin.'}
        />
      )}
    </Box>
  );
};
