import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { API } from '../../api/services';
import { ToastMessage } from '../../helpers/ToastMessage';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/features';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import CommonSetting from '../../components/common/CommonSetting';
import { setAppState } from '../../redux/features/appStateSlice';

const SettingsPage = () => {
  const [open, setOpen] = useState(false);
  const [switchState, setSwitchState] = useState({
    notification: false,
    promotional: false,
    isProfileVisible: false,
  });

  const { profileData } = useSelector((state: any) => state?.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token] = useCookies();

  useEffect(() => {
    dispatch(setAppState('settings'));
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
    const getProfileData = async () => {
      dispatch(setLoading(true));
      const res = await dispatch(fetchProfileDetails());
      setTimeout(() => {
        const data = res?.payload;
        dispatch(setProfileState(res?.payload));
        setSwitchState({
          notification: data?.email_notification,
          promotional: data?.promotional_notification,
          isProfileVisible: data?.is_profile_visible,
        });
        dispatch(setLoading(false));
      }, 100);
    };
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [_, __, removeAccessTokenToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  const handleDelete = async () => {
    try {
      dispatch(setLoading(true));
      const res = await API.get('/delete-account');
      if (res.data.status) {
        removeAccessTokenToken('accessToken', { path: '/' });
        removeAccessTokenToken('refreshToken', { path: '/' });
        removeAccessTokenToken('googleToken', { path: '/' });
        removeAccessTokenToken('role', { path: '/' });

        ToastMessage({
          type: 'success',
          message: res.data.message,
        });
      }
      dispatch(setLoading(false));
      if (res.message === 'Unauthenticated') {
        removeAccessTokenToken('accessToken', { path: '/' });
        removeAccessTokenToken('refreshToken', { path: '/' });
        removeAccessTokenToken('googleToken', { path: '/' });
        removeAccessTokenToken('role', { path: '/' });
      }
    } catch (error: any) {
      if (error?.message === 'Unauthenticated') {
        removeAccessTokenToken('accessToken', { path: '/' });
        removeAccessTokenToken('refreshToken', { path: '/' });
        removeAccessTokenToken('googleToken', { path: '/' });
        removeAccessTokenToken('role', { path: '/' });

        dispatch(setLoading(false));
      }
    }
  };

  const handleClick = async (name: any, value: any) => {
    let requst = {};

    if (name === 'notification')
      requst = {
        email_notification: value === true ? 1 : 0,
        promotional_notification: profileData.promotional_notification ? 1 : 0,
        is_profile_visible: switchState.promotional ? 1 : 0,
      };

    if (name === 'promotional') {
      requst = {
        email_notification: switchState?.notification ? 1 : 0,
        promotional_notification: value === true ? 1 : 0,
      };
    }

    if (name === 'isProfileVisible') {
      requst = {
        email_notification: switchState?.notification ? 1 : 0,
        promotional_notification: switchState.promotional ? 1 : 0,
        is_profile_visible: value === true ? 1 : 0,
      };
    }

    dispatch(setLoading(true));
    await API.post('/setting', requst)
      .then((res: any) => {
        if (res.data.status) {
          ToastMessage({
            type: 'success',
            message: res.data.message,
          });
        } else {
          ToastMessage({
            type: 'error',
            message: res.data.message,
          });
        }
        dispatch(setLoading(false));
      })
      .catch((e: any) => {
        ToastMessage({
          type: 'error',
          message: e.message,
        });
        dispatch(setLoading(false));
      });
  };

  const handleSwitchChange = (event: any) => {
    const name = event.target.name;
    setSwitchState({ ...switchState, [name]: event.target.checked });
    handleClick(name, event.target.checked);
  };

  return (
    <CommonSetting
      isInfluencer={token.role === '2' ? true : false}
      switchState={switchState}
      handleSwitchChange={(e: any) => handleSwitchChange(e)}
      navigate={navigate}
      handleClickOpen={() => {
        handleClickOpen();
      }}
      open={open}
      handleClose={() => {
        handleClose();
      }}
      handleDelete={() => {
        handleDelete();
      }}
    />
  );
};

export default SettingsPage;
