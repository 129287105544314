import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../styles/color';
import { useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import { CompleteLogo } from '../assets/icons';

const CompleteSetup = () => {
  //! Variables
  const navigate = useNavigate();

  return (
    <Box>
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px 0px 35px', xs: '20px 10px' },
            display: 'block',
            minHeight: 'calc(100vh - 169px)',

          }}
        >
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '100px', xs: '50px' },
              mb: { sm: '200px', xs: '30px' },
              textAlign: 'center',
            }}
          >
            <Box>
              <CompleteLogo />
            </Box>
            <Box mt={'39px'}>
              <Typography variant='h1'>Setup completed</Typography>
              <Typography variant='body2' color={colors.gray[300]}>
                Your YouTube channel setup has been completed.
              </Typography>
            </Box>

            <Box pt={'35px'}>
              <Button variant='contained' sx={{ width: '100%' }} onClick={() => navigate('/dashboard')}>
                Ok
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default CompleteSetup;
