import { Box, Button, Typography } from '@mui/material';
import { colors } from '../styles/color';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '../assets/icons';
import { useAppSelector } from '../hooks/useAppReducer';
import { ApplicationStateType } from '../redux/store';
import { setDialogState } from '../redux/features';

const TermsAndConditions = () => {
  const { staticData } = useSelector((state: any) => state?.staticData);
  const {
    dialog: { termsDialog },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Applying on mount
    document.body.style.overflow = 'hidden';
    // Applying on unmount
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);
  
  return (
    <Box sx={{'& .MuiContainer-root':{
      p:'0 !important'
    }}}>
      <CustomContainer>
        <>
          <Box
            sx={{
              background: colors.dark[800],
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
              color: 'white',
              p:{md: '30px 30px',xs:'30px 0px'},
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Box sx={{ flex: { sm: '0 0 40%', xs: '0 0 20%' } ,
              '& .MuiButton-root':{p:{sm:'0',xs:'6px 10px 6px 0px'}}}}>
                <Button
                  variant='text'
                  onClick={() => {
                    if (termsDialog === true) {
                      dispatch(
                        setDialogState({
                          loginDialog: false,
                          registerDialog: true,
                          modal: true,
                          otpDialog: { otpDialog: false, fromPageName: '' },
                          privacyDialog: false,
                          resetPassword: false,
                          successReset: false,
                          termsDialog: false,
                          forgotPassword: false,
                        })
                      );
                    } else {
                      navigate('/sign-in');
                    }
                  }}
                >
                  <BackButton />
                </Button>
              </Box>
              <Box sx={{ flex: { sm: '0 0 40%', xs: '0 0 80%' } }}>
                <Typography variant='body1' fontWeight={600}>
                  Terms & Conditions
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              '& .MuiDivider-root': {
                borderTop: `1px solid ${colors.dark[100]}`,
              },
            }}
          >
            <Divider sx={{ color: colors.gray[300] }} />
          </Box>
          <Box
            sx={{
              '&::-webkit-scrollbar': {
                width: 0,
              },
              // height: 'calc(100vh - 230px)',
              height: {sm:'calc(100vh - 230px)', xs:'calc(100vh - 130px)'},
              overflow: 'auto',
              borderTopRightRadius: '0px',
              borderTopLeftRadius: '0px',
              background: colors.dark[800],
              color: 'white',
              p: { md: '20px 154px', xs: '0px' },

              '& .MuiTypography-root': {
                '&:nth-of-type(odd)': {
                  color: colors.basics.white,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: '150%',
                },
                '&:nth-of-type(even)': {
                  color: colors.gray[300],
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 15,
                  lineHeight: '180%',
                },
              },
            }}
          >
            <Box>
              <Box>  
                    <Typography 
                      dangerouslySetInnerHTML={{
                        __html: staticData?.data?.description,
                      }}
                    />
              </Box>
            </Box>
          </Box>
        </>
      </CustomContainer>
    </Box>
  );
};

export default TermsAndConditions;
