import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { colors } from '../styles/color';
import { useLocation, useNavigate } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { CustomContainer } from '../components/CustomContainer';
import { ToastContainer, toast } from 'react-toastify';
import { API } from '../api/services';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/features/loader/loaderSlice';
import { BackButton, EyeOff, EyeOn, ForgotLogo } from '../assets/icons';
import * as yup from 'yup';
import { ToastMessage } from '../helpers/ToastMessage';
import { setDialogState } from '../redux/features';
import { ApplicationStateType } from '../redux/store';
import { useAppSelector } from '../hooks/useAppReducer';
interface FormErrors {
  password?: string;
  confirmpassword?: string;
}
interface ShowPasswordsState {
  password: boolean;
  confirmpassword: boolean;
}
const NewPassword = () => {
  //! Hooks
  const { signupData } = useAppSelector((state: ApplicationStateType) => state.signup);
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [showPasswords, setShowPasswords] = useState<ShowPasswordsState>({
    password: false,
    confirmpassword: false,
  });
  const {
    dialog: {
      newPassword: newPassword,
    },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);
  const {
    dialog: {
      resetPassword: resetPassword,
    },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);
  //! Variables
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let formDetails = location.state;
  //! Functions
  const handleClickShowPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    const passwordField = event.currentTarget.name as keyof ShowPasswordsState;
    setShowPasswords({
      ...showPasswords,
      [passwordField]: !showPasswords[passwordField],
    });
  };
  const url = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1)
  const schema = yup.object().shape({
    password: yup
      .string()

      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must be match')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Confirm password is required'),
  });

  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const resetPasswordAPICall = async () => {
    setFormErrors({})
    dispatch(setLoading(true));
    try {
      const response = await API.post('/reset-password', {
        ...formDetails,
        ...formData,
        ...signupData
      });

      const resetData = response?.data;
      if (resetData?.status) {
        ToastMessage({
          type: "success",
          message: resetData.message
        })

        if (url === "new-password") {
          navigate('/sign-in');
        } else {
          dispatch(setDialogState({
            loginDialog: false,
            registerDialog: false,
            modal: true,
            otpDialog: { otpDialog: false, fromPageName: '' },
            privacyDialog: false,
            resetPassword: true,
            successReset: false,
            termsDialog: false,
            forgotPassword: false,
            newPassword: false
          }))
        }
      }
    } catch (error: any) {
      ToastMessage({
        type: "error",
        message: error.message
      })
    }
    dispatch(setLoading(false));
  };
  const handleResetPassword = (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        resetPasswordAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };
  return (
    <Box>
      <ToastContainer />
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px', xs: '20px 10px' },
            display: 'block',
          }}
        >
          {newPassword && <Box>
            <Button variant='text' onClick={() => {
              dispatch(setDialogState({
                loginDialog: true,
                registerDialog: false,
                modal: true,
                otpDialog: { otpDialog: false, fromPageName: '' },
                privacyDialog: false,
                resetPassword: false,
                successReset: false,
                termsDialog: false,
                forgotPassword: false,
                newPassword: false
              }))
            }}>
              <BackButton />
            </Button>
          </Box>}
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',

              mt: { sm: '60px', xs: '30px' },
              mb: { sm: '60px', xs: '30px' },

              textAlign: 'center',
            }}
          >
            <Box>
              <ForgotLogo />
            </Box>
            <Box mt={'39px'}>
              <Typography variant='h1'>Set New Password</Typography>
            </Box>
            <Box mt={'7px'}>
              <Typography variant='body2' color={colors.gray[300]}>
              Please enter your new password below
              </Typography>
            </Box>
            <Box sx={{ mt: '17px' }}>
              <TextInput
                name='password'
                onChange={handleChange}
                title='Password'
                placeholder='Enter your password'
                type={showPasswords.password ? 'text' : 'password'}
                EndIcon={
                  <IconButton onClick={handleClickShowPassword} name='password'>
                    {showPasswords.password ? <EyeOn /> : <EyeOff />}
                  </IconButton>
                }
              />
              {formErrors.password && (
                <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: "3px" }}>{formErrors.password}</div>
              )}
            </Box>
            <Box sx={{ mt: '17px' }}>
              <TextInput
                name='confirmpassword'
                onChange={handleChange}
                title='Confirm Password'
                placeholder='Enter confirm password'
                type={showPasswords.confirmpassword ? 'text' : 'password'}
                EndIcon={
                  <IconButton onClick={handleClickShowPassword} name='confirmpassword'>
                    {showPasswords.confirmpassword ? <EyeOn /> : <EyeOff />}
                  </IconButton>
                }
              />
              {formErrors.confirmpassword && (
                <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: "3px" }}>{formErrors?.confirmpassword}</div>
              )}
            </Box>
            <Box pt={'35px'}>
              <Button variant='contained' sx={{ width: '100%' }} type='submit' onClick={handleResetPassword}>
                Reset Password
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer >
    </Box >
  );
};

export default NewPassword;
