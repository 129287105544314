import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

export const fetchDeleteChannelData: any = createAsyncThunk(
  'DeleteChannel/fetchList',
  async (id: any) => {
    const response = await API.get(`/delete-channel?channel_id=${id}`);
    return response.data;
  }
);
const initialState = {
   deleteChannel: {},
};
const deleteChannelSlice = createSlice({
  name: 'DeleteChannel',
  initialState,
  reducers: {
    setdeleteChannelelState: (state, { payload }) => {
      state.deleteChannel = payload;
    },
  },
});
export const { setdeleteChannelelState } = deleteChannelSlice.actions;
export default deleteChannelSlice.reducer;
