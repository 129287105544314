import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterData: {},
    isBackButtonClick: false,
    searchParams: "",
    directBack: false
};

const staticDataSlice = createSlice({
    name: 'statiDataDetails',
    initialState,
    reducers: {
        setStoreData: (state, { payload }) => {
            state.filterData = payload;
        },
        setBackButton: (state, { payload }) => {
            state.isBackButtonClick = payload;
        },
        setSearchParams: (state, { payload }) => {
            state.searchParams = payload;
        },
        setDirectBack: (state, { payload }) => {
            state.directBack = payload;
        }
    },

});

export const { setStoreData, setBackButton, setSearchParams, setDirectBack } = staticDataSlice.actions;
export default staticDataSlice.reducer;