import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Dropzone from 'react-dropzone';
import { Close, Doller, EditThumbnail, InfoEmail, Line, Search, UploadIcon } from '../../assets/icons';
import { colors } from '../../styles/color';
import AutoComplete from '../AutoComplete';
import CountryAutoComplete from '../CountryAutoComplete';
import { SelectInput } from '../SelectInput';
import TextInput from '../TextInput';

import { useDispatch, useSelector } from 'react-redux';
import { fetchCountryeDetails, setCountryState } from '../../redux/features/country/countrySlice';
import {
  fetchCategoryDetails,
  setCategoryState,
} from '../../redux/features/channelCategory/channelCategorySlice';
import { fetchAudioDetails, setAudioState } from '../../redux/features/channelAudioList/channelAudioSlice';
import { fetchAddChannelData, setaddChannelState } from '../../redux/features/addChannel/addChannelSlice';
import { durationTime } from '../../constants/menuItems';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ToastMessage } from '../../helpers/ToastMessage';
import ErrorTextBox from './ErrorTextBox';
import { setLoading } from '../../redux/features';
import { onlyNumbers, validateMinSec } from '../../utils/commanFunctions/commanFunctions';
import CkEditoComponent from './CkEditorComponent';
import {
  fetchContentCategoryDetails,
  setContentCategory,
} from '../../redux/features/contentCategory/contentCategorySlice';
import ContentCategoryAutoComplete from '../layout/ContentCategoryAutoComplete';
import { toast } from 'react-toastify';

interface Props {
  show?: boolean;
  setShow?: any;
}
interface FormErrors {
  category?: string;
  uploadTime?: string;
  country?: any;
  language?: string;
  addChannel?: string;
  email?: string;
  description?: string;
  file?: string;
  valueData?: string;
  videoType?: any;
  contentCat?: any;
}
export const StoreChannel = ({ show, setShow }: Props) => {
  const { channelSuggestionList } = useSelector((state: any) => state.channelSuggestionList);
  const { countryData } = useSelector((state: any) => state.country);
  const { categoryData } = useSelector((state: any) => state?.category);
  const [addChannel, setAddChannel] = useState<string | null>(null);
  const [category, setCategory] = useState<any>('');
  const [minimumPrice, setMinimumPrice] = useState<any>();
  const [uploadTime, setUploadTime] = useState<any>();
  const [valueData, setValueData] = useState('');
  const [country, setCountry] = useState<any[]>([]);
  const [email, setEmail] = useState<any>('');
  const [language, setLanguage] = useState<any[]>([]);
  const { audioData } = useSelector((state: any) => state?.audio);
  const [description, setDescription] = useState('');
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [contentCatData, setContentcatData] = useState<any[]>([]);
  const [contentCat, setContentCat] = useState<any>();
  const [videoType, setVideoType] = useState<any>('1');
  const [shortsPrice, setShortsPrice] = useState<any>();
  const [file, setFile] = useState(null);
  const [isVideoFieldDisplay, setIsVideoFieldDisplay] = useState(false);
  const [isShortVideoDisplay, setIsShortVideoDisplay] = useState(false);
  const [bothFiled, setIsBothField] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');
  const handleDrop = React.useCallback((acceptedFiles: File[]) => {
    setFormErrors({});
    const data: any = acceptedFiles && acceptedFiles[0];

    if (data) {
      const fileSize = data.size;
      var fileSizes = Math.round(fileSize / 1024);
      if (fileSizes > 1024 * 5) {
        ToastMessage({
          type: 'error',
          message: 'Please select a file less than 5MB.',
        });
      } else {
        setFile(data);
      }
    }
  }, []);

  useEffect(() => {
    if (videoType === '2') {
      setIsVideoFieldDisplay(true);
      setIsShortVideoDisplay(false);
      setIsBothField(false);
    } else if (videoType === '3') {
      setIsShortVideoDisplay(true);
      setIsVideoFieldDisplay(false);
      setIsBothField(false);
    } else if (videoType === '1') {
      setIsBothField(true);
      setIsShortVideoDisplay(false);
      setIsVideoFieldDisplay(false);
    }
  }, [videoType]);

  const schema = yup.object().shape({
    addChannel: yup.string().required('Please select a channel name'),
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    category: yup.string().required('Please select a category'),
    uploadTime: yup.string().required('Please select a upload time'),
    country: yup.array().min(1, 'Please select at least one country').required('Please select a country'),
    language: yup
      .array()
      .min(1, 'Please select at least one video audio language')
      .required('Please select video audio language'),

    description: yup
      .string()
      .min(10, 'Description must be at least 10 characters')
      .required('Description is required'),
    contentCat: yup
      .array()
      .min(1, 'Please select at least one content category')
      .required('Please select a content category'),
    videoType: yup.number().required('Please select a video type'),
    file: yup.string().required('Drang and drop or browser to upload thumbnail'),
    valueData: yup
      .string()
      .test(
        'not-00-0e',
        'Value cannot be "00:0e" or contain invalid characters, only numbers are allowed',
        (value: any) => {
          if (value === '00:0e' || /[^\d:]/.test(value)) {
            return false; 
          }
          return true;
        }
      ),
  });

  const handleClear = () => {
    setFile(null);
  };

  const useFetchData = (fetchFunc: any, setFunc: any) => {
    useEffect(() => {
      const fetchData = async () => {
        const res = await dispatch(fetchFunc());
        dispatch(setFunc(res));
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  useEffect(() => {
    const contentCategoryData = async () => {
      const data = await dispatch(fetchContentCategoryDetails());

      if (data) {
        setContentcatData(data?.payload);
      } else {
        toast.error('Data not found');
      }
    };
    contentCategoryData();
  }, []);

  useFetchData(fetchCountryeDetails, setCountryState);
  useFetchData(fetchCategoryDetails, setCategoryState);
  useFetchData(fetchAudioDetails, setAudioState);
  useFetchData(fetchContentCategoryDetails, setContentCategory);

  const handleChange = (value: any) => {
    setCountry(value);
  };
  const handleLanguageChange = (value: any) => {
    setLanguage(value);
  };

  // const selectedLanguageIds = language?.map(
  //   (language) => audioData?.find((audioData: any) => audioData.name === language)?.id
  // );
  const ChannelAddAPICall = async () => {
    setFormErrors({});
    dispatch(setLoading(true));
    const formData = new FormData();
    if (file) formData.append('thumbnail', file);
    const getID = channelSuggestionList?.filter((item: any) => item?.name === addChannel);
    formData.append('youtube_channel_id', getID[0]?.id);
    formData.append('category_id', category);
    formData.append('upload_time', uploadTime);
    formData.append('email', email);
    if (valueData !== null && valueData !== undefined && valueData !== '') {
      formData.append('video_length', valueData);
    }
    formData.append('video_type', '1');

    formData.append('minimum_price', minimumPrice || 0);
    // selectedLanguageIds.map((id: any) => {
    //   formData.append('audio_id[]', id);
    // });
    country.forEach((languageDetails) => {
      formData.append('audio_id[]', languageDetails.id);
    });
    formData.append('description', description);
    country.forEach((countryDetails) => {
      formData.append('country[]', countryDetails.id);
    });
    contentCat.forEach((contentCatDetails: any) => {
      formData.append('content_cat_id[]', contentCatDetails?.id);
    });
    formData.append('video_type', videoType);
    formData.append('minimum_short_price', shortsPrice || 0);
    const res = await dispatch(fetchAddChannelData(formData));

    if (res?.error) {
      ToastMessage({
        type: 'error',
        message: res.error.message,
      });
      dispatch(setLoading(false));
    } else {
      ToastMessage({
        type: 'success',
        message: res.payload.message,
      });
      dispatch(setaddChannelState(res));
      if (show) {
        navigate('/dashboard/social/youtube');
      } else {
        navigate('/setup-complete');
      }
      dispatch(setLoading(false));
    }
  };
  const handleContentcategory = (value: any) => {
    setContentCat(value);
  };

  const handleSubmit = async (e: any) => {
    setFormErrors({});
    let isValidate = true;
    if (formErrors.valueData === '' || formErrors.valueData === undefined) {
      isValidate = true;
    } else {
      isValidate = false;
    }
    if (isValidate) {
      e.preventDefault();
      schema
        .validate(
          {
            category,
            uploadTime,
            language: language?.map((l) => l.name),
            country: country?.map((c) => c.name),
            contentCat,
            videoType,
            valueData,
            addChannel,
            description,
            email,
            file,
          },
          { abortEarly: false }
        )
        .then(() => {
          ChannelAddAPICall();
        })
        .catch((err) => {
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });

          setFormErrors(errors);
        });
    }
  };

  const handleChangLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedLanguages = event?.target?.value as string[];
    setLanguage(selectedLanguages);
  };
  return (
    <Box>
      <Grid container sx={{ justifyContent: 'space-between', display: 'flex' }}>
        <Grid item sm={5.9} xs={12}>
          <Box>
            <AutoComplete
              placeholder='Select channel'
              title='Add Channel'
              data={channelSuggestionList?.map((option: any) => option?.name)}
              EndIcon={<Search />}
              value={addChannel}
              disableClearable
              onChange={(value: any) => {
                setAddChannel(value);
              }}
            />
            {formErrors.addChannel && <ErrorTextBox>{formErrors.addChannel}</ErrorTextBox>}
          </Box>
          <Box mt={'24px'}>
            <Typography variant='body2' sx={{ color: 'gray' }}>
              Video Type
            </Typography>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='all'
              name='radio-buttons-group'
              value={videoType}
              onChange={(event: any) => {
                setVideoType(event.target.value);
                if (event.target.value === '2') {
                  setIsVideoFieldDisplay(true);
                  setIsShortVideoDisplay(false);
                  setIsBothField(false);
                } else if (event.target.value === '3') {
                  setIsShortVideoDisplay(true);
                  setIsVideoFieldDisplay(false);
                  setIsBothField(false);
                } else if (event.target.value === '1') {
                  setIsBothField(true);
                  setIsShortVideoDisplay(false);
                  setIsVideoFieldDisplay(false);
                }
              }}
              sx={{
                '& .MuiRadio-root': {
                  color: colors.gray[500],
                },
                '& .Mui-checked': {
                  color: colors.basics.primary,
                  '& svg': {
                    fill: colors.basics.primary,
                  },
                },
                '& .MuiFormControlLabel-label': {
                  color: colors.gray[300],
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '21px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  backgroundColor: '#1D1D1D',
                  paddingY: '6px',
                  paddingX:'16px',
                  borderRadius: '8px',
                }}
              >
                <FormControlLabel value='1' control={<Radio />} label='Both' />
                <FormControlLabel value='2' control={<Radio />} label='Full Video' />
                <FormControlLabel value='3' control={<Radio />} label='Shorts Reels	' />
              </Box>
            </RadioGroup>
            <ErrorTextBox>{formErrors?.videoType}</ErrorTextBox>
          </Box>
          {isVideoFieldDisplay || bothFiled ? (
            <Box
              sx={{
                mt: '24px',
                '& .MuiOutlinedInput-input': {
                  pl: '20px',
                },
              }}
            >
              <TextInput
                title='Minimum Price for Video (Optional)'
                placeholder='Enter minimum price'
                value={minimumPrice}
                onChange={(e: any) => {
                  if (onlyNumbers(e?.target?.value) || e.target.value === '') {
                    if (e.target.value >= 1 || e.target.value === '') {
                      setMinimumPrice(Number(e?.target?.value));
                    }
                  }
                }}
                name='minimum_price'
                StartIcon={
                  <Box display={'flex'} alignItems={'center'}>
                    <Box pr={'20px'}>
                      <Doller />
                    </Box>
                    <Box>
                      <Line />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ) : (
            ''
          )}

          {isShortVideoDisplay || bothFiled ? (
            <Box
              sx={{
                mt: '24px',
                '& .MuiOutlinedInput-input': {
                  pl: '20px',
                },
              }}
            >
              <TextInput
                title='Minimum Price for Shorts (Optional)'
                placeholder='Enter minimum shorts price'
                value={shortsPrice === null || shortsPrice === 0 ? '' : shortsPrice}
                onChange={(e: any) => {
                  if (onlyNumbers(e?.target?.value) || e?.target?.value === '') {
                    if (e?.target?.value >= 1 || e?.target?.value === '') {
                      setShortsPrice(Number(e?.target?.value));
                    }
                  }
                }}
                name='shorts_minimum_price'
                StartIcon={
                  <Box display={'flex'} alignItems={'center'}>
                    <Box pr={'20px'}>
                      <Doller />
                    </Box>
                    <Box>
                      <Line />
                    </Box>
                  </Box>
                }
              />
            </Box>
          ) : (
            ''
          )}

          <Box mt={'24px'}>
            <TextInput
              title='Average video upload duration length (optional)'
              placeholder='05:50 min:sec'
              value={valueData}
              onKeyDown={(e: any) => {
                if (e.target.value.length === 2 && e.keyCode !== 8) {
                  if (e.target.value.indexOf(':') === -1) {
                    e.target.value = e.target.value + ':';
                  }
                  var str = e.target.value.split(':');
                  if (str[0].length === 1) {
                    e.target.value = '0' + str[0];
                  }
                }
              }}
              onChange={(e: any) => {
                let input = e.target.value;

                if (input !== '00:0e' && input.length <= 5) {
                  if (validateMinSec(input) || input === '') {
                    setValueData(input);
                    setFormErrors({
                      ...formErrors,
                      valueData: '',
                    });
                  } else {
                    setValueData(input);
                    setFormErrors({
                      ...formErrors,
                      valueData: 'Invalid time format',
                    });
                  }
                } else if (input === '00:0e') {
                  setValueData(input);
                  setFormErrors({
                    ...formErrors,
                    valueData: 'Value cannot be "00:0e"',
                  });
                } else if (input === '') {
                  setValueData(input);
                  setFormErrors({
                    ...formErrors,
                    valueData: '',
                  });
                }
              }}
              name='minimum_price'
            />
          </Box>
          <ErrorTextBox>{formErrors.valueData}</ErrorTextBox>
          <Box
            mt={'24px'}
            sx={{
              '&::placeholder': {
                color: '#d32f2f',
                textAlign: 'left',
                // fontSize: '12px',
                marginTop: '3px',
              },
            }}
          >
            <CountryAutoComplete
              // open={true}
              placeholder={`${country?.length ? '' : 'Select country'}`}
              title={'Target Audience Country'}
              options={countryData?.map((option: any) => option)}
              EndIcon={<Search />}
              disableClearable
              value={country}
              getOptionLabel={(option: any) => option?.name}
              onChange={(value) => handleChange(value)}
              renderTags={(value: any, getTagProps: any) =>
                value.map((option: any, index: any) => (
                  <Chip
                    key={option?.id}
                    label={option?.name}
                    {...getTagProps({ index })}
                    deleteIcon={<Close />}
                  />
                ))
              }
              renderOption={(props: any, option: any) => {
                return (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      '& .MuiAutocomplete-noOptions': {
                        color: colors.basics.white,
                      },
                      background: colors.dark[600],
                      '& .icons': {
                        width: '28px !important',
                        height: '28px !important',
                        borderRadius: '8px',
                      },
                    }}
                    {...props}
                  >
                    <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                      <ReactCountryFlag countryCode={option.code} svg className='icons' />
                    </Typography>
                    <Typography variant='body2'>{option.name} </Typography>
                  </Box>
                );
              }}
            />
            {formErrors.country && <ErrorTextBox>{formErrors.country}</ErrorTextBox>}
          </Box>
        </Grid>
        <Grid item sm={5.9} xs={12}>
          <Box>
            <SelectInput
              placeholder='Select Category'
              renderValue={(selected: any) => {
                if (selected === '') {
                  return (
                    <Typography variant='body2' sx={{ color: colors.gray[500] }}>
                      {''}
                    </Typography>
                  );
                }
                const selectedItem = categoryData.find((item: any) => item.id === selected);
                return selectedItem ? selectedItem.name : '';
              }}
              children={categoryData?.map((item: any) => {
                return (
                  <MenuItem key={item} value={item.id}>
                    <Typography variant='body2' lineHeight='24px'>
                      {item.name}
                    </Typography>
                  </MenuItem>
                );
              })}
              title='Channel Category'
              data={categoryData?.map((category: any) => category.name)}
              onChange={(e) => {
                const categoryId = e.target.value;
                setCategory(categoryId);
              }}
              value={category}
              defaultOpen={false}
            />
            {formErrors.category && <ErrorTextBox>{formErrors.category}</ErrorTextBox>}
          </Box>
          <Box
            mt={'24px'}
            sx={{
              '&::placeholder': {
                color: 'red',
              },
            }}
          >
            <ContentCategoryAutoComplete
              title={'Content Category'}
              options={contentCatData?.map((option: any) => option)}
              EndIcon={<Search />}
              disableClearable
              value={contentCat}
              getOptionLabel={(option: any) => option.name}
              onChange={(value) => handleContentcategory(value)}
              renderTags={(value: any, getTagProps: any) =>
                value.map((option: any, index: any) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                    deleteIcon={<Close />}
                  />
                ))
              }
              renderOption={(props: any, option: any) => {
                return (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      '& .MuiAutocomplete-noOptions': {
                        color: colors.basics.white,
                      },
                      background: colors.dark[600],
                    }}
                    {...props}
                  >
                    <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}></Typography>
                    <Typography variant='body2'>{option.name} </Typography>
                  </Box>
                );
              }}
            />
            <ErrorTextBox>{formErrors?.contentCat}</ErrorTextBox>
          </Box>
          <Box
            sx={{
              mt: '24px',
              '& .MuiTooltip-tooltip ': {
                backgroundColor: 'red !important',
                color: 'pink',
              },
              '& .MuiInputAdornment-root': {
                p: '10px',
              },
            }}
          >
            <TextInput
              title='Contact Email'
              name='email'
              placeholder='Enter your email address'
              onChange={(e) => setEmail(e?.target?.value)}
              EndIcon={
                <Tooltip
                  className='custom-tooltip'
                  sx={{ background: 'red' }}
                  placement='bottom-start'
                  title='Add an Email associated with your YouTube channel account.'
                  arrow
                >
                  <InfoEmail />
                </Tooltip>
              }
            />
            {formErrors.email && <ErrorTextBox>{formErrors.email}</ErrorTextBox>}
          </Box>
          <Box sx={{ mt: '24px' }}>
            <SelectInput
              placeholder='Video upload duration time'
              title='Video upload duration time'
              renderValue={(selected: any) => {
                if (selected === '') {
                  return (
                    <Typography variant='body2' sx={{ color: colors.gray[500] }}>
                      {''}
                    </Typography>
                  );
                }
                const selectedItem = durationTime.find((item: any) => item.id === selected);
                return selectedItem ? selectedItem?.time : '';
              }}
              data={durationTime}
              onChange={(e) => {
                const uploadTimeId = e?.target?.value;
                setUploadTime(uploadTimeId);
              }}
              value={uploadTime}
              defaultOpen={false}
              children={durationTime?.map((option: any, index: any) => {
                return (
                  <MenuItem
                    value={option?.id}
                    key={option?.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                      {option?.time}
                    </Typography>
                  </MenuItem>
                );
              })}
            />
            {formErrors.uploadTime && <ErrorTextBox>{formErrors.uploadTime}</ErrorTextBox>}
          </Box>
          <Box mt={'24px'}>
            {/* <SelectInput
              multiple={true}
              title='Video audio language'
              placeholder={'Select language'}
              renderValue={(selected: string[]) => {
                if (selected.length === 0) {
                  return (
                    <Typography variant='body2' sx={{ color: 'gray' }}>
                      Select language
                    </Typography>
                  );
                }
                return selected.join(', ');
              }}
              value={language}
              onChange={handleChangLanguage}
              children={?.map((option: any, index: any) => {
                return (
                  <MenuItem
                    value={option.name}
                    key={option.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                      {option.name}
                    </Typography>
                    <Typography variant='body2'>{option.CountryName}</Typography>
                  </MenuItem>
                );
              })}
            /> */}
            <CountryAutoComplete
              // open={true}
              title={'Video audio language'}
              placeholder={`${language?.length ? '' : 'Select language'}`}
              options={audioData?.map((option: any) => option)}
              EndIcon={<Search />}
              disableClearable
              value={language}
              getOptionLabel={(option: any) => option?.name}
              onChange={(value) => handleLanguageChange(value)}
              renderTags={(value: any, getTagProps: any) =>
                value.map((option: any, index: any) => (
                  <Chip
                    key={option?.id}
                    label={option?.name}
                    {...getTagProps({ index })}
                    deleteIcon={<Close />}
                  />
                ))
              }
              renderOption={(props: any, option: any) => {
                return (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      '& .MuiAutocomplete-noOptions': {
                        color: colors.basics.white,
                      },
                      background: colors.dark[600],
                      '& .icons': {
                        width: '28px !important',
                        height: '28px !important',
                        borderRadius: '8px',
                      },
                    }}
                    {...props}
                  >
                    <Typography mr='14px' variant='body2' alignItems={'center'} display={'flex'}>
                      <ReactCountryFlag countryCode={option.code} svg className='icons' />
                    </Typography>
                    <Typography variant='body2'>{option.name} </Typography>
                  </Box>
                );
              }}
            />
            {formErrors.language && <ErrorTextBox>{formErrors.language}</ErrorTextBox>}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={'24px'}>
            <Typography
              sx={{ color: colors.gray[300] }}
              variant='body2'
              lineHeight='140%'
              mb='6px'
              textAlign='left'
              textTransform='capitalize'
            >
              Description
            </Typography>
            <Box
              sx={{
                '& .ck-editor ': {
                  backgroundColor: colors.dark[600],
                  padding: '20px',
                  borderRadius: '10px',
                },
                '.ck.ck-editor__main>.ck-editor__editable': {
                  backgroundColor: 'transparent',
                  border: 0,
                },
                '& .ck-editor__top ': {
                  display: 'none',
                },
                '& .ck-content p ': {
                  marginTop: '0 !important',
                  marginBottom: '15px !important',
                },
                '& .ck-content p a': {
                  color: '#A175FF !important',
                  textDecoration: 'none',
                },
                '& .ck-content p:last-child ': {
                  marginBottom: '0 !important',
                },
              }}
            >
              <CkEditoComponent data={description} setData={setDescription} />
            </Box>
            {formErrors.description && <ErrorTextBox>{formErrors.description}</ErrorTextBox>}
          </Box>
          <Box mt={'24px'}>
            <Box mb={'10px'}>
              <Typography variant='body2' lineHeight={'140%'} color={colors.gray[300]}>
                Upload Thumbnail
              </Typography>
            </Box>
            <Dropzone
              accept={{ 'image/png': ['.png', '.jpeg', '.jpg'] }}
              onDrop={handleDrop}
              // accept={['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/*']}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'left',
                    padding: file ? '0px' : '25px',
                    border: file ? 'none' : `1px dashed ${colors.dark[100]}`,
                    borderRadius: '10px',
                    cursor: 'pointer',
                    position: 'relative',
                    width: 'fit-content',
                    overflow: 'hidden',
                  }}
                >
                  <input {...getInputProps()} accept='image/*' />
                  {file ? (
                    <Box
                      sx={{
                        position: 'relative',
                        textAlign: 'left',
                        justifyContent: 'left',
                        display: 'flex',
                        width: 'fit-content',
                      }}
                    >
                      <Box sx={{ maxWidth: '100%', maxHeight: '200px', position: 'relative' }}>
                        <img
                          src={file ? URL?.createObjectURL(file) : file}
                          alt='Preview'
                          style={{ maxWidth: '166px', maxHeight: '166px', borderRadius: '8px' }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            background: '#1d1d1d99',
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            p: '8px 8px 8px 8px',
                            textAlign: 'left',
                            justifyContent: 'left',
                            display: 'flex',
                          }}
                          onClick={handleClear}
                        >
                          <EditThumbnail />
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box textAlign={'center'}>
                      <Box>
                        <UploadIcon />
                      </Box>
                      <Box>
                        <Typography variant='body2' lineHeight={'24px'}>
                          Drag and drop or browse
                        </Typography>
                        <Typography
                          variant='body2'
                          fontSize={'14px'}
                          lineHeight={'140%'}
                          color={colors.gray[300]}
                        >
                          Image size should be 1080px x 1080px (Max. File size: 5MB)
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Dropzone>
            {formErrors.file && <ErrorTextBox>{formErrors.file}</ErrorTextBox>}
          </Box>
          <Box
            alignItems='center'
            justifyContent={show ? 'left' : 'center'}
            display={'flex'}
            mt={'20px'}
            mb={'10px'}
            // onClick={() => navigate('/dashboard/social/youtube')}
          >
            <Button type='submit' onClick={handleSubmit} sx={{ width: { sm: '180px', xs: '100%' } }}>
              {show ? 'Add' : 'Save'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
