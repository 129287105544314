import { Box, Button, Dialog, Typography } from '@mui/material';
import { colors } from '../../styles/color';
import { useDispatch } from 'react-redux';
import { setDialogState, setLoading } from '../../redux/features';
import { ToastMessage } from '../../helpers/ToastMessage';
import { setProfileState } from '../../redux/features/profile/profileSlice';
import { API } from '../../api/services';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setAppState } from '../../redux/features/appStateSlice';
import { cleanChannelListState } from '../../redux/features/channelList/channelListSlice';
import { cleanChannelDetailsState } from '../../redux/features/channelDetails/channelDetailsSlice';

const LogOutModel = () => {
  const dispatch = useDispatch();
  const [token, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setAppState('logout'));
  }, []);
  const cancel = () => {
    dispatch(
      setDialogState({
        loginDialog: false,
        registerDialog: false,
        modal: false,
        otpDialog: { otpDialog: false, fromPageName: '' },
        privacyDialog: false,
        resetPassword: false,
        successReset: false,
        termsDialog: false,
        forgotPassword: false,
        logout: false,
      })
    );
  };

  const logout = async () => {
    dispatch(setLoading(true));
    try {
      const response: any = await API.get(`/logout`);
      if (response.data.status) {
        removeToken('accessToken', { path: '/' });
        removeToken('refreshToken', { path: '/' });
        removeToken('googleToken', { path: '/' });
        removeToken('role', { path: '/' });
        // document.cookie.split(";").forEach((c) => {
        //   document.cookie = c
        //     .replace(/^ +/, "")
        //     .replace(
        //       /=.*/,
        //       "=;expires=" + new Date().toUTCString() + ";path=/"
        //     );
        // });
        localStorage.removeItem('login');
        dispatch(
          setDialogState({
            loginDialog: false,
            registerDialog: false,
            modal: false,
            otpDialog: { otpDialog: false, fromPageName: '' },
            privacyDialog: false,
            resetPassword: false,
            successReset: false,
            termsDialog: false,
            forgotPassword: false,
            logout: false,
          })
        );
        dispatch(setProfileState({}));
        ToastMessage({
          type: 'success',
          message: response.data.message,
        });
        // window.location.href = "/";
        setTimeout(() => {
          dispatch(setLoading(false));
          cancel();
          if (token.role === '2') {
            navigate('/sign-in');
          } else {
            navigate('/');
          }
        }, 200);
      } else {
        ToastMessage({
          type: 'error',
          message: response.data.message,
        });
      }
    } catch (error: any) {
      // ToastMessage({
      //   type: "error",
      //   message: error.message,
      // });
      if (error.status === false) {
        removeToken('accessToken', { path: '/' });
        removeToken('refreshToken', { path: '/' });
        removeToken('googleToken', { path: '/' });
        removeToken('role', { path: '/' });
        // document.cookie.split(";").forEach((c) => {
        //   document.cookie = c
        //     .replace(/^ +/, "")
        //     .replace(
        //       /=.*/,
        //       "=;expires=" + new Date().toUTCString() + ";path=/"
        //     );
        // });
        if (token.role === '2') {
          navigate('/sign-in');
        } else {
          navigate('/');
        }
        localStorage.removeItem('login');
        dispatch(
          setDialogState({
            loginDialog: false,
            registerDialog: false,
            modal: false,
            otpDialog: { otpDialog: false, fromPageName: '' },
            privacyDialog: false,
            resetPassword: false,
            successReset: false,
            termsDialog: false,
            forgotPassword: false,
            logout: false,
          })
        );
        dispatch(setProfileState({}));
      }
      dispatch(setLoading(false));
    }
    dispatch(setLoading(false));
    await dispatch(cleanChannelListState());
    await dispatch(cleanChannelDetailsState());
  };

  return (
    <Box>
      <Dialog
        sx={{
          '&  .MuiDialog-paper': {
            background: colors.dark[600],
            borderRadius: '24px',
            padding: '42px',
          },
        }}
        open={true}
        maxWidth={'xl'}
      >
        <Box>
          <Typography variant="body1" fontWeight={600}>
            Are you sure you want to logout ?
          </Typography>
        </Box>
        <Box display={'flex'} width={'100%'} mt={'40px'}>
          <Button
            onClick={() => {
              cancel();
            }}
            sx={{
              width: '50%',
              marginRight: '10px',
              color: colors.basics.primary,
              border: `1px solid ${colors.basics.primary}`,
              background: 'transparent',
              '&:hover': {
                background: 'transparent',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              logout();
            }}
            sx={{
              width: '50%',
              background: colors.basics.danger,
              '&:hover': {
                background: colors.basics.danger,
              },
            }}
          >
            Yes
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default LogOutModel;
