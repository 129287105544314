import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchProfileDetails: any = createAsyncThunk('profileDetails/fetchProfileDetails', async () => {
  const response = await API.get(`/profile`)
    .then((res: any) => {
      return res?.data?.data;
    })
    .catch((error: any) => {
      return error;
    });
  return response;
});

const initialState = {
  profileData: {},
  status: '',
  loading: false,
  isLoggedIn: false,
};

const profileSlice = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {
    setProfileState: (state, { payload }) => {
      state.profileData = payload;
    },
  },
});

export const { setProfileState } = profileSlice.actions;
export default profileSlice.reducer;
