import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchChannelListDetails: any = createAsyncThunk(
  'channelListDetails/fetChchannelListDetails',
  async () => {
    const response = await API.get(`/channel-list`);
    return response?.data;
  }
);

const initialState = {
  channelList: {},
  status: '',
};

const channelListSlice = createSlice({
  name: 'channelListDetails',
  initialState,
  reducers: {
    setChannelListState: (state, { payload }) => {
      state.channelList = payload;
    },
    cleanChannelListState: (state) => {
      state.channelList = {};
      state.status = '';
    }
  },
});

export const { setChannelListState, cleanChannelListState } = channelListSlice.actions;
export default channelListSlice.reducer;
