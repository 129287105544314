import { Box } from '@mui/system';
import { colors } from '../styles/color';
import { ReactComponent as Logo } from '../assets/logo/logo.svg';
import { AppBar, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <>
      <AppBar
        sx={{
          background: colors.dark[800],
          boxShadow: '0px 4px 60px rgba(52, 52, 52, 0.5)',
          height: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 999,
        }}
      >
        <Container
          sx={{
            maxWidth: '1200px !important',
            width: '100%',
            height: '90px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              color: colors.basics.theme,
              justifyContent: 'space-between',
              alignItems: 'center',

              p: '26px 0',
              '& svg': {
                width: '136px',
                height: '36px',
                alignItems: 'center',
              },
            }}
          >
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <Logo />
            </Box>
          </Box>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
