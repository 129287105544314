import { Box } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Modal from '../../../components/core/modal';
import {
  LoginModal,
  RegisterModal,
  PrivacyModal,
  // ForgotPassword,
  ResponsiveAppBar,
  Footer,
  // ResetPassword,
  OtpModal,
} from '../../../components/Business';
import { useAppSelector } from '../../../hooks/useAppReducer';
import { ApplicationStateType } from '../../../redux/store';
import TermsAndConditions from '../../TermsAndConditions';
import InquiryModal from '../../../components/InquiryModal/InquiryModal';
import { ForgotPassword } from '../../ForgotPassword';
import NewPassword from '../../NewPassword';
import BusinessResetPassword from '../../../components/Business/BusinessResetPassword';
import LogOutModel from '../../logoutModel/LogoutModel';

const BusinessDashboard = (props: any) => {
  const {
    dialog: {
      loginDialog,
      registerDialog,
      forgotPassword,
      resetPassword,
      otpDialog: { otpDialog },
      privacyDialog,
      termsDialog,
      sendInquiry,
      newPassword,
      logout
    },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);


  return (
    <>
      <Box sx={{ background: '#0D0D0D' }}>
        <ResponsiveAppBar />
        <Outlet />
        {/* <BusinessHome /> */}
        {props.children}
        <Box sx={{ backgroundColor: '#141414' }}>
          <Footer />
        </Box>
      </Box>
      <Modal>
        <>
          {registerDialog && <RegisterModal />}
          {loginDialog && <LoginModal />}
          {termsDialog === true && <TermsAndConditions />}
          {privacyDialog === true && <PrivacyModal />}
          {otpDialog === true && <OtpModal />}
          {resetPassword === true && <BusinessResetPassword />}
          {forgotPassword === true && <ForgotPassword />}
          {newPassword === true && <NewPassword />}
          {sendInquiry === true && <InquiryModal />}
          {logout === true && <LogOutModel />}
        </>
      </Modal>
    </>
  );
};

export default BusinessDashboard;
