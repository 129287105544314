const Image = (props: any) => {
    return (
        <img
            height={props.height}
            width={props.width}
            alt={props.alt}
            src={props.src}
            className={props.className}
            style={props.style}
            onClick={(e) => {
                if (props.onClick !== undefined) {
                    props.onClick(e)
                } else {
                    e.preventDefault()
                }
            }
            }
        />
    )
}

export default Image