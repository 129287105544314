import {
  Box,
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles/color';
import TextInput from '../../components/TextInput';
import Topbar from '../../components/common/Topbar';
import { setDrawerState } from '../../redux/features/drawer/drawerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FilterDrawer } from './FilterDrawer';
import { FilterData } from '../../components/FilterData';
import { FilterIcon, SearchIcon } from '../../assets/icons';
import { setLoading } from '../../redux/features';
import { fetchInquiry, setInquiry } from '../../redux/features/getInquiry/getInquiry';
import { useAppSelector } from '../../hooks/useAppReducer';
import { ApplicationStateType } from '../../redux/store';
import { ToastMessage } from '../../helpers/ToastMessage';
import { DateObject } from 'react-multi-date-picker';
import dayjs from 'dayjs';
import NoInquiry from '../../assets/images/NoInquiry.png';
import Image from '../../components/common/Image';
import NoDataFound from '../../components/common/NoDataFound';
import { formatDate } from '../../utils/commanFunctions/commanFunctions';

const headerData = ['Inquiry No.', 'Channel Name', 'User Name', 'Date', 'Time', 'Subject'];

const InquiryPage = () => {
  const { getInquiry } = useAppSelector((state: ApplicationStateType) => state.getInquiry);
  const { loading } = useAppSelector((state: ApplicationStateType) => state.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [values, setValues] = useState<any>([
    new DateObject().add(-7, 'days'),
    new DateObject().add(0, 'days'),
  ]);

  const [searchText, setSearchText] = useState('');
  let [timer, setTimer] = useState<any>(null);
  const [value, setValue] = useState('0');
  const [open, setOpen] = useState(false);

  const [direction, setDirection] = useState('');

  const drawerState = useSelector((state: any) => state.drawer.drawerState);

  const startDate = dayjs(values[0]).format('MMM DD , YYYY');
  const endDate = dayjs(values[1]).format('MMM DD , YYYY');
  const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const getInquiryApi = useCallback(
    async (page: number, search: string, filter: number, start_date: string, end_date: string) => {
      dispatch(setLoading(true));
      let obj = {};
      if (filter === 7) {
        obj = {
          data: {
            filter: filter,
            search: search,
            start_date: dayjs(start_date).format('DD-MM-YYYY'),
            end_date: dayjs(end_date).format('DD-MM-YYYY'),
          },
          page: page,
        };
      } else {
        obj = {
          data: {
            filter: filter,
            search: search,
          },
          page: page,
        };
      }
      setPage(page);
      const res = await dispatch(fetchInquiry(obj));
      const data = res?.payload?.data;
      if (data !== undefined) {
        dispatch(setInquiry(data));
      } else {
        dispatch(setInquiry([]));
      }

      if (res.error) {
        ToastMessage({
          type: 'error',
          message: res.error.message,
        });
        dispatch(setLoading(false));
      }
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 1000);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setInquiry({}));
    document.body.style.overflow = 'hidden';
    dispatch(setLoading(true));
    getInquiryApi(1, '', 0, '', '');
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [getInquiryApi, dispatch]);

  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: true }));
  }, [direction]);

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  const filterRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setValue(value);
  };

  const customApplyClick = (startDate: any, endDate: any) => {
    if (startDate === undefined) {
      getInquiryApi(
        1,
        searchText,
        0,
        startDate === undefined ? '' : startDate,
        endDate === undefined ? '' : endDate
      );
      setValue('0');
    } else {
      getInquiryApi(
        1,
        searchText,
        Number(value),
        startDate === undefined ? '' : startDate,
        endDate === undefined ? '' : endDate
      );
    }
    dispatch(setDrawerState({ ...drawerState, right: false }));
    setOpen(false);
    setVisible(false);
  };

  const handleChangeSearch = async (e: any) => {
    clearTimeout(timer);
    setSearchText(e.target.value);
    const newTimer = setTimeout(() => {
      getInquiryApi(1, e.target.value, Number(value), startDate, endDate);
    }, 800);
    setTimer(newTimer);
  };

  const handleChange = (event: any, handleValue: any) => {
    window.scroll(0, 0);
    setPage(handleValue);
    getInquiryApi(
      handleValue,
      searchText,
      Number(value),
      startDate === undefined ? '' : startDate,
      endDate === undefined ? '' : endDate
    );
  };

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      <Topbar>
        <Box display={'flex'} justifyContent={'space-between'} width='100%'>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            // width='100%'
            alignItems={'center'}
            sx={{
              '& .MuiToolbar-root': {
                minHeight: '0px',
              },
            }}
          >
            <Box sx={{ pl: { xl: 0, xs: '20px' } }}>
              <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                Inquiry
              </Typography>
            </Box>
          </Box>
          <Box display='flex' width='50%' justifyContent={'flex-end'}>
            <Box
              sx={{
                '& .MuiInputBase-input':{
                  textOverflow:"ellipsis ",
                  maxWidth:{sm:"400px !important",xs:"150px !important"},
                },
                '& .MuiFormControl-root': {
                  background: colors.dark[600],
                },
                '& svg': {
                  mr: '10px',
                  display: { xs: 'none', sm: 'flex' },
                },
                maxWidth: '325px',
                width: '100%',
              }}
            >
              <TextInput
                placeholder='Search'
                StartIcon={<SearchIcon />}
                value={searchText}
                onChange={handleChangeSearch}
              />
            </Box>
            {/* {Object.keys(getInquiry).length > 0 && getInquiry?.data?.length > 0 ? ( */}
              <Box sx={{ ml: '20px', display: { xl: 'flex', xs: 'none' } }}>
                <Button
                  onClick={() => setVisible(true)}
                  variant='text'
                  sx={{
                    background: colors.dark[600],
                    p: '26px 15px',
                    borderRadius: '10px',
                    '&:hover': {
                      background: colors.dark[800],
                    },
                  }}
                >
                  <FilterIcon />
                </Button>
              </Box>
            {/* ) : (
              ''
            )} */}

            {/* {Object.keys(getInquiry).length > 0 && getInquiry?.data?.length > 0 ? ( */}
              <Box sx={{ ml: '0', display: { xl: 'none', xs: 'flex' } }}>
                <Button
                  onClick={() => setDirection('right')}
                  variant='text'
                  sx={{
                    background: colors.dark[800],
                    p: '26px 15px',
                    borderRadius: '10px',
                    '&:hover': {
                      background: colors.dark[800],
                    },
                  }}
                >
                  <FilterIcon />
                </Button>
              </Box>
            {/* ) : ( */}
              {/* '' */}
            {/* )} */}
          </Box>
        </Box>
      </Topbar>
      <Box p='0 16px 0 16px' mt={'16px'}>
        <Box
          sx={{
            width: '100%',
            display: visible ? 'flex' : 'block',
            mt: '20px',
          }}
        >
          <Box
            minWidth='calc(100% - 330px)'
            sx={{
              '& .MuiTableContainer-root': {
                borderTopRightRadius: '12px',
                borderRadius: ' 12px 12px 0px 0px',
              },
              '& .MuiPaper-root': {
                background: 'none',
                boxShadow: 'none',
              },
              '& .MuiTable-root': {
                background: colors.dark[800],
              },
              '& .MuiTableCell-root': {
                background: colors.dark[800],
                color: colors.basics.white,
                borderBottom: `1px solid ${colors.gray[500]}`,
                p: { md: '25px 50px', xs: '15px 25px' },
              },
              '& .MuiTableCell-body': {
                borderBottom: `1px solid ${colors.dark[100]}`,
                color: colors.gray[300],
              },
            }}
          >
            <Paper
              sx={{
                width: '100%',
                overflowY: 'hidden',
              }}
            >
              {Object.keys(getInquiry).length > 0 && getInquiry?.data?.length > 0 ? (
                <>
                  <TableContainer
                    sx={{
                      height: 'calc(100vh - 220px)',
                      '&::-webkit-scrollbar': {
                        width: 10,
                      },
                    }}
                  >
                    <Table stickyHeader={true} aria-label='sticky table' sx={{ overflowX: 'auto' }}>
                      <TableHead>
                        <TableRow>
                          {headerData.map((item, index) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '18px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {item}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getInquiry?.data?.map((item: any, index: any) => {
                          return (
                            <TableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={index}
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => navigate(`/dashboard/inquiry/${item.id}`)}
                            >
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                }}
                              >
                                {' '}
                                {item.inquiry_id}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {item?.channel?.channel_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {item?.sender_profile?.name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {formatDate(item.date)}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {item.time}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {' '}
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: { sm: '400px', xs: '200px' },

                                    '&::-webkit': {
                                      lineClamp: 2,
                                      boxOrient: 'vertical',
                                    },
                                  }}
                                >
                                  {item.subject}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{
                      '& .MuiPagination-ul': {
                        background: colors.dark[800],
                        p: { md: '25px', xs: '15px' },
                        justifyContent: 'center',
                      },
                      '& .MuiPaginationItem-root': {
                        color: colors.gray[300],
                        '& svg': {
                          background: colors.dark[100],
                          border: `0.5px solid ${colors.gray[500]}`,
                          p: '4px',
                          borderRadius: '4px',
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.basics.primary,
                      },
                    }}
                  >
                    <Pagination
                      count={Math.ceil(getInquiry?.total / pageSize)}
                      page={page}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              ) : !loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 130px)',
                  }}
                >
                  <NoDataFound svg={<Image src={NoInquiry} />} text={'No inquiries found'} />
                </Box>
              ) : (
                <></>
              )}
            </Paper>
          </Box>
          {visible && (
            <FilterData
              startDate={startDate}
              endDate={endDate}
              weekDays={weekDays}
              values={values}
              open={open}
              setOpen={setOpen}
              setValues={setValues}
              customApplyClick={(startDate: any, endDate: any) => customApplyClick(startDate, endDate)}
              filterRadioButton={(e: React.ChangeEvent<HTMLInputElement>) => filterRadioButton(e)}
              visible={visible}
              setVisible={setVisible}
              value={value}
            />
          )}
        </Box>
      </Box>
      <Box>
        <FilterDrawer
          startDate={startDate}
          endDate={endDate}
          weekDays={weekDays}
          values={values}
          open={open}
          setOpen={setOpen}
          setValues={setValues}
          customApplyClick={(startDate: any, endDate: any) => customApplyClick(startDate, endDate)}
          filterRadioButton={(e: React.ChangeEvent<HTMLInputElement>) => filterRadioButton(e)}
          visible={visible}
          setVisible={setVisible}
          value={value}
        />
      </Box>
    </Box>
  );
};

export default InquiryPage;
