import React from 'react';
import { Container } from '@mui/material';

interface customContainerProps {
  children: JSX.Element;
  style?: React.CSSProperties;
  className?: string;
  maxWidth? : string
}

export const CustomContainer = ({ children, style, className , maxWidth  }: customContainerProps) => {
  return (
    <Container
      sx={{
        maxWidth: maxWidth !== undefined ? maxWidth :'1200px !important',
        width: '100%',
        p: {sm:'20px 0',xs:'10px 0'},

        '&.MuiContainer-root': {
          pl: { xs: '15px' },
          pr: { xs: '15px' },
        },
      }}
    >
      {children}
    </Container>
  );
};
