import { TextareaAutosize, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { colors } from '../styles/color';
interface textAreaProps {
  title?: string;
  onChange?: (e: any) => void;
  value?: any;
  placeholder?: string;
  name?: any;
  background?: any
}

export const TextAreaInput = (props: any) => {
  const { placeholder, value, onChange, title, name, background } = props;

  return (
    <Box
      sx={{
        '& textarea': {
          background: background === undefined ? colors.dark[600] : background,
          width: 'calc(100% - 40px)',
          border: 'none',
          height: '100%',
          p: '20px',
          borderRadius: '10px',
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: '16px',
          color: colors.basics.white,
          lineHeight: '180%',
          '&::placeholder': {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontStyle: 'normal',
            // fontSize: '16px',
            color: colors.gray[500],
            lineHeight: '24px',
          },
          '&:focus': { outline: 'none' },
        },
      }}
    >
      {title && (
        <Typography
          sx={{ color: colors.gray[300] }}
          variant='body2'
          lineHeight='140%'
          mb='8px'
          textTransform='capitalize'
          textAlign='left'
        >
          {title}
        </Typography>
      )}
      <TextareaAutosize

        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        maxRows={3}
        minRows={3}
        style={{ paddingLeft: '20px', resize: "none" }}
      />
    </Box>
  );
};
