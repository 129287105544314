import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API } from '../../../api/services';

export const fetchChannelDetails: any = createAsyncThunk('channelDetails/fetChchannelDetails', async (payload) => {
    const response = await API.post(`/get-channel-details`, payload);
    return response?.data;
});

interface channelDetail {
    singleChannelDetails: Record<any, any>
}

const initialState: channelDetail = {
    singleChannelDetails: {}
};

const getSingleChannelDetails = createSlice({
    name: 'channel-details',
    initialState,
    reducers: {
        setChannelDetailsState: (state, { payload }) => {
            state.singleChannelDetails = payload;
        },
    },
});
export const { setChannelDetailsState } = getSingleChannelDetails.actions;
export default getSingleChannelDetails.reducer;