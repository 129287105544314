import { Box } from "@mui/material"
import { CustomContainer } from "../CustomContainer"
import { colors } from "../../styles/color"
import ResetPassword from "../common/ResetPassword"

const BusinessResetPassword = () => {
    return (
        <Box>
            <CustomContainer>
                <Box
                    sx={{
                        // minHeight: "770px",
                        height: '100%',
                        background: colors.dark[800],
                        borderRadius: '24px',
                        color: 'white',
                        p: { sm: '39px 35px', xs: '20px 10px' },
                        display: 'block',
                    }}
                >
                    <ResetPassword />
                </Box>
            </CustomContainer>
        </Box>
    )
}

export default BusinessResetPassword