import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerState: {
    left: false,
    right: false,
  },
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerState: (state, { payload }) => {
      state.drawerState = payload;
    },
  },
});

export const { setDrawerState } = drawerSlice.actions;
export default drawerSlice.reducer;
