import { Box } from "@mui/material"
import { Close } from "../../assets/icons"
import DoneIcon from '@mui/icons-material/Done';
import { colors } from "../../styles/color";
import ErrorTextBox from "./ErrorTextBox";
import TextInput from "../TextInput";

const AddLink = (props: any) => {
    const { value, onChange, onSuccess, onClose, error } = props
    return (
        <Box sx={{ display: "flex", background: colors.basics.black, padding: "15px" }}>
            <Box>
                <TextInput
                    placeholder="eg: https://abc.com"
                    onChange={(e) => { onChange(e) }}
                    value={value}
                />
                <ErrorTextBox>{error}</ErrorTextBox>
            </Box>

            <DoneIcon className="cursor-pointer" onClick={() => onSuccess()} sx={{ color: "green", margin: "auto 5px", width: "30px !important" }} />
            <Close className="cursor-pointer" onClick={() => onClose()} style={{ color: " red", margin: "auto 5px", width: "14px" }} />
        </Box>

    )
}

export default AddLink