import React, { useEffect, useState } from 'react';
import DrawerRoot from './Drawer';
import { Button, Drawer, IconButton, List, Stack, Toolbar, Box } from '@mui/material';
import Logo from '../assets/images/QuickFluenceLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import appRoutes from '../routes/appRoutes';
import sizeConfigs from '../styles/sizeConfigs';
import SidebarItemCollapse from './common/SidebarItemCollapse';
import SidebarItem from './common/SidebarItem';
import { setDrawerState } from '../redux/features/drawer/drawerSlice';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../styles/color';
import { useCookies } from 'react-cookie';
import { LogOut } from '../assets/icons';
import { setDialogState } from '../redux/features';
import { useNavigate } from 'react-router-dom';
const LeftDrawer = () => {
  const [direction, setDirection] = useState('');
const navigate = useNavigate()
  const dispatch = useDispatch();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  const [_, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);

  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  return (
    <DrawerRoot direction='left'>
      <Drawer
        variant='permanent'
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          display: { xs: 'flex' },
          '& .MuiDrawer-paper': {
            width: sizeConfigs.sidebar.width,
            boxSizing: 'border-box',
            borderRight: '0px',
            backgroundColor: colors.dark[800],
            color: colors.basics.primary,
          },
        }}
      >
        <List disablePadding>
          <Box width={'100%'}>
            <IconButton
              sx={{ width: '100%', justifyContent: 'flex-end' }}
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={() => dispatch(setDrawerState({ ...drawerState, left: false }))}
              color='inherit'
              disableRipple={true}
            >
              {drawerState?.left && <CloseIcon />}
            </IconButton>
          </Box>
          <Box onClick={() => navigate('/dashboard')} sx={{cursor:"pointer"}}>
          <Toolbar sx={{ marginBottom: '20px' }}>
            <Stack sx={{ width: '100%' }} direction='row' justifyContent='center' display={'flex'}>
              <img
                src={Logo}
                width={'166px'}
                height={'35px'}
                alt={'logo'}
                onClick={() => dispatch(setDrawerState({ ...drawerState, left: false }))}
              />{' '}
            </Stack>
          </Toolbar>
          </Box>

          {appRoutes.map((route: any, index: any) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
        <Box
          sx={{
            position: 'absolute',
            display: 'inline-block',
            bottom: 10,
            left: '15px',
            width: ' 100%',
            ml: '10px',
          }}
        >
          {' '}
          <Button
            onClick={() => {
              dispatch(
                setDialogState({
                  loginDialog: false,
                  registerDialog: false,
                  modal: true,
                  otpDialog: { otpDialog: false, fromPageName: '' },
                  privacyDialog: false,
                  resetPassword: false,
                  successReset: false,
                  termsDialog: false,
                  forgotPassword: false,
                  logout: true,
                })
              );
              dispatch(setDrawerState({ ...drawerState, left: false }));
            }}
            variant='text'
            startIcon={<LogOut />}
            sx={{ color: colors.gray[300] }}
          >
            LogOut
          </Button>
        </Box>
      </Drawer>
    </DrawerRoot>
  );
};

export default LeftDrawer;
