import React, { useState } from 'react';
import { Box, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { colors } from '../styles/color';
import { ArrowDownGray, ArrowUpWhite } from '../assets/icons';

interface multiSelectProps {
  title?: string;
  data?: string[] | any;
  onChange?: (e: any) => void;
  value?: any;
  multiple?: boolean;
  style?: React.CSSProperties;
  StartIcon?: JSX.Element;
  checkbox?: boolean;
  placeholder?: string;
  children?: any;
  Ref?: any;
  error?: boolean;
  renderValue?: any;
  input?: any;
  defaultOpen?: boolean;
}

export const SelectInput = (props: multiSelectProps) => {
  //! Props
  const {
    title,
    data,
    onChange,
    value,
    multiple,
    style,
    StartIcon,
    children,
    error,
    placeholder,
    renderValue,
    input,
    defaultOpen,
  } = props;

  //! Hooks
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box>
      {title && (
        <Typography
          sx={{ color: colors.gray[300] }}
          variant='body2'
          lineHeight='140%'
          mb='8px'
          pl='2px'
          textTransform='capitalize'
        >
          {title}
        </Typography>
      )}
      <Select
        multiple={multiple || false}
        value={value}
        onChange={(e) => onChange && onChange(e)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        error={error}
        displayEmpty
        input={input}
        placeholder={placeholder}
        defaultOpen={defaultOpen}
        startAdornment={
          StartIcon ? (
            <InputAdornment position='start' sx={{ marginRight: { sm: '10px', xs: '5px' } }}>
              <Box display='flex' className="start-icon">{StartIcon}</Box>
            </InputAdornment>
          ) : (
            <></>
          )
        }
        renderValue={(selected) => {
          if (selected === '' || selected == undefined) {
            return (
              <Typography variant='body2' sx={{ color: '#7D7D7D99' }}>
                {placeholder}
              </Typography>
            );
          }
          return renderValue ? renderValue(selected) : value;
        }}
        IconComponent={(props) => {
          return (
            <Box display='flex'
              sx={{
                position: "absolute",
                right: "8px"
              }}
              className="icon-box">
              {props.className.includes('MuiSelect-iconOpen') ? <ArrowUpWhite /> : <ArrowDownGray />}
            </Box>
          );
        }}
        sx={{
          paddingLeft: { sm: '17px', xs: '7px' },
          paddingRight: '10px',
          background: colors.dark[600],
          color: colors.basics.white,
          display: 'flex',
          maxHeight: '52px',
          height: '100%',
          justifyContent: 'center',
          borderRadius: '10px',
          '& svg': {
            paddingRight: '10px',
            paddingLeft: '10px',
          },
          '& .MuiSelect-outlined': {
            fontSize: '14px',
            paddingRight: { sm: '43px !important', xs: '37px !important' },
            paddingLeft: 0,
            zIndex: "11"
          },
          '& .MuiSelect-select': {
            '&>em': {
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              color: ' #7D7D7D99',
              fontWeight: 400,
              lineHeight: '24px',
              fontSize: '16px',
              paddingLeft: '0px',
            },
          },
          '&:focus': {
            outline: 'none !important',
            borderRadius: 'inherit',
          },
          ...style,
        }}
      >
        {children ||
          data?.map((item: any) => {
            return (
              <MenuItem key={item} value={item}>
                <Typography variant='body2' lineHeight='24px'>
                  {item}
                </Typography>
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};
