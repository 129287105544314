import { Box, Typography } from '@mui/material';
import { colors } from '../../styles/color';
import { ReactComponent as ArrowLeftGray } from '../../assets/icons/ArrowLeftGray.svg';
import Topbar from '../../components/common/Topbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import { fetchProfileUpdateDetails } from '../../redux/features/profileEdit/profileEditSlice';
import { ToastMessage } from '../../helpers/ToastMessage';
import { setLoading } from '../../redux/features';
import * as yup from 'yup';
import ProfileEdit from '../../components/common/ProfileEdit';

interface ProfileEditData {
  name: any;
  email: any;
  contact: any;
  bio: any;
  profile: any;
}
interface Props {
  toggle?: boolean;
  setToggle?: any;
}
interface FormErrors {
  name?: string;
  contact?: string;
}

export const EditProfile = ({ toggle, setToggle }: Props) => {
  const { profileData } = useSelector((state: any) => state?.profile);
  const [ckEditor, setCkEditor] = useState('');
  const [file, setFile] = useState<any>();
  const [formData, setFormData] = useState<ProfileEditData>({
    name: profileData?.data?.name,
    email: profileData?.data?.email,
    contact: profileData?.data?.contact,
    bio: profileData?.data?.bio,
    profile: profileData?.data?.profile,
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, 'Name can only contain letters.')
      .max(25, 'Full name must be at most 25 characters')
      .min(3, 'Full name must be at least 3 characters')
      .required('Full name is required'),
    contact: yup
      .string()
      .matches(/^\d{10}$/, 'Contact number must have 10 digits to be valid')
      .matches(/^[0-9]+$/, 'Contact number must contain only numbers')
      .required('Contact number is required'),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;

    const getProfileData = async () => {
      const res = await dispatch(fetchProfileDetails());
      setTimeout(() => {
        if (res?.payload) {
          dispatch(setProfileState(res?.payload));
        } else {
          dispatch(setProfileState({}));
        }
      }, 100);
    };
    if (Object.keys(profileData).length === 0) getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileData) {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      setFormData({
        name: profileData?.name,
        email: profileData?.email,
        contact: profileData?.contact,
        bio: profileData?.bio === null ? '' : profileData.bio,
        profile: profileData?.profile,
      });
    }
  }, [profileData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data: any = e.target.files && e.target.files[0];
    if (data) {
      const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validFormats.includes(data.type)) {
        ToastMessage({
          type: 'error',
          message: 'Please select a valid image file (PNG, JPG, JPEG).',
        });
        return; // Stop execution if the file format is invalid
      }
      const fileSize = data.size;
      var fileSizes = Math.round(fileSize / 1024);
      if (fileSizes > 1024 * 5) {
        // setFile(null)
        ToastMessage({
          type: 'error',
          message: 'Please select a file less than 5MB.',
        });
      } else {
        setFile(data);
      }
    }
  };

  const handleFormChange = (e: any) => {
    let obj = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(obj);
  };

  useEffect(() => {});

  const successProfile = async () => {
    setFormErrors({});
    const formData1 = new FormData();
    if (file) formData1.append('profile', file);
    // formData1.append('profile', file); // append the file to the form data
    formData1.append('name', formData.name);
    formData1.append('email', formData.email);
    formData1.append('contact', formData.contact);
    formData1.append('bio', ckEditor);

    dispatch(setLoading(true));
    const res = await dispatch(fetchProfileUpdateDetails(formData1));

    if (res?.payload?.status) {
      dispatch(setProfileState(res?.payload?.data));
      ToastMessage({
        type: 'success',
        message: res.payload.message,
      });
      dispatch(setLoading(false));
      setToggle(false);
    } else {
      dispatch(setProfileState({}));
      ToastMessage({
        type: 'error',
        message: res.error.message,
      });
      dispatch(setLoading(false));
    }
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    const validateObj = {
      name: formData?.name,
      contact: formData?.contact,
    };
    schema
      .validate(validateObj, { abortEarly: false })
      .then(async () => {
        successProfile();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
    // dispatch(fetchProfileUpdateDetails(formData));
  };

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      <Topbar>
        <Box
          display={'flex'}
          alignItems={'center'}
          textAlign={'center'}
          width='100%'
          sx={{ pl: { xl: 0, xs: '20px' } }}
        >
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box mr={'15px'} onClick={() => setToggle(false)} sx={{ cursor: 'pointer' }}>
              <Typography variant='body1' fontSize={'18px'} lineHeight='27px' color={colors.gray[300]}>
                Profile
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} mr={'15px'}>
              <ArrowLeftGray />
            </Box>
            <Box>
              <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
                Edit Profile
              </Typography>
            </Box>
          </Box>
        </Box>
      </Topbar>

      <Box sx={{ p: '0px 24px', mt: '16px', mb: '16px' }}>
        <Box pt={'60px'} bgcolor={colors.dark[800]} borderRadius='12px'>
          <ProfileEdit
            isTextInput={true}
            column1={'Full Name'}
            file={file}
            handleFormChange={(e: any) => handleFormChange(e)}
            formErrors={{
              'Full Name': formErrors.name,
              Email: '',
              'Contact Number': formErrors.contact,
              About: '',
            }}
            buttonName={'Update Profile'}
            handleFormSubmit={handleFormSubmit}
            handleChange={handleChange}
            placeholder1={'Full Name'}
            placeholder2={'Email'}
            column2='Email'
            placeholder3={'Contact Number'}
            column3='Contact Number'
            placeholder4={'About Yourself....'}
            column4='About'
            value1={formData?.profile}
            value2={formData.name}
            value3={formData?.email}
            value5={formData?.bio}
            value4={formData?.contact}
            name1={'profile'}
            name2={'name'}
            setCkEditor={setCkEditor}
            name3={'email'}
            name4={'contact'}
            name5={'bio'}
            readOnly={{
              col1: false,
              col2: false,
              col3: true,
              col4: false,
              col5: false,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
