import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerState } from '../../redux/features/drawer/drawerSlice';
import DrawerRoot from '../../components/Drawer';
import { FilterData } from '../../components/FilterData';

interface ChildComponentProps {
  visible?: any;
  setVisible?: any;
  filterRadioButton?: any;
  value?: any;
  values?: any;
  setValues?: any;
  open?: any;
  setOpen?: any;
  customApplyClick?: any;
  startDate?: any;
  endDate?: any;
  weekDays?: any;
}

export const FilterDrawer = ({
  visible,
  setVisible,
  filterRadioButton,
  value,
  values,
  setValues,
  customApplyClick,
  open,
  setOpen,
  startDate,
  endDate,
  weekDays,
}: ChildComponentProps) => {
  const [direction, setDirection] = useState('');
  const dispatch = useDispatch();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  return (
    <DrawerRoot direction='right' DrawerRightRootClassName='drawer-main-search'>
      <Box
        sx={{
          width: '300px',
        }}
      >
        <Box>
          {' '}
          <FilterData
            startDate={startDate}
            endDate={endDate}
            weekDays={weekDays}
            values={values}
            open={open}
            setOpen={setOpen}
            setValues={setValues}
            customApplyClick={(startDate: any, endDate: any) => customApplyClick(startDate, endDate)}
            filterRadioButton={(e: React.ChangeEvent<HTMLInputElement>) => filterRadioButton(e)}
            visible={visible}
            setVisible={setVisible}
            value={value}
          />
        </Box>
      </Box>
    </DrawerRoot>
  );
};
