import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

const InfluencerProtectedRoute = (props: any) => {
  const { children } = props;
  const [token] = useCookies();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  }, []);

  const func = useCallback(() => {
    if (token.role === '2') {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      return true;
    } else {
      return false;
    }
  }, [token]);

  return func() ? children : <Navigate to='/sign-in' />;
};

export default InfluencerProtectedRoute;
