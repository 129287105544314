import { Box } from "@mui/system"
import { colors } from "../../styles/color"
import { Typography } from "@mui/material"
import { Close } from "@mui/icons-material"

const ChatFilesDisplayContent = (props: any) => {
    const { image, name, handleDeleteClick, index } = props
    return (
        <Box display='flex' height='100%' p='10px' flexShrink='0'>
            <Box
                display='flex'
                alignItems={'center'}
                sx={{
                    border: `1px solid ${colors.dark[100]}`,
                    borderRadius: '10px',
                    p: '10px',
                    maxWidth: '200px',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {image}
                </Box>
                <Box>
                    <Typography
                        variant='subtitle1'
                        color={colors.gray[300]}
                        sx={{
                            whiteSpace: 'nowrap',
                            width: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            pl: '10px',
                            textTransform: "none"
                        }}
                    >
                        {name}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        '& svg': {
                            fill: "white",
                            width: '15px',
                            height: 'auto',
                        },
                    }}
                >
                    <Close onClick={() => handleDeleteClick(index)} />
                </Box>
            </Box>
        </Box>
    )
}

export default ChatFilesDisplayContent