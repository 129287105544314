import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API } from '../../../api/services';

export const fetchInquiry: any = createAsyncThunk('getInquiry/fetchInquiry', async (payload: any) => {
    const response = await API.post(`/inquiry?page=${payload.page}`, payload.data);
    return response?.data;
});

interface inquiryDetail {
    getInquiry: Record<any, any>
}

const initialState: inquiryDetail = {
    getInquiry: {}
};

const getInquiry = createSlice({
    name: 'getInquiry',
    initialState,
    reducers: {
        setInquiry: (state, { payload }) => {
            state.getInquiry = payload;
        },
    },
});
export const { setInquiry } = getInquiry.actions;
export default getInquiry.reducer;