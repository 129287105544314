import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { colors } from "../../styles/color";
import {
  ActionIcon,
  DiscoverIcon,
  MonitorIcon,
} from "../../assets/icons";

const HowDoesItWork = () => {
  const influencer = [
    {
      name: "1. Registration",
      icon: <DiscoverIcon />,
      disc: "Create an account on Quick Fluence by providing your details and public information such as video pricing, content language, duration, categories, length, and target audience country. Your profile is your digital resume.",
    },
    {
      name: "2. Discover Opportunities",
      icon: <MonitorIcon />,
      disc: "Explore collaboration opportunities from brand owners looking for influencers like you. Use our intuitive search and filtering options to find campaigns that match your expertise and interests",
    },
    {
      name: "3. Connect and Collaborate",
      icon: <ActionIcon />,
      disc: "Express your interest in campaigns by using the inquire button. Engage in a chat to discuss campaign details, goals, and expectations. Collaborate, co-create content, and execute captivating influencer campaigns.",
    }
  ];
  const BrandOwners = [
    {
      name: "1. Registration",
      icon: <DiscoverIcon />,
      disc: "Sign up on Quick Fluence as a brand owner. Create your account and profile to showcase your brand's identity, values, and campaign objectives.",
    },
    {
      name: "2. Search for Influencers",
      icon: <MonitorIcon />,
      disc: "Utilize our search and filtering tools to find influencers who align with your brand's vision. Discover influencers based on video pricing, content language, categories, and more.",
    },
    {
      name: "3. Inquire and Connect",
      icon: <ActionIcon />,
      disc: "Reach out to influencers you're interested in collaborating with using the inquire button. Initiate a chat to discuss campaign details, expectations, and goals. Build authentic relationships for impactful influencer marketing.",
    }];
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "15px",
          }}
        >
          <Typography
            display={"inline"}
            variant="h2"
            sx={{
              fontWeight: "600",
              fontSize: "36px",
              lineHeight: "54px",
              textAlign: "center",
            }}
          >
            How does it{" "}
            <Typography
              display={"inline"}
              variant="h2"
              sx={{
                fontWeight: "600",
                fontSize: "36px",
                lineHeight: "54px",
                color: colors.basics.primary,
              }}
            >
              work?
            </Typography>{" "}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            color: colors.gray[300],
            lineHeight: "33px",
            textAlign: "center",
            textTransform:'none',
          }}
        >
          Welcome to Quick Fluence, your platform for seamless influencer collaboration. Our user-friendly process ensures that connecting with the right influencers or brand owners is straightforward and efficient. Here's how it works:
        </Typography>
        <Box sx={{
          margin: '30px 0',
          display:'grid',
          gridTemplateColumns: {md:'1fr 1fr',xs:'1fr'},
          gap: '20px',
          '& .word-box' : {
            backgroundColor: colors.dark[600],
            padding: {md:'30px',xs:'20px'},
            borderRadius: '20px',
          }
        }}>
          <Box className="for-influencer word-box">
            <Typography variant="h3" 
              sx={{
                color:colors.basics.white,
                fontSize: {sm:'28px',xs:'25px'},
                fontWeight: '600'
              }}
            >For <span style={{color:colors.basics.primary, fontSize: 'inherit'}}> Influencers</span></Typography>
            <Box className="box-list" sx={{mt:{sm:'30px',xs:'20px'}}}>
              {influencer.map((item, index)=>{
                return (
                  <Box key={index} className="box-item" 
                    sx={{
                      display:'flex',
                      position:'relative',
                      '&:not(:last-child)' : {
                        pb:'40px',
                      },
                      '&:not(:last-child)::after' : {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: '30px',
                        left: {lg:'37px', xs:'27px'},
                        width: '3px',
                        height: '94%',
                        borderRight: `2px dashed ${colors.dark[100]}`,
                        zIndex: '0',
                      }
                    }}
                  >
                    <Box
                      className="img-box"
                      sx={{
                        textAlign: "center",
                        background: colors.dark[800],
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        flexShrink:'0',
                        width:'100%',
                        maxWidth: {xl: "80px", sm: "60px", xs:'60px' },
                        height: {xl: "80px", sm: "60px", xs:'60px' },
                        marginRight:'20px',
                        position: "relative",
                        zIndex: "1",
                      }}
                    >
                      <Box
                        sx={{
                          "& svg": {
                            width: { xl: "37px", sm: "25px", xs:'25px' },
                            height: 'auto',
                          },
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    <Box >
                      <Typography
                        variant="body2"
                        sx={{
                          pb: {sm:"10px",xs:'5px'},
                          lineHeight:'1.5',
                          fontSize:{sm:'18px',xs:'16px'},
                          textTransform:'none',
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography variant="subtitle1" 
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: colors.gray[300],
                          lineHeight: "1.5",
                          textTransform:'none',
                        }}
                      >{item.disc}</Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box className="for-brand-owners word-box">
            <Typography variant="h3" 
              sx={{
                color:colors.basics.white,
                fontSize: {sm:'28px',xs:'25px'},
                fontWeight: '600'
              }}
            >For <span style={{color:colors.basics.primary, fontSize: 'inherit'}}> Brand Owners</span></Typography>
            <Box className="box-list" sx={{mt:{sm:'30px',xs:'20px'}}}>
              {BrandOwners.map((item, index)=>{
                return (
                  <Box key={index} className="box-item" 
                    sx={{
                      display:'flex',
                      pb:'40px',
                      position:'relative',
                      '&:not(:last-child)::after' : {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: '30px',
                        left: {lg:'37px', xs:'27px'},
                        width: '3px',
                        height: '94%',
                        borderRight: `2px dashed ${colors.dark[100]}`,
                        zIndex: '0',
                      }
                    }}
                  >
                    <Box
                      className="img-box"
                      sx={{
                        textAlign: "center",
                        background: colors.dark[800],
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        flexShrink:'0',
                        width:'100%',
                        maxWidth: {xl: "80px", sm: "60px", xs:'60px' },
                        height: {xl: "80px", sm: "60px", xs:'60px' },
                        marginRight:'20px',
                        position: "relative",
                        zIndex: "1",
                      }}
                    >
                      <Box
                        sx={{
                          "& svg": {
                            width: { xl: "37px", sm: "25px", xs:'25px' },
                            height: 'auto',
                          },
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    <Box >
                      <Typography
                        variant="body2"
                        sx={{
                          pb: {sm:"10px",xs:'5px'},
                          lineHeight:'1.5',
                          fontSize:{sm:'18px',xs:'16px'},
                          textTransform:'none',
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography variant="subtitle1" 
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: colors.gray[300],
                          lineHeight: "1.5",
                          textTransform:'none',
                        }}
                      >{item.disc}</Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HowDoesItWork;
