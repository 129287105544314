import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles/color';
import Topbar from '../../components/common/Topbar';
import {
  ArrowLeftGray,
} from '../../assets/icons';
import { StoreChannel } from '../../components/common/StoreChannel';

export const AddChannel = () => {
  //! Hooks
  const [show, setShow] = React.useState(true);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      <Topbar>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          textAlign={'center'}
          sx={{ pl: { xl: 0, xs: '20px' } }}
        >
          <Box mr={'15px'}>
            <Typography variant='body1' fontSize={'18px'} lineHeight='27px' color={colors.gray[300]}>
              Social
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mr={'15px'}>
            <ArrowLeftGray />
          </Box>
          <Box onClick={() => navigate('/dashboard/social/youtube')} sx={{ cursor: 'pointer' }}>
            <Typography
              variant='body1'
              fontSize={'18px'}
              lineHeight='27px'
              color={colors.gray[300]}
              mr={'15px'}
            >
              YouTube
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mr={'15px'}>
            <ArrowLeftGray />
          </Box>
          <Box>
            <Typography variant='body1' fontSize={'18px'} lineHeight='27px'>
              Add Channel
            </Typography>
          </Box>
        </Box>
      </Topbar>
      <Box p='0px 16px' mt={'16px'}>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '12px',
            color: 'white',
            p: { md: '70px 100px 20px', xs: '20px 10px' },
          }}
        >
          <StoreChannel show={show} setShow={setShow} />
        </Box>
      </Box>
    </Box>
  );
};
