import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import sizeConfigs from '../../styles/sizeConfigs';
import Sidebar from '../common/Sidebar';

const InfluencerRoute = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component='nav'
        sx={{
          width: { xl: sizeConfigs.sidebar.width, sx: '100%' },
          flexShrink: 0,
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: `calc(100% - 282px)`,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default InfluencerRoute;
