import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppReducer';
import { useParams } from 'react-router-dom';
import { setLoading } from '../../redux/features';
import {
  fetchInquiryMessageList,
  setInquiryMessageList,
} from '../../redux/features/inquiryMessageList/inquiryMessageList';
import { ToastMessage } from '../../helpers/ToastMessage';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchNotification,
  setNotificationState,
} from '../../redux/features/notificationDetails/notificationDetails';
import BusinessInquiryChat from '../../pages/BusinessInquiryChat/busniessInquiryChat';
import { useCookies } from 'react-cookie';
import { InquiryDetails } from '../../pages/inquiry/InquiryDetails';
import { ApplicationStateType } from '../../redux/store';
import NoInquiry from './../../assets/images/NoInquiry.png';
import Image from './Image';
import NoDataFound from './NoDataFound';
import { setAppState } from '../../redux/features/appStateSlice';

const CommanChat = () => {
  const { inquiryMessageList } = useAppSelector((state: ApplicationStateType) => state.inquryMessageList);
  const { loading } = useAppSelector((state: ApplicationStateType) => state.loading);
  const [loader, setLoader] = useState(true);
  const [token] = useCookies();
  const dispatch = useDispatch();
  const params = useParams();

  const fetchList = useCallback(
    async (isFirstTime?: any) => {
      dispatch(setLoading(true));
      const obj = {
        id: params.id || '',
      };
      const res = await dispatch(fetchInquiryMessageList(obj));
      if (res?.error) {
        ToastMessage({
          type: 'error',
          message: res.error.message,
        });
        dispatch(setInquiryMessageList({}));
        dispatch(setLoading(false));
        setLoader(false);
      } else {
        dispatch(setInquiryMessageList(res.payload.data));
        if (isFirstTime) {
          getNotification();
        } else {
          dispatch(setLoading(false));
          setLoader(false);
        }
      }
    },
    [dispatch, params.id]
  );

  const getNotification = useCallback(async () => {
    const res = await dispatch(fetchNotification());
    const data = res.payload.data;
    if (data) {
      dispatch(setNotificationState(data));
      dispatch(setLoading(false));
      setLoader(false);
    } else {
      dispatch(setNotificationState({}));
      dispatch(setLoading(false));
      setLoader(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAppState('inquiry'));
    dispatch(setInquiryMessageList({}));
    fetchList(true);
  }, [fetchList]);

  return (
    <>
      {!loader ? (
        Object.keys(inquiryMessageList).length > 0 ? (
          token.role === '3' ? (
            <BusinessInquiryChat
              inquiryMessageList={inquiryMessageList}
              loading={loading}
              fetchList={fetchList}
            />
          ) : (
            <InquiryDetails inquiryMessageList={inquiryMessageList} loading={loading} fetchList={fetchList} />
          )
        ) : (
          <NoDataFound
            parentSx={{
              textAlign: 'center',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
            sx={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              textAlign: 'center',
              transform: 'translate(-50%, -50%)',
            }}
            svg={<Image src={NoInquiry} />}
            text={'You have no inquiries yet'}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default CommanChat;
