import { Navigate, Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

const WithNavWithLogin = () => {
  const [cookies] = useCookies();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  });

  return cookies.role === '2' ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to={'/dashboard'} />
  );
};

export default WithNavWithLogin;
