import { Box, Button, Typography } from '@mui/material';
import { colors } from '../../styles/color';
import { Download, ImageIcon, VideoIcon, FileTypeIcon } from '../../assets/icons';
import NoDataFound from './NoDataFound';
import Image from './Image';
import NoInquiry from '../../assets/images/NoInquiry.png';
import LinkImage from '../../assets/images/Link.png';
import parse from 'html-react-parser';
import { isEmpty } from '../../utils/commanFunctions/commanFunctions';
import userImage from '../../assets/images/p1.png';

const InquiryChat = (props: any) => {
  const { inquiry } = props;

  return (
    <Box
      sx={{
        '&::-webkit-scrollbar ': {
          width: '3px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: colors.dark[100],
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '10px',
        },
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        margin: '20px 0px',
        paddingRight: '10px',
      }}
      id='boxData'
    >
      <Box>
        <Box
          id='boxData'
          mt={'16px'}
          sx={{
            '& .MuiDivider-root': {
              m: 0,
            },
          }}
        ></Box>
        {inquiry.length > 0 ? (
          inquiry.map((message: any, index: any) => {
            return (
              <Box key={index}>
                <Box display='flex' mt={{ md: '20px', xs: '10px' }}>
                  <Box
                    mr={{ sm: '20px', xs: '10px' }}
                    sx={{
                      '& .img-size': {
                        width: { sm: '56px', xs: '40px' },
                        height: { sm: 'auto', xs: 'auto', md: '56px' },
                        mt: { sm: 0, xs: '4px' },
                        borderRadius: { sm: '10px', xs: '5px' },
                      },
                    }}
                  >
                    <img
                      style={{ objectFit: 'cover' }}
                      src={message?.sender_user?.profile ? message?.sender_user?.profile : userImage}
                      alt='img'
                      className='img-size'
                    />
                  </Box>
                  <Box>
                    <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'}>
                      <Typography
                        variant='body2'
                        lineHeight='180%'
                        color={colors.gray[300]}
                        fontSize={{ sm: '16px', xs: '14px' }}
                        alignItems={'center'}
                      >
                        {message?.sender_user?.name}
                      </Typography>
                      <Typography
                        sx={{ display: { sm: 'flex', xs: 'none' } }}
                        p={'0px 10px'}
                        variant='subtitle1'
                        fontWeight={400}
                        fontSize='30px'
                        color={colors.gray[300]}
                      >
                        ·
                      </Typography>

                      <Typography
                        variant='body2'
                        lineHeight='180%'
                        color={colors.gray[300]}
                        fontSize={{ sm: '16px', xs: '14px' }}
                      >
                        {message?.date_time?.replace(/([a-zA-Z]+),(\d{4})/, '$1,  $2')}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        '& p': {
                          marginTop: '0',
                        },
                        '& a': {
                          color: colors.basics.primary,
                        },
                      }}
                    >
                      <Typography
                        variant='body2'
                        sx={{ wordBreak: 'break-word' }}
                        fontWeight={400}
                        fontSize={{ sm: '16px', xs: '14px' }}
                      >
                        {isEmpty(message?.message) === '' ? '' : parse(message?.message)}
                      </Typography>
                    </Box>
                    <Box
                      mt={'10px'}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiButton-root': {
                          '&:hover': { border: `1px solid ${colors.dark[100]}` },
                        },
                      }}
                    >
                      {message?.attachment.length > 0 &&
                        message?.attachment.map((file: any, index: any) => {
                          let extension = file.attachment.split('.');
                          let icon;
                          if (['png', 'jpg', 'jpeg', 'svg', 'webp'].includes(extension[extension.length - 1]))
                            icon = <ImageIcon />;
                          else if (['mp4', 'mp3', 'wav'].includes(extension[extension.length - 1]))
                            icon = <VideoIcon />;
                          else icon = <FileTypeIcon />;
                          const imgSrcSplit = file.attachment.split('/');
                          return (
                            <Box key={index}>
                              <Button
                                variant='outlined'
                                startIcon={icon}
                                endIcon={
                                  <Download
                                    onClick={async () => {
                                      const link = document.createElement('a');
                                      fetch(file.attachment)
                                        .then((res) => {
                                          return res.blob();
                                        })
                                        .then((blob) => {
                                          const reader: any = new FileReader();
                                          reader.onloadend = () => {
                                            link.href = reader.result;
                                            link.download = imgSrcSplit[imgSrcSplit.length - 1];
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                          };
                                          reader.readAsDataURL(blob);
                                        });
                                    }}
                                  />
                                }
                                sx={{
                                  border: `1px solid ${colors.dark[100]}`,
                                  color: colors.gray[300],
                                  mr: '10px',
                                  mb: '10px',
                                }}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color={colors.gray[300]}
                                  sx={{
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    width: { sm: '150px', xs: '125px' },
                                    overflow: 'hidden',
                                  }}
                                >
                                  {imgSrcSplit[imgSrcSplit.length - 1]}
                                </Typography>
                              </Button>
                            </Box>
                          );
                        })}
                    </Box>
                    <Box
                      mt={'10px'}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiButton-root': {
                          '&:hover': { border: `1px solid ${colors.dark[100]}` },
                        },
                      }}
                    >
                      {message?.link && (
                        // message?.links.length > 0 && message?.links.map((link: any, index: Number) => {
                        // return
                        <Button
                          onClick={() => {
                            window.open(message?.link, '_blank');
                          }}
                          variant='outlined'
                          startIcon={<Image src={LinkImage} />}
                          sx={{
                            border: `1px solid ${colors.dark[100]}`,
                            color: colors.gray[300],
                            mr: '10px',
                            mb: '10px',
                          }}
                        >
                          <Typography
                            variant='subtitle1'
                            color={colors.gray[300]}
                            sx={{
                              textAlign: 'left',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: { sm: '150px', xs: '125px' },
                              overflow: 'hidden',
                            }}
                          >
                            {message.link}
                          </Typography>
                        </Button>
                      )}
                      {/* )} */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <NoDataFound
            sx={{
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
            svg={<Image src={NoInquiry} />}
            text={'You have no inquiries yet'}
          />
        )}
      </Box>
    </Box>
  );
};

export default InquiryChat;
