import { Box } from "@mui/material"
import { MoreOption } from '../../assets/icons';

const FileAttachment = (props: any) => {
    const { handleFileInputChange, multiple ,accept , inputClassName , svgImage} = props
    return (
        <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                <Box  onChange={handleFileInputChange}>
                    <label htmlFor='multiFiles'  style={{ cursor: 'pointer' }}>
                        <Box display={'flex'}>
                            {' '}
                            <Box className={inputClassName}>
                                <MoreOption className={svgImage} />
                            </Box>
                        </Box>
                    </label>
                </Box>

                <Box display='none'>
                    <input  accept={accept} onChange={(e) => handleFileInputChange(e)} multiple={multiple} type='file' id='multiFiles' />
                </Box>
            </Box>
        </Box>
    )
}

export default FileAttachment