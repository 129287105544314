/** @format */

import { Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { colors } from "../../styles/color";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/features/loader/loaderSlice";
import {
  fetchChannelListDetails,
  setChannelListState,
} from "../../redux/features/channelList/channelListSlice";
import {
  fetchChannelDetails,
  setChannelDetailsState,
} from "../../redux/features/channelDetails/channelDetailsSlice";
import { EditChannel } from "./EditChannel";
import {
  fetchProfileDetails,
  setProfileState,
} from "../../redux/features/profile/profileSlice";
import { EmptyYoutubePage } from "./EmptyYoutubePage";
import { ToastMessage } from "../../helpers/ToastMessage";
import { durationTime } from "../../constants/menuItems";
import {
  AlertCircle,
  ArrowRight,
  ChannelDone,
  EditIcon,
  Plus,
} from "../../assets/icons";
import { useGoogleLogin } from "@react-oauth/google";
import {
  fetchChannelSuggestionsList,
  setChannelSuggestionsState,
} from "../../redux/features/channelSuggestionList/channelSuggestionListSlice";
import BreadCrumb from "../../components/common/BreadCrumb";
import Image from "../../components/common/Image";
import { makeStyles } from "@mui/styles";
import { nFormatter } from "../../utils/commanFunctions/commanFunctions";
import parse from "html-react-parser";
import { setAppState } from "../../redux/features/appStateSlice";

type props = {
  access_token?: any;
  accesstoken?: any;
};

const useStyles: any = makeStyles({
  channelLogo: {
    width: "50px",
    height: "50px",
    "@media (max-width: 576px)": {
      width: "32px",
      height: "32px",
    },
  },
});

export const YouTubePage = () => {
  const { channelDetails } = useSelector((state: any) => state?.channelDetails);
  const { profileData } = useSelector((state: any) => state?.profile);
  const { channelList } = useSelector((state: any) => state?.channelList);
  const { loading } = useSelector((state: any) => state.loading);
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const travelData = [
    {
      views: `${
        channelDetails?.subscriber_count === undefined
          ? 0
          : nFormatter(channelDetails?.subscriber_count, 2)
      }`,
      text: "Subscribers",
      display: true,
    },

    {
      views: `${
        channelDetails?.view_count === undefined
          ? 0
          : nFormatter(channelDetails?.view_count, 2)
      }`,
      text: "Views",
      display: true,
    },
    {
      views: `${
        channelDetails?.video_count === undefined
          ? 0
          : nFormatter(channelDetails?.video_count, 2)
      }`,
      text: "Videos uploaded",
      display: true,
    },
    {
      views: (() => {
        if (
          channelDetails?.video_type === "1" &&
          (channelDetails?.minimum_price !== null ||
            channelDetails?.minimum_price == null) &&
          (channelDetails?.minimum_short_price !== null ||
            channelDetails?.minimum_short_price == null)
        ) {
          const minimumPrice = channelDetails?.minimum_price || 0;
          const minimumShortPrice = channelDetails?.minimum_short_price || 0;
          if (parseInt(minimumPrice) == 0 || parseInt(minimumShortPrice) == 0 || minimumPrice == null || minimumShortPrice == null)  {
            return `₹${Math.max(minimumPrice, minimumShortPrice)}`;
          } else {
            return `₹${Math.floor(Math.min(minimumPrice, minimumShortPrice))}`;
          }
        } 
        
        else if (channelDetails?.video_type === "2") {
          if(channelDetails?.minimum_price !== null ){
          return `₹${Math.floor(channelDetails?.minimum_price)}`
          }else {
            return `₹${Math.floor(channelDetails?.minimum_price)}`
          }
        } 
        
        else if (
          channelDetails?.video_type === "3"  ) {
          if(channelDetails?.minimum_short_price !== null){
            return `₹${Math.floor(channelDetails?.minimum_short_price)}`;
          }
          else{
          return `₹${Math.floor(channelDetails?.minimum_short_price)}`;
          }
          // { console.log(Math.floor(channelDetails?.minimum_short_price),'Math.floor(channelDetails?.minimum_short_price')}
        } else {
          return "-";
        }
      })(),
      text: "Price Starting At",
      display:
        channelDetails?.minimum_price !== null ||
        channelDetails?.minimum_short_price !== null || channelDetails?.minimum_price == null ||
        channelDetails?.minimum_short_price == null ,
    },
  ];

  useEffect(() => {
    dispatch(setAppState("social.youtube"));
    const getProfileData = async () => {
      const res = await dispatch(fetchProfileDetails());
      if (res?.payload) {
        if (res?.payload?.channel_count === 0) {
          setTimeout(() => {
            dispatch(setLoading(false));
          }, 400);
        }
      } else {
        dispatch(setLoading(false));
      }
      setTimeout(() => {
        dispatch(setProfileState(res?.payload));
      }, 100);
    };
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getChannelListData = async () => {
      dispatch(setLoading(true));
      const res = await dispatch(fetchChannelListDetails());
      dispatch(setChannelListState(res?.payload?.data));
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 600);
    };
    !toggle && getChannelListData();
    // dispatch(setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  useEffect(() => {
    const getChannelDetailsData = async () => {
      const res = await dispatch(fetchChannelDetails(channelList?.id));
      if (res?.error) {
        ToastMessage({
          type: "error",
          message: res.error.message,
        });
      } else {
        dispatch(setChannelDetailsState(res?.payload?.data));
      }
      dispatch(setLoading(false));
    };

    if (channelList?.id) {
      getChannelDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelList]);

  const onSuccessData = async (response: props) => {
    const res = await dispatch(
      fetchChannelSuggestionsList(response?.access_token)
    );
    dispatch(setChannelSuggestionsState(res?.payload?.data));
  };

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      dispatch(setLoading(true));
      await onSuccessData(res);
      dispatch(setLoading(false));
      navigate("/dashboard/add-channel");
    },
    onError: () => {
      dispatch(setLoading(false));
    },
    scope: "https://www.googleapis.com/auth/youtube.readonly",
  });

  // const onFailure: any = (response: any) => {
  //   console.error(response);
  // };

  const handleSubmit = async () => {
    if (channelDetails?.channel_name) {
      ToastMessage({
        type: "error",
        message: "You can not add more than one channel!",
      });
      navigate("/dashboard/social/youtube");
    } else {
      login();
      dispatch(setChannelDetailsState(null));
    }
  };

  const handleEdit = () => {
    setToggle(true);
  };

  const durationObj = durationTime.find(
    (item) => item.id === channelDetails?.upload_time
  );
  const time = durationObj ? durationObj.time : '';

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      {' '}
      <Box>
        {!toggle ? (
          <>
            <BreadCrumb
              name1={'Social'}
              isClickable={false}
              name2={'YouTube'}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropogation();
                navigate('/dashboard/social');
              }}
            />
            {!loading ? (
              <Box
                p='0px 16px'
                mt={'16px'}
                mb={'16px'}
                display={{ md: 'flex', xs: 'block' }}
              >
                {profileData?.channel_count === 0 ||
                channelDetails === null ||
                profileData === undefined ? (
                  <EmptyYoutubePage
                    profileData={profileData}
                    handleSubmit={handleSubmit}
                  />
                ) : (
                  <>
                    {channelDetails !== null ? (
                      channelDetails !== undefined ? (
                        Object.keys(channelDetails).length > 0 && (
                          <Box
                            sx={{
                              background: colors.dark[800],
                              borderRadius: '12px',
                              mr: { md: '16px', xs: '0px' },
                              flex: '40%',
                              mb: { md: '0px', xs: '20px' },
                              minHeight: {
                                md: 'calc(100vh - 132px)',
                                xs: 'auto',
                              },
                            }}
                          >
                            <Box
                              p={{ xs: '15px', sm: '30px' }}
                              flexDirection='column'
                              flex='1'
                              display={'flex'}
                              minHeight={{
                                md: 'calc(100% - 60px)',
                                xs: 'auto',
                              }}
                            >
                              <Box>
                                <Typography>
                                  My Channel (
                                  {profileData?.channel_count
                                    ? profileData?.channel_count
                                    : 0}
                                  )
                                </Typography>
                              </Box>

                              <Box
                                className="border-remove"
                                sx={{
                                  cursor: "pointer",
                                }}
                                mt={"24px"}
                                display="flex"
                                bgcolor={colors.dark[600]}
                                borderRadius={"10px"}
                                alignItems="center"
                                justifyContent={"flex-start"}
                                // onClick={() => handleChannelClick(channelList?.id)}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box
                                    sx={{
                                      p: {
                                        sm: "12px 20px 12px 12px",
                                        xs: "12px 10px 12px 12px",
                                      },
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Image
                                      className={classes.channelLogo}
                                      src={channelList?.image}
                                      // height='50px'
                                      // width='50px'
                                      style={{ borderRadius: "100px" }}
                                      alt="bg-img"
                                    />
                                  </Box>
                                  <Box display={{ sm: "flex", xs: "none" }}>
                                    <Typography mr="6px">
                                      {channelList?.channel_name}
                                    </Typography>
                                    {channelList?.channel_name && (
                                      <Typography
                                        variant="body1"
                                        fontSize={{ xs: "14px", sm: "20px" }}
                                        display={"flex"}
                                        alignItems="center"
                                      >
                                        <ChannelDone />
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box display={{ sm: "none", xs: "flex" }}>
                                    <Typography
                                      mr="6px"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        maxWidth: { sm: "auto", xs: "210px" },
                                        overflow: "hidden",
                                        display: "inline-block",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {channelList?.channel_name}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      display={"flex"}
                                      ml={{ sm: "-8px", xs: "5px" }}
                                      alignItems="center"
                                    >
                                      <ChannelDone />
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display={"flex"}
                                  pr="20px"
                                  justifyContent={"flex-end"}
                                  flexGrow={1}
                                >
                                  <ArrowRight />
                                </Box>
                              </Box>

                              {channelDetails !== null &&
                                channelDetails !== undefined &&
                                Object.keys(channelDetails).length === 0 && (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      mt: "auto",
                                      pt: "20px",
                                      "& .MuiButton-root": {
                                        border: `1px solid ${colors.dark[100]}`,
                                        "&:hover": {
                                          border: `1px solid ${colors.dark[100]}`,
                                        },
                                      },
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      sx={{ width: "100%" }}
                                      startIcon={<Plus />}
                                      type="submit"
                                      onClick={handleSubmit}
                                    >
                                      Add Channel{" "}
                                    </Button>
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    {channelDetails !== null ? (
                      channelDetails !== undefined ? (
                        Object.keys(channelDetails).length > 0 && (
                          <Box
                            sx={{
                              background: colors.dark[800],
                              borderRadius: "12px",
                              flex: "60%",
                            }}
                          >
                            <Box p={{ xs: "15px", sm: "30px" }}>
                              <Box
                                display={"flex"}
                                justifyContent="space-between"
                                alignItems={"center"}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ p: "12px 20px 12px 0px" }}>
                                    <Image
                                      className={classes.channelLogo}
                                      src={channelDetails?.image}
                                      height="50px"
                                      width="50px"
                                      style={{ borderRadius: "100px" }}
                                      alt="bg-img"
                                    />
                                  </Box>
                                  <Box>
                                    <Box
                                      display={{ sm: "flex", xs: "none" }}
                                      sx={{
                                        "& svg": {
                                          height: { sm: "24px", xs: "16px" },
                                          width: { sm: "23px", xs: "16px" },
                                        },
                                      }}
                                    >
                                      {" "}
                                      <Typography
                                        variant="body1"
                                        fontSize={{ xs: "14px", sm: "20px" }}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          // width: { sm: '190px', xs: '190px' },
                                          overflow: "hidden",
                                          display: "inline-block",
                                          fontSize: "16px",
                                        }}
                                        mr="6px"
                                      >
                                        {channelDetails?.channel_name}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        fontSize={{ xs: "14px", sm: "20px" }}
                                        sx={{
                                          wordBreak: "break-word",
                                        }}
                                        display={"flex"}
                                        alignItems="center"
                                      >
                                        <ChannelDone />
                                      </Typography>
                                    </Box>
                                    <Box
                                      display={{ sm: "none", xs: "flex" }}
                                      sx={{
                                        "& svg": {
                                          height: { sm: "24px", xs: "16px" },
                                          width: { sm: "23px", xs: "16px" },
                                        },
                                      }}
                                    >
                                      {" "}
                                      <Typography
                                        variant="body1"
                                        fontSize={{ xs: "14px", sm: "20px" }}
                                        sx={{
                                          wordBreak: "break-word",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          maxWidth: { sm: "auto", xs: "230px" },
                                          overflow: "hidden",
                                          display: "inline-block",
                                        }}
                                        mr="6px"
                                      >
                                        {channelDetails?.channel_name}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        fontSize={{ xs: "16px", sm: "20px" }}
                                        sx={{
                                          wordBreak: "break-word",
                                        }}
                                        display={"flex"}
                                        alignItems="center"
                                      >
                                        <ChannelDone />
                                      </Typography>
                                    </Box>
                                    <Typography
                                      color={colors.gray[300]}
                                      variant="body2"
                                      lineHeight={"24px"}
                                      fontWeight={400}
                                      sx={{
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      #{channelDetails?.category?.name}
                                    </Typography>
                                  </Box>{" "}
                                </Box>
                                <Box>
                                  <Box
                                    pr={{ sm: "20px", xs: "0" }}
                                    justifyContent={"flex-end"}
                                    marginLeft={"10px"}
                                    flexGrow={1}
                                    sx={{
                                      "& .MuiButton-root": {
                                        border: `1px solid ${colors.dark[100]}`,
                                        "&:hover": {
                                          border: `1px solid ${colors.dark[100]}`,
                                        },
                                      },
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        "& span": {
                                          margin: {
                                            xs: "0",
                                            sm: "0 8px 0 -4px",
                                          },
                                        },
                                        flexShrink: 0,
                                        minWidth: { xs: 0, sm: "64px" },
                                        fontSize: { xs: 0, sm: "16px" },
                                        height: { xs: "auto", sm: "50px" },
                                        padding: { sm: "5px 15px", xs: "10px" },
                                      }}
                                      startIcon={<EditIcon />}
                                      onClick={handleEdit}
                                    >
                                      Edit
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                // width='100%'
                                display={{ md: "flex", xs: "block" }}
                                flexDirection={"row"}
                                justifyContent="space-between"
                                flex-wrap=" wrap"
                              >
                                {travelData.map((item, index) => {
                                  return (
                                    item.display && (
                                      <Box
                                        key={index}
                                        sx={{
                                          m: { sm: '10px', xs: '10px 0px' },
                                          ml: '0',
                                          background: colors.dark[600],
                                          p: '15px 20px',
                                          borderRadius: '12px',
                                          justifyContent: 'space-between',
                                          flex: '25%',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          fontSize={'14px'}
                                          lineHeight={'116.18%'}
                                          color={colors.gray[300]}
                                          pb={'8px'}
                                        >
                                          {item.text}
                                        </Typography>{' '}
                                        <Typography
                                          variant='h2'
                                          textAlign={'left'}
                                        >
                                          {item.text === "Price Starting At" &&
                                          item.views === "$0"
                                            ? "-"
                                            : item.views}
                                        </Typography>
                                      </Box>
                                    )
                                  );
                                })}
                              </Box>
                              <Box mt={"30px"}>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    fontSize={"18px"}
                                    lineHeight={"27px"}
                                  >
                                    About channel
                                  </Typography>
                                </Box>
                                <Box
                                  mt={"20px"}
                                  sx={{
                                    "& .MuiTypography-root a": {
                                      color: "#A175FF",
                                      textDecoration: "none",
                                    },
                                  }}
                                >
                                  {" "}
                                  <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    lineHeight="24px"
                                    color={colors.gray[300]}
                                  >
                                    {parse(channelDetails?.description)}
                                  </Typography>
                                </Box>
                                <Box mt={"22px"} display={"flex"}>
                                  <Box mr={"14px"}>
                                    <AlertCircle />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      fontWeight={400}
                                      lineHeight="24px"
                                      color={colors.gray[300]}
                                    >
                                      Created on {channelDetails?.published_at}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box mt={"23px"}>
                                  <img
                                    src={channelDetails?.thumbnail}
                                    height="90px"
                                    width="90px"
                                    style={{
                                      borderRadius: "8px",
                                      objectFit: "cover",
                                    }}
                                    alt="thumbnail"
                                  />
                                </Box>
                              </Box>
                              <Box
                                borderTop={`1px solid ${colors.dark[100]}`}
                                mt={"30px"}
                              >
                                <Divider />
                              </Box>
                              <Box mt={"30px"}>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    fontSize={"18px"}
                                    lineHeight={"27px"}
                                  >
                                    Other details
                                  </Typography>
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                  <Box
                                    display={{ sm: "flex", xs: "block" }}
                                    justifyContent="flex-start"
                                    mt={"20px"}
                                  >
                                    {channelDetails?.display_video_length !==
                                      "" && (
                                      <Box
                                        flex={"0 0 50%"}
                                        mr={{ sm: "10px", xs: "0px" }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          Average video upload duration length
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.display_video_length}
                                        </Typography>
                                      </Box>
                                    )}{" "}
                                    <Box
                                      flex={"0 0 50%"}
                                      mt={{ sm: "0px", xs: "15px" }}
                                    >
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                        lineHeight="140%"
                                        color={colors.gray[300]}
                                      >
                                        Video upload duration time
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        lineHeight={"140%"}
                                        mt={"5px"}
                                      >
                                        {time}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {channelDetails?.countries ? (
                                    <Box
                                      display={{ sm: "flex", xs: "block" }}
                                      justifyContent="flex-start"
                                      mt={"25px"}
                                    >
                                      <Box flex={"0 0 50%"}>
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          Target audience country
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.countries
                                            ?.map((value: any) => value.name)
                                            .join(", ")}
                                        </Typography>
                                      </Box>
                                      <Box
                                        flex={"0 0 50%"}
                                        mt={{ sm: "0px", xs: "15px" }}
                                        ml={{ sm: "10px", xs: "0px" }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          Video audio language
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.audio
                                            .map(
                                              (language: any) => language?.name
                                            )
                                            .join(", ")}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  {channelDetails?.content_category?.length ? (
                                    <Box
                                      display={{ sm: "flex", xs: "block" }}
                                      justifyContent="flex-start"
                                      mt={"25px"}
                                    >
                                      <Box flex={"0 0 50%"}>
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          {channelDetails?.content_category
                                            ?.length
                                            ? "Content Category"
                                            : ""}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.content_category
                                            ?.length
                                            ? channelDetails?.content_category?.map(
                                                (item: any) => (
                                                  <span key={item.id}>
                                                    &nbsp;
                                                    {item.content_category_details[0].name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      item.content_category_details[0].name.slice(
                                                        1
                                                      )}
                                                    &nbsp;
                                                  </span>
                                                )
                                              )
                                            : ""}
                                        </Typography>
                                      </Box>
                                      <Box
                                        flex={"0 0 50%"}
                                        mt={{ sm: "0px", xs: "15px" }}
                                        ml={{ sm: "10px", xs: "0px" }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          {channelDetails?.category
                                            ? "Category"
                                            : ""}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.category?.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  {channelDetails?.video_type ? (
                                    <Box
                                      display={{ sm: "flex", xs: "block" }}
                                      justifyContent="flex-start"
                                      mt={"25px"}
                                    >
                                      <Box flex={"0 0 50%"}>
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          Video Type
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {channelDetails?.video_type === "2"
                                            ? "Full Video"
                                            : channelDetails?.video_type === "1"
                                            ? "Full Video and Shorts Reels"
                                            : channelDetails?.video_type === "3"
                                            ? "Shorts Reels"
                                            : ""}
                                        </Typography>
                                      </Box>
                                      <Box
                                        flex={"0 0 50%"}
                                        mt={{ sm: "0px", xs: "15px" }}
                                        ml={{ sm: "10px", xs: "0px" }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          lineHeight="140%"
                                          color={colors.gray[300]}
                                        >
                                          Price
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          lineHeight={"140%"}
                                          mt={"5px"}
                                        >
                                          {/* {channelDetails?.video_type === '1' &&
                                          (channelDetails?.minimum_price !==
                                            null ||channelDetails?.minimum_price ==
                                            null) &&
                                          (channelDetails?.minimum_short_price !==
                                            null ||channelDetails?.minimum_short_price ==
                                            null)
                                            ? ((channelDetails?.minimum_short_price == '0.00' ||channelDetails?.minimum_short_price == null) && (channelDetails?.minimum_price == '0.00' ||channelDetails?.minimum_price ==null))?"-": `₹${Math.floor(
                                                channelDetails?.minimum_price 
                                              )}, ₹${Math.floor(
                                                channelDetails?.minimum_short_price
                                              )}`
                                            : (channelDetails?.video_type ===
                                                '2' &&
                                                channelDetails?.minimum_price &&  channelDetails?.minimum_price =='0.00'?"-":
                                                `₹${Math.floor(
                                                  channelDetails?.minimum_price
                                                )}`) ||
                                              (channelDetails?.video_type ===
                                                '3' &&
                                                channelDetails?.minimum_short_price &&
                                                channelDetails?.minimum_short_price == '0.00' ?"-":`₹${Math.floor(
                                                  channelDetails?.minimum_short_price
                                                )}`)}  */}
                                          {channelDetails?.video_type === "1"
                                            ? (channelDetails?.minimum_price !==
                                                null ||
                                                channelDetails?.minimum_price ==
                                                  null) &&
                                              (channelDetails?.minimum_short_price !==
                                                null ||
                                                channelDetails?.minimum_short_price ==
                                                  null)
                                              ? (channelDetails?.minimum_short_price ==
                                                  "0.00" ||
                                                  channelDetails?.minimum_short_price ==
                                                    null) &&
                                                (channelDetails?.minimum_price ==
                                                  "0.00" ||
                                                  channelDetails?.minimum_price ==
                                                    null)
                                                ? "-"
                                                : `₹${Math.floor(
                                                    channelDetails?.minimum_price
                                                  )}, ₹${Math.floor(
                                                    channelDetails?.minimum_short_price
                                                  )}`
                                              : "-"
                                            : channelDetails?.video_type === "2"
                                            ? channelDetails?.minimum_price &&
                                              channelDetails?.minimum_price ==
                                                "0.00"
                                              ? "-"
                                              : `₹${Math.floor(
                                                  channelDetails?.minimum_price
                                                )}`
                                            : channelDetails?.video_type === "3"
                                            ? channelDetails?.minimum_short_price &&
                                              channelDetails?.minimum_short_price ==
                                                "0.00"
                                              ? "-"
                                              : `₹${Math.floor(
                                                  channelDetails?.minimum_short_price
                                                )}`
                                            : null}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <EditChannel
            toggle={toggle}
            setToggle={setToggle}
            channelDetails={channelDetails}
          />
        )}
      </Box>
    </Box>
  );
};
