import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '../../styles/color';
import { ReactComponent as Logo } from '../../assets/logo/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogState, setLoading } from '../../redux/features';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { NotificationIcon } from '../../assets/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProfileSettingOption from './ProfileSettingOption';
import { CustomContainer } from '../CustomContainer';
import NotificationModal from '../../pages/dashboard/NotificationModal';
import { initialLetters } from '../../utils/commanFunctions/commanFunctions';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import { makeStyles } from '@mui/styles';
import { ApplicationStateType } from '../../redux/store';
import { setAppState } from '../../redux/features/appStateSlice';
import { ToastMessage } from '../../helpers/ToastMessage';

function ResponsiveAppBar() {
  const param = useLocation();
  let updatedAppState = '';
  const { profileData } = useSelector((state: any) => state?.profile);

  const { appState } = useSelector((state: ApplicationStateType) => state.appState);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const [token] = useCookies();
  const [open, setOpen] = React.useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const { notificationDetails } = useSelector((state: any) => state.notification);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const useStyles: any = makeStyles({
    headerMargin: {
      '@media (max-width: 372px)': {
        marginTop: token.accessToken ? '' : '10px !important',
      },
    },
    marginButton: {
      marginBottom: '10px !important',
    },
    mTop: {
      '@media (max-width: 408px)': {
        padding: '5px 0px',
      },
    },
    marginButton2: {
      marginBottom: '10px !important',
      marginLeft: '10px !important',
    },
  });
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const pages = [
    {
      title: 'Home',
      isDisplay: true,
      redirect: '/',
    },
    {
      title: 'Influencer',
      isDisplay: true,
      redirect: '/search-channel',
    },
    {
      title: 'How It Work',
      isDisplay: true,
      redirect: '/',
    },
    {
      title: 'Inquiry',
      isDisplay: token.accessToken ? true : false,
      redirect: '/inquiry',
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const getProfileData = useCallback(async () => {
  //   dispatch(setLoading(true));
  //   try {
  //     const res = await dispatch(fetchProfileDetails());
  //     const data = res?.payload;
  //     dispatch(setProfileState(data));
  //     dispatch(setLoading(false));
  //   } catch (error: any) {
  //     ToastMessage({
  //       type: 'error',
  //       message: error.error,
  //     });
  //     dispatch(setLoading(false));
  //   }
  //   setTimeout(() => {
  //     dispatch(setLoading(false));
  //   }, 100);
  // }, []);

  // useEffect(() => {
  //   if (token.accessToken && Object.keys(profileData).length === 0) {
  //     getProfileData();
  //   }
  // }, [token, param.pathname]);

  document.body.addEventListener(
    'click',
    (e: any) => {
      if (e.target.closest('#composition-menu-profile')) {
        setOpenNotification(false);
      } else if (e.target.closest('#notification')) {
        setOpen(false);
      } else {
        setOpenNotification(false);
        setOpen(false);
      }
    },
    true
  );

  const url = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);

  const scrollToHowDoesItWork = () => {
    const howDoesItWorkSection = document.getElementById('how-does-it-work');

    if (howDoesItWorkSection && appState === 'HowItWorks') {
      let scrollTopHeight = howDoesItWorkSection?.offsetTop || 0;
      howDoesItWorkSection.scrollIntoView({ behavior: 'smooth' });
      document.documentElement.scrollTop = scrollTopHeight - 120;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (appState === 'HowItWorks') {
        scrollToHowDoesItWork();
      }
    }, 100);
  }, [appState, param.pathname]);

  useEffect(() => {
    return () => {
      setOpen((prev) => !prev);
    };
  }, []);

  return (
    <>
      <AppBar
        position='sticky'
        sx={{
          backgroundColor: colors.dark[800],
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.dark[100]}`,
          zIndex: 999,
        }}
      >
        <CustomContainer maxWidth={url === 'youtubee-channel' ? '100% !important' : '1352px !important'}>
          <Toolbar disableGutters sx={{ minHeight: '46px !important' }}>
            <Box
              className='cursor-pointer'
              onClick={() => {
                navigate('/');
              }}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Logo />
            </Box>
            <Box sx={{ zIndex: '9999 !important', flexGrow: 1, display: { xs: 'flex', md: 'none' }, '& .MuiButtonBase-root': { p: 0 }, }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                // keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages?.map(
                  (page: any, i: any) =>
                    page?.isDisplay && (
                      <MenuItem key={i} >
                        <Link
                          to={page?.redirect}
                          style={{ textDecoration: 'none' }}
                          onClick={() => {
                            if (page?.title === 'How It Work') {
                              scrollToHowDoesItWork();
                              navigate('/')
                            }
                            handleCloseNavMenu();
                          }}
                        >
                          <Typography
                            textAlign='center'
                            lineHeight={{ md: '30px', xs: '1.5' }}
                            fontSize={{ md: '20px', sm: '16px', xs: '14px' }}
                          >
                            {page?.title}
                          </Typography>
                        </Link>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                className='cursor-pointer'
                onClick={async () => {
                  await dispatch(setAppState('home'));
                  updatedAppState = 'home';

                  if (updatedAppState === 'home') {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                    navigate('/');
                  }
                }}
                variant='body2'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  color: appState === 'home' ? colors.basics.primary : colors.basics.white,
                  mr: '60px',
                }}
              >
                Home
              </Typography>

              <Typography
                className='cursor-pointer'
                onClick={async () => {
                  await dispatch(setAppState('influencer'));
                  updatedAppState = 'influencer';

                  if (updatedAppState === 'influencer') {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                    navigate('/search-channel');
                  }
                }}
                variant='body2'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  color: appState === 'influencer' ? colors.basics.primary : colors.basics.white,
                  mr: '60px',
                }}
              >
                Influencer
              </Typography>

              <Typography
                onClick={() => {
                  dispatch(setAppState('HowItWorks'));
                  const updatedAppState1 = 'HowItWorks';
                  if (updatedAppState1 === 'HowItWorks') {
                    scrollToHowDoesItWork();
                    navigate('/');
                  }
                }}
                className='cursor-pointer'
                variant='body2'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  color: appState === 'HowItWorks' ? colors.basics.primary : colors.basics.white,
                  mr: '60px',
                }}
              >
                How it work
              </Typography>
              {token.accessToken && (
                <Typography
                  onClick={() => {
                    dispatch(setAppState('inquiry'));
                    navigate('/inquiry');
                  }}
                  className='cursor-pointer'
                  variant='body2'
                  sx={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: appState === 'inquiry' ? colors.basics.primary : colors.basics.white,
                  }}
                >
                  Inquiries
                </Typography>
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {token.accessToken ? (
                <Box
                  sx={{
                    '& svg': {
                      color: colors.gray[500],
                    },
                    '& .MuiButton-root': {
                      border: `1px solid ${colors.dark[100]}`,
                      maxHeight: '46px',
                      '&:hover': {
                        background: colors.dark[100],
                      },
                    },
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenNotification(true);
                    }}
                    variant='text'
                    sx={{
                      background: colors.dark[100],
                      padding: '13px 15px',
                      mr: '15px',
                    }}
                  >
                    <Box sx={{ position: 'relative', display: 'flex' }}>
                      {Object.keys(notificationDetails).length > 0 &&
                        notificationDetails?.unread_count !== 0 && (
                          <Box
                            sx={{
                              width: '9px',
                              height: '9px',
                              borderRadius: '15px',
                              bgcolor: 'red',
                              position: 'absolute',
                              mt: '-4px',
                              ml: '9px',
                              border: `2px solid ${colors.dark[600]}`,
                            }}
                          ></Box>
                        )}
                      <NotificationIcon />
                    </Box>
                  </Button>
                  <Button
                    variant='text'
                    className={classes.headerMargin}
                    sx={{
                      background: colors.dark[100],
                      maxHeight: 'none',
                      p: '10px 12px',
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    ref={anchorRef}
                    id='composition-button'
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup={open ? 'true' : 'false'}
                    onClick={(event) => {
                      setOpen((prevOpen) => !prevOpen);
                    }}
                  >
                    {profileData?.name && initialLetters(profileData?.name)}
                  </Button>
                </Box>
              ) : (
                !token.accessToken && (
                  <Box
                    className={classes.mTop}
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      marginBottom: { sm: '-5px', xs: '-8px' },
                      '& .MuiButtonBase-root': { height: { sm: '50px !important', xs: '30px !important' } },
                    }}
                  >
                    <Button
                      className={classes.marginButton}
                      onClick={() => {
                        navigate('/sign-in');
                      }}
                      sx={{
                        padding: { sm: '0px 10px', xs: '0px 0px' },
                        // mr: '36px',
                        color: colors.basics.primary,
                        background: 'transparent !important',
                        width: '100%',
                        lineHeight: '150%',
                        maxWidth: '220px',
                        textTransform: 'capitalize',
                        textAlign: 'right',
                        transition: 'all .5s ease',
                        fontSize: { md: '18px', sm: '16px', xs: '14px' },
                        fontWeight: '400',
                        '&:hover': {
                          color: colors.basics.white,
                        },
                      }}
                    >
                      Join as an influencer
                    </Button>
                    <Button
                      className={classes.marginButton2}
                      variant='contained'
                      sx={{ p: '0 33px', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}
                      onClick={() => {
                        dispatch(
                          setDialogState({
                            loginDialog: true,
                            registerDialog: false,
                            modal: true,
                            otpDialog: { otpDialog: false, fromPageName: '' },
                            privacyDialog: false,
                            resetPassword: false,
                            successReset: false,
                            termsDialog: false,
                            forgotPassword: false,
                          })
                        );
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                )
              )}
            </Box>
          </Toolbar>
        </CustomContainer>
        <NotificationModal
          open={openNotification}
          setOpen={setOpenNotification}
          settingNavigatePath={'/settings'}
        />
      </AppBar>
      {open === true && anchorRef?.current !== null && (
        <ProfileSettingOption open={open} setOpen={setOpen} anchorRef={anchorRef} />
      )}
    </>
  );
}
export default ResponsiveAppBar;
