
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filtervalue: false,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterValue: (state, action) => {
      state.filtervalue = action.payload;
    },
  },
});

export const { setFilterValue } = filterSlice.actions;

export default filterSlice.reducer;
