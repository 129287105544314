import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchCategoryDetails: any = createAsyncThunk(
  'categoryDetails/fetchCategoryDetails',
  async () => {
    const response = await API.get(`/category-list`);
    return response.data?.data;
  }
);

const initialState = {
  categoryData: [],
  status: '',
};

const categorySlice = createSlice({
  name: 'categoryDetails',
  initialState,
  reducers: {
    setCategoryState: (state, { payload }) => {
      state.categoryData = payload.payload;
    },
  },
});

export const { setCategoryState } = categorySlice.actions;
export default categorySlice.reducer;
