import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../styles/color';
import SignUpImg from '../assets/images/SignUpImg.png';
import TextInput from '../components/TextInput';
import { ReactComponent as EyeOff } from '../assets/icons/EyeOff.svg';
import { UnCheckedIcon, CheckedIcon } from '../components/CheckBox';
import { ReactComponent as EyeOn } from '../assets/icons/EyeOn.svg';
import { Link, useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import Axios from 'axios';
import { baseURL } from '../api/url';
import { setLoading } from '../redux/features/loader/loaderSlice';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ToastMessage } from '../helpers/ToastMessage';
interface FormErrors {
  name?: string;
  email?: string;
  password?: string;
  contact?: string;
  terms?: boolean;
}
export const SignUp = () => {
  //! Hooks
  const [showPassword, setShowPassword] = React.useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({
    role: '2',
    name: '',
    business_name: '',
    email: '',
    contact: '',
    password: '',
    terms: false,
  });
  //! Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Full name is required')
      .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, 'Name can only contain letters.'),
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    contact: yup
      .string()
      .matches(/^\d{10}$/, 'Contact number is invalid; it must have 10 digits')
      .required('Contact number is required'),
    terms: yup.boolean().isTrue('Please accept Terms and conditions'),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  }, []);

  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;

    name === 'terms'
      ? setFormData({
          ...formData,
          [name]: evt.target.checked,
        })
      : setFormData({
          ...formData,
          [name]: value,
        });
  };

  const SignUpAPICall = async () => {
    setFormErrors({});
    dispatch(setLoading(true));
    const response = await Axios.post(`${baseURL}/signup`, {
      ...formData,
    });

    const signUpData = response?.data;
    if (signUpData?.status) {
      ToastMessage({
        type: 'success',
        message: signUpData.message,
      });
      navigate('/otp-verification', {
        state: { formData, fromPage: 'sign-up' },
      });
    } else {
      ToastMessage({ type: 'error', message: signUpData.message });
    }
    dispatch(setLoading(false));
  };

  const handleSignUp = (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        SignUpAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };

  return (
    <Box>
      <CustomContainer>
        <form onSubmit={handleSignUp} method='post'>
          <Box
            sx={{
              background: colors.dark[800],
              borderRadius: '24px',
              color: 'white',
              p: { md: '60px 70px', xs: '10px 10px' },
              display: { md: 'flex', xs: 'block' },
            }}
          >
            <Grid
              container
              sx={{
                display: { md: 'flex', xs: 'block' },
                '&.MuiGrid-root ': { ml: '0' },
                '& .grid-root': {
                  paddingLeft: { md: '60px', xs: '0' },
                },
              }}
            >
              <Grid item md={6} xs={12}>
                {' '}
                <CardMedia
                  component='img'
                  image={SignUpImg}
                  alt='Live from space album cover'
                  sx={{ borderRadius: '20px' }}
                />
                <Box mt={'25px'}>
                  <Typography variant='body2' color={colors.gray[300]} fontWeight='400'>
                    Ready to Join the Movement? Create your influencer account today and embark on a journey
                    of creativity and influence. Let's amplify your voice and passion together!
                  </Typography>
                </Box>
              </Grid>{' '}
              <Grid
                className='grid-root'
                item
                md={6}
                xs={12}
                sx={{
                  '& .MuiGrid-item': {
                    background: 'red',
                    paddingLeft: '0',
                    paddingRight: '60px',
                  },
                }}
              >
                <Box sx={{ p: { md: '0 10px', xs: '0 0' }, width: '100%' }}>
                  <Box>
                    <Typography variant='h1'>Create An Account</Typography>
                  </Box>
                  <Box sx={{ mt: '20px' }}>
                    <TextInput
                      title='Full Name'
                      name='name'
                      onChange={handleChange}
                      placeholder='Enter your full name'
                      value={formData.name}
                    />
                    {formErrors.name && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.name}
                      </div>
                    )}
                  </Box>
                  <Box sx={{ mt: '17px' }}>
                    <TextInput
                      // autoComplete='name'

                      title='Email'
                      name='email'
                      onChange={handleChange}
                      placeholder='Enter your email address'
                      value={formData.email}
                    />
                    {formErrors.email && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.email}
                      </div>
                    )}
                  </Box>
                  <Box sx={{ mt: '17px' }}>
                    <TextInput
                      name='contact'
                      title='Contact Number'
                      placeholder='Enter your contact number'
                      onChange={handleChange}
                      value={formData.contact}
                    />
                    {formErrors.contact && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.contact}
                      </div>
                    )}
                  </Box>
                  <Box sx={{ mt: '17px' }}>
                    <TextInput
                      title='Password'
                      onChange={handleChange}
                      name={'password'}
                      value={formData.password}
                      placeholder='Enter your password'
                      type={showPassword ? 'text' : 'password'}
                      EndIcon={
                        <IconButton onClick={() => setShowPassword((show) => !show)}>
                          {showPassword ? <EyeOn /> : <EyeOff />}
                        </IconButton>
                      }
                    />
                    {formErrors.password && (
                      <div
                        style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                      >
                        {formErrors.password}
                      </div>
                    )}
                  </Box>

                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={'20px'}>
                    <Box>
                      <FormControlLabel
                        sx={{
                          '& .MuiTypography-body1': {
                            color: colors.gray[300],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{
                              '& span': {
                                width: { xs: '17px', sm: '20px !important' },
                                height: { xs: '17px', sm: '20px !important' },
                              },
                              '& span::before': {
                                width: { xs: '17px !important', sm: '20px !important' },
                                height: { xs: '17px !important', sm: '20px !important' },
                              },
                            }}
                            name='terms'
                            checkedIcon={<CheckedIcon />}
                            icon={<UnCheckedIcon />}
                            disableRipple
                            onChange={handleChange}
                          />
                        }
                        label={
                          <label>
                            I have read and agreed to the{' '}
                            <Link
                              to='/terms-conditions'
                              style={{
                                color: colors.basics.primary,
                                textDecoration: 'none',
                              }}
                            >
                              Terms & Conditions
                            </Link>{' '}
                            and{' '}
                            <Link
                              to='/privacy-policy'
                              style={{
                                color: colors.basics.primary,
                                textDecoration: 'none',
                              }}
                            >
                              Privacy policy
                            </Link>{' '}
                          </label>
                        }
                      />{' '}
                      {formErrors.terms && (
                        <div
                          style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}
                        >
                          {formErrors.terms}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box pt={'35px'}>
                    <Button variant='contained' sx={{ width: '100%' }} type='submit'>
                      Sign Up
                    </Button>
                  </Box>

                  <Box mt={{ md: '40px', xs: '20px' }} mb={{ md: 0, xs: '20px' }} textAlign='center'>
                    <Typography
                      variant='body2'
                      fontWeight='400'
                      lineHeight={'180%'}
                      sx={{ color: colors.gray[300] }}
                    >
                      Already have an account?{' '}
                      <Link
                        to='/sign-in'
                        style={{
                          color: colors.basics.primary,
                          textDecoration: 'none',
                        }}
                      >
                        login
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </CustomContainer>
    </Box>
  );
};
