import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

interface messageDetail {
    inquiryMessageList: Record<any, any>
}

const initialState: messageDetail = {
    inquiryMessageList: {},
};

export const fetchInquiryMessageList: any = createAsyncThunk(
    'messageList/fetchlist',
    async (obj) => {
        const responseUpdate = await API.post(`/inquiry-message-list`, obj);
        return responseUpdate.data;
    }
);

const inquiryMessageList = createSlice({
    name: 'messageList',
    initialState,
    reducers: {
        setInquiryMessageList: (state, { payload }) => {
            state.inquiryMessageList = payload;
        },
    },
});

export const { setInquiryMessageList } = inquiryMessageList.actions;

export default inquiryMessageList.reducer;