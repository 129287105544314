
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API } from '../../../api/services';
export const fetchChannelDetails: any = createAsyncThunk('channelDetails/fetChchannelDetails', async (id: any) => {
  const response = await API.get(`/channel-details?channel_id=${id}`);
  return response?.data;
});
const initialState = {
  channelDetails: null
};

const channelDetailsSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannelDetailsState: (state, { payload }) => {
      state.channelDetails = payload;
    },
    cleanChannelDetailsState: (state) => {
      state.channelDetails = null
    }
  },
});
export const { setChannelDetailsState, cleanChannelDetailsState } = channelDetailsSlice.actions;
export default channelDetailsSlice.reducer;
