import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { colors } from '../styles/color';
import TextInput from './TextInput';
import SearchIcon from '@mui/icons-material/Search';

interface searchInputProps {
  onChange?: (value: any) => void;
  onSearchButtonClick?: () => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  btnText?: string;
  handleKeyDown?: (value: any) => void;
}

export const SearchInput = ({
  onChange,
  handleKeyDown,
  onSearchButtonClick,
  placeholder,
  value,
  btnText,
  defaultValue,
}: searchInputProps) => {
  return (
    <Box display='flex' alignItems='center' sx={{ width: '100%' }} className='searchBox'>
      <Box width='100%'>
        <TextInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          StartIcon={
            <SearchIcon
              sx={{
                color: colors.basics.primary,
                width: '25px',
                height: '25px',
              }}
            />
          }
          textFieldStyle={{
            backgroundColor: `${colors.dark[600]}!important`,
            width: '100% !important',
            borderRadius: '10px 0 0 10px !important',
            '& .MuiInputBase-input': {
              paddingLeft: '10px !important',
            },
            '& .MuiOutlinedInput-root': {
              pl: '0 !important',
              pr: '0 !important',
            },
          }}
        />
      </Box>
      <Button
        variant='contained'
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeftWidth: 0,
          paddingX: '30px',
          marginBottom: '1px',
        }}
        onClick={onSearchButtonClick}
      >
        <Typography variant='body2'>{btnText ? btnText : 'Subscribe'}</Typography>
      </Button>
    </Box>
  );
};
