import React, { useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { colors } from '../../styles/color';
import { Box, ListItemIcon, Typography } from '@mui/material';
import { LogOut, ProfileIcon, SettingIcon } from '../../assets/icons';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../redux/features';

import { API } from '../../api/services';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogState } from '../../redux/features';
import { ApplicationStateType } from '../../redux/store';
type Props = {
  anchorRef: React.RefObject<HTMLButtonElement>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileSettingOption = ({ anchorRef, open, setOpen }: Props) => {
  const [_, __, removeToken] = useCookies(['accessToken', 'refreshToken', 'googleToken', 'role']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appState } = useSelector((state: ApplicationStateType) => state.appState);
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
  };
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open
  }, [open, anchorRef]);

  return (
    <>
      <Stack id='profile-box' direction='row' spacing={2}>
        <Box>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-end'
            transition
            sx={{
              width: '100%',
              maxWidth: '226px',
              zIndex: open && anchorRef.current ? '9999' : '-1 !important',
              borderRadius: '12px',
              '& .MuiMenuItem-root': {
                backgroundColor: `${colors.dark[600]}!important`,
              },
            }}
            style={{ marginTop: '100px' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'left bottom',
                }}
              >
                <Paper
                  sx={{
                    marginTop: '10px',
                    backgroundColor: `${colors.dark[600]}!important`,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      // autoFocusItem={open}
                      id='composition-menu-profile'
                      aria-labelledby='composition-button'
                    >
                      <MenuItem
                        onClick={(e) => {
                          setOpen(false);
                          navigate('/profile');
                        }}
                        sx={{
                          p: '12px 20px',

                          '& .MuiListItemIcon-root svg': {
                            color: colors.gray[500],
                            strokeWidth: 1.5,
                            transition: 'all .5s ease',
                            '& path': {
                              stroke: appState == 'profile' ? colors.basics.primary : '',
                            },
                            '& circle': {
                              stroke: appState == 'profile' ? colors.basics.primary : '',
                            },
                          },
                          '&:hover svg, &:hover p': {
                            color: colors.basics.primary,
                          },
                        }}
                      >
                        <ListItemIcon>
                          <ProfileIcon />
                        </ListItemIcon>
                        <Typography
                          variant='body2'
                          sx={{
                            color: appState == 'profile' ? colors.basics.primary : colors.gray[500],
                            transition: 'all .5s ease',
                          }}
                        >
                          Profile
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          navigate('/settings');
                        }}
                        sx={{
                          p: '12px 20px',

                          '& .MuiListItemIcon-root svg': {
                            color: colors.gray[500],
                            strokeWidth: 1.5,
                            transition: 'all .5s ease',
                            '& path ': {
                              stroke: appState == 'settings' ? colors.basics.primary : '',
                            },
                          },
                          '&:hover svg, &:hover p': {
                            color: colors.basics.primary,
                          },
                        }}
                      >
                        <ListItemIcon sx={{ color: colors.gray[500], strokeWidth: 1.5 }}>
                          {' '}
                          <SettingIcon />{' '}
                        </ListItemIcon>
                        <Typography
                          variant='body2'
                          sx={{
                            color: appState == 'settings' ? colors.basics.primary : colors.gray[500],
                            transition: 'all .5s ease',
                          }}
                        >
                          Settings{' '}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          p: '12px 20px',

                          cursor: 'pointer',
                          '& .MuiListItemIcon-root svg': {
                            color: colors.gray[500],
                            strokeWidth: 1.5,
                            transition: 'all .5s ease',
                          },
                          '&:hover svg, &:hover p': {
                            color: colors.basics.danger,
                          },
                        }}
                        onClick={async () => {
                          dispatch(
                            setDialogState({
                              loginDialog: false,
                              registerDialog: false,
                              modal: true,
                              otpDialog: { otpDialog: false, fromPageName: '' },
                              privacyDialog: false,
                              resetPassword: false,
                              successReset: false,
                              termsDialog: false,
                              forgotPassword: false,
                              sendInquiry: false,
                              addLink: false,
                              newPassword: false,
                              logout: true,
                            })
                          );
                          setOpen(false);
                        }}
                      >
                        {' '}
                        <ListItemIcon sx={{ color: colors.gray[500], strokeWidth: 1.5 }}>
                          <LogOut />{' '}
                        </ListItemIcon>
                        <Typography
                          variant='body2'
                          sx={{ color: colors.gray[500], transition: 'all .5s ease' }}
                        >
                          Log Out{' '}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Stack>
    </>
  );
};

export default ProfileSettingOption;
