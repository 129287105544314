import { createSlice } from '@reduxjs/toolkit';

type initialProp = {
  signupData: {
    role: string;
    name: string;
    business_name: string;
    email: string;
    contact: string;
    password: string;
    terms: boolean;
  };
};

const initialState:initialProp = {
  signupData: {
    role: '',
    name: '',
    business_name: '',
    email: '',
    contact: '',
    password: '',
    terms:false,
  },
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setSignupState: (state, { payload }) => {
      state.signupData = payload;
    },
  },
});

export const { setSignupState } = signupSlice.actions;
export default signupSlice.reducer;
