import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../components/TextInput';
import { TextAreaInput } from '../../components/TextAreaInput';
import { colors } from '../../styles/color';
import Axios from 'axios';
import { baseURL } from '../../api/url';
import { ToastMessage } from '../../helpers/ToastMessage';
import * as yup from 'yup';
import { setLoading } from '../../redux/features';
import { useDispatch } from 'react-redux';
interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  contact?: string;
  message?: string;
}
export const ContactUs = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    message: '',
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'First Name must be at least 2 characters')
      .max(15, 'First Name must be at most 15 characters')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'First Name can only contain letters.'
      )
      .required('First Name is required'),
    lastName: yup
      .string()
      .min(2, 'Last Name must be at least 2 characters')
      .max(15, 'Last Name must be at most 15 characters')
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Last Name can only contain letters.'
      )
      .required('Last Name is required'),
    email: yup
      .string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is invalid')
      .required('Email is required'),

    contact: yup
      .string()
      .matches(/^\d{10}$/, 'Contact number not valid must be 10 digits')
      .required('Contact required'),
    message: yup.string().min(3, 'Message must be at least 5 characters').required('Message required'),
  });
  const contactUsAPICall = async () => {
    try {
      const body = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.contact,
        message: formData.message,
      };

      dispatch(setLoading(true));
      const response = await Axios.post(`${baseURL}/contact-us`, body);

      if (response.data.status) {
        ToastMessage({
          type: 'success',
          message: response?.data.message,
        });
        setErrors({});
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          contact: '',
          message: '',
        });
      } else {
        ToastMessage({
          type: 'error',
          message: response.data.message,
        });
      }

      dispatch(setLoading(false));
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        contactUsAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      });
  };
  const sections = [
    {
      title: 'General Inquiries : ',
      content:
        "For general questions or information about Quick Fluence, please contact us at contact@quickfluence.com. We'll get back to you as soon as possible.",
    },
    {
      title: 'Technical Support : ',
      content:
        "Encountering a technical issue? Our support team is ready to help. Drop us an email at contact@quickfluence.com, and we'll work diligently to resolve any technical challenges you may be facing.",
    },
    {
      title: 'Partnerships and Collaboration : ',
      content:
        "Interested in collaborating with Quick Fluence? We're always open to new partnerships and opportunities. Reach out to us at contact@quickfluence.com, and let's explore how we can work together.",
    },
    {
      title: 'Media Inquiries : ',
      content:
        "For media inquiries, interviews, or press-related matters, please contact our media relations team at contact@quickfluence.com. We're happy to provide information and assist with your media needs.",
    },
    {
      title: 'Feedback and Suggestions : ',
      content:
        'Your feedback is invaluable in helping us improve. If you have suggestions for enhancing your experience on Quick Fluence or ideas to share, please email us at contact@quickfluence.com. We appreciate your input.',
    },
    {
      title: 'Connect with Us on Social Media : ',
      content:
        'Stay updated on the latest news, features, and insights by following us on social media. Connect with us on twitter, facebook, instagram and linkedin and join our community of brand owners, influencers, and enthusiasts.',
    },
    {
      title: '',
      content:
        "We're dedicated to providing you with exceptional service and meaningful connections. Your communication matters, and we're excited to engage with you. Thank you for choosing Quick Fluence.",
    },
  ];
  return (
    <Box>
      <Box>
        <Typography>
          We're here to assist you! If you have any questions, feedback, or inquiries, please don't hesitate
          to reach out to us. Your thoughts are important, and we're eager to connect with you.{' '}
        </Typography>
      </Box>
      <Box mt='20px'>
        <Box sx={{ maxWidth: '500px', width: '100%', margin: 'auto' }}>
          <Box>
            <TextInput
              title='First Name'
              placeholder='Enter first name'
              name='firstName'
              value={formData.firstName}
              onChange={handleInputChange}
            />
            {errors.firstName && (
              <div
                style={{
                  color: '#d32f2f',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.firstName}
              </div>
            )}
          </Box>
          <Box mt='10px'>
            <TextInput
              title='Last Name'
              placeholder='Enter last name'
              name='lastName'
              value={formData.lastName}
              onChange={handleInputChange}
            />
            {errors.lastName && (
              <div
                style={{
                  color: '#d32f2f',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.lastName}
              </div>
            )}
          </Box>
          <Box mt='10px'>
            <TextInput
              type='email'
              title='Email'
              placeholder='Enter email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <div
                style={{
                  color: '#d32f2f',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.email}
              </div>
            )}
          </Box>
          <Box mt='10px'>
            <TextInput
              title='Phone'
              placeholder='Enter phone number'
              name='contact'
              value={formData.contact}
              onChange={handleInputChange}
            />
            {errors.contact && (
              <div
                style={{
                  color: '#d32f2f',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.contact}
              </div>
            )}
          </Box>
          <Box mt='10px'>
            <TextAreaInput
              title='Message'
              placeholder='Enter message'
              name='message'
              value={formData.message}
              onChange={handleInputChange}
            />
            {errors.message && (
              <div
                style={{
                  color: '#d32f2f',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.message}
              </div>
            )}
          </Box>
        </Box>
        <Box
          mt='20px'
          sx={{
            maxWidth: '500px',
            width: '100%',
            margin: 'auto',
            justifyContent: 'center',
            display: 'flex',
            mt: '10px !important',
          }}
        >
          <Button sx={{ width: '100%', maxWidth: '200px' }} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
      {sections.map((section, index) => (
        <Box key={index} mt='20px'>
          <Typography sx={{ color: `${colors.gray[300]} !important` }}>
            <strong style={{ color: colors.basics.white }}>{section.title}</strong>
            {section.content}
          </Typography>
        </Box>
      ))}
      <Box mt='20px'>
        <Typography sx={{ color: `${colors.gray[300]} !important` }}>
          <strong style={{ color: colors.basics.white }}>Email : </strong>
          contact@quickfluence.com
        </Typography>
      </Box>
      <Box mt='20px' mb='20px'>
        <Typography>We look forward to hearing from you!</Typography>
      </Box>
    </Box>
  );
};
