import { ListItemButton, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationStateType } from '../../redux/store';
import { colors } from '../../styles/color';
import { setAppState } from '../../redux/features/appStateSlice';
import { setDrawerState } from '../../redux/features/drawer/drawerSlice';
import { useEffect, useState } from 'react';

type Props = {
  item: any;
};

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: ApplicationStateType) => state.appState);
  const dispatch = useDispatch();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  const [drawervalue, setDrawerValue] = useState({});

  useEffect(() => {
    setDrawerValue(drawerState);
  }, [drawerState]);
  return item.sidebarProps && item.path && item.flag === true ? (
    <Box
      onClick={async () => {
        await dispatch(setAppState(item.state));
        await dispatch(setDrawerState({ ...drawervalue, left: false }));
      }}
      pl={'10px'}
      pr={'10px'}
      pt={'5px'}
      pb={'5px'}
    >
      <ListItemButton
        component={Link}
        to={item.path}
        sx={{
          backgroundColor: appState === item.state ? colors.dark[600] : 'unset',
          borderRadius: '6px',
          paddingY: '8px',
          paddingX: '14px',
          color:
            (appState === item.state && (item.color ? 'white' : colors.basics.primary)) || colors.gray[300],
        }}
      >
        <ListItemIcon
          sx={{
            color: colors.gray[300],
            p: 0,
            '& svg': {
              strokeWidth: '1.5',
              color:
                appState === item.state && item.color === true
                  ? 'white'
                  : appState === item.state && item.color === false
                  ? colors.basics.primary
                  : colors.gray[300],
            },
            '&.MuiListItemIcon-root ': {
              pr: '10px',
              minWidth: '0',
            },
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>

        {item.sidebarProps.displayText}
      </ListItemButton>
    </Box>
  ) : null;
};

export default SidebarItem;
