import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { colors } from '../../styles/color';
import { ApplicationStateType } from '../../redux/store';
import { useAppSelector } from '../../hooks/useAppReducer';

type Props = {
  children: JSX.Element;
};

function Modal({ children }: Props) {
  const {
    dialog: { registerDialog },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { dialog } = useSelector((state: any) => state.dialog);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialog.modal}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1200px !important',
          backgroundColor: colors.dark[800],
          height: 'fit-content',
          borderRadius: '24px',
          width: '100%',
          margin: { md: '32px', xs: '15px' },
          padding: '15px !important',
        },
        '& .MuiDialog-container': {
          marginTop: registerDialog ? 0 : '96px',
          height: registerDialog ? '100vh' : 'calc(100vh - 96px)',
          alignItems: 'unset',
        },
      }}
    >
      {children}
    </Dialog>
  );
}

export default Modal;
