import axios from 'axios';
import { onRequest, onRequestError, onResponse, onResponseError } from './clientHelper';
import { baseURL } from '../url';
import { getCookie } from '../../utils';

export function setupInterceptorsTo(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const instance = setupInterceptorsTo(
  axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
);

instance.interceptors.request.use(async (config) => {
  const token = getCookie('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  // config.headers["Strict-Transport-Security"] = "max-age=31536000; includeSubDomains; preload";
  // config.headers["X-Frame-Options"] = "SAMEORIGIN";
  // config.headers["X-Content-Type-Options"] = "nosniff";
  // config.headers["Content-Security-Policy"] = "default-src 'none'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self'; frame-ancestors 'self'";
  return config;
})

export { instance };
