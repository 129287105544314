import { AppBar, Button } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from '../../styles/color';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerState } from '../../redux/features/drawer/drawerSlice';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
interface customTopbarProps {
  children: JSX.Element;
}
const Topbar = ({ children }: customTopbarProps) => {
  const [direction, setDirection] = useState('');
  const dispatch = useDispatch();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  return (
    <AppBar
      sx={{
        width: '100%',
        height: '100px',
        backgroundColor: colors.dark[800],
        boxShadow: '0px 4px 60px rgba(52, 52, 52, 0.5)',
        color: colors.basics.primary,
        display: 'flex',
        zIndex: 999,
        '& .MuiPaper-root': {
          pr: '0px',
        },
      }}
    >
      <Box display='flex' alignItems='center' height='100%' p={'0px 16px'}>
        <Box display={'flex'}>
          <Box display={{ xl: 'none', xs: 'flex' }} alignItems='center' width={`calc(100% - 90%`}>
            <Button
              variant='text'
              onClick={() => setDirection('left')}
              color='inherit'
              disableRipple={true}
              sx={{
                p: '10px 0',
                justifyContent: 'flex-end',
                display: 'flex',
                alignItems: 'center',
                '& .MuiButtonBase-root': {
                  p: '10px 0',
                  background: 'red',
                },
              }}
            >
              {!drawerState?.left ? <MenuIcon /> : <MenuIcon />}
            </Button>
          </Box>
        </Box>
        {children}
      </Box>
    </AppBar>
  );
};

export default Topbar;
