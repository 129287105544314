import { configureStore } from '@reduxjs/toolkit';
import drawerReducer from './features/drawer/drawerSlice';
import appStateSlice from './features/appStateSlice';
import loginSlice from './features/login/loginSlice';
import signupSlice from './features/signup/signupSlice';
import profileSlice from './features/profile/profileSlice';
import profileEditSlice from './features/profileEdit/profileEditSlice';
import countrySlice from './features/country/countrySlice';
import channelCategorySlice from './features/channelCategory/channelCategorySlice';
import channelAudioSlice from './features/channelAudioList/channelAudioSlice';
import staticSlice from './features/staticPage/staticSlice';
import loaderSlice from './features/loader/loaderSlice';
import channelSuggestionListSlice from './features/channelSuggestionList/channelSuggestionListSlice';
import channelListSlice from './features/channelList/channelListSlice';
import channelDetailsSlice from './features/channelDetails/channelDetailsSlice';
import addChannelSlice from './features/addChannel/addChannelSlice';
import dialogSlice from './features/dialog/dialogSlice';
import dashboardSlice from './features/dashboard/dashboardSlice';
import getTopInfluencerList from './features/getTopInfluencerList/getTopInfluencerList';
import getSingleChannelDetails from './features/getChannelDetails/getChannelDetails';
import getInquiry from './features/getInquiry/getInquiry';
import notificationDetails from './features/notificationDetails/notificationDetails';
import inquiryMessageList from './features/inquiryMessageList/inquiryMessageList';
import staticDataSlice from '../../src/redux/features/storeFilterSearchInfluencer/storeFilterSearchInfluencer';
import contentCategorySlice from './features/contentCategory/contentCategorySlice';
import filterReducer from './features/filterSlice';

export const store = configureStore({
  reducer: {
    filterData: staticDataSlice,
    inquryMessageList: inquiryMessageList,
    notification: notificationDetails,
    loading: loaderSlice,
    getInquiry: getInquiry,
    singleChannelDetails: getSingleChannelDetails,
    drawer: drawerReducer,
    appState: appStateSlice,
    login: loginSlice,
    signup: signupSlice,
    profile: profileSlice,
    profileEdit: profileEditSlice,
    country: countrySlice,
    category: channelCategorySlice,
    audio: channelAudioSlice,
    staticData: staticSlice,
    loader: loaderSlice,
    channelSuggestionList: channelSuggestionListSlice,
    channelList: channelListSlice,
    channelDetails: channelDetailsSlice,
    addChannelData: addChannelSlice,
    dialog: dialogSlice,
    dahsboard: dashboardSlice,
    influencerList: getTopInfluencerList,
    contentCategorySlice: contentCategorySlice,
    filter: filterReducer,
  },
});

export type ApplicationDispatch = typeof store.dispatch;
export type ApplicationStateType = ReturnType<typeof store.getState>;
export default store;
