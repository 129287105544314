import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loginData: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginState: (state, { payload }) => {
      state.loginData = payload;
    },
  },
});

export const { setLoginState } = loginSlice.actions;
export default loginSlice.reducer;
