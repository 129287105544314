import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../styles/color';
import { Link, useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import { BackButton, EmailLogo } from '../assets/icons';

const EmailCheck = () => {
  //! Variables
  const navigate = useNavigate();

  return (
    <Box>
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px', xs: '20px 10px' },
            display: 'block',
          }}
        >
          <Box>
            <Button variant='text' onClick={() => navigate('/sign-in')}>
              <BackButton />
            </Button>
          </Box>
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '100px', xs: '50px' },
              mb: { sm: '60px', xs: '30px' },
              textAlign: 'center',
            }}
          >
            <Box>
              <EmailLogo />
            </Box>
            <Box mt={'39px'}>
              <Typography variant='h1'>Check Your Email</Typography>
              <Typography variant='body2' color={colors.gray[300]}>
                We sent a password reset link to
              </Typography>
              <Typography variant='body2' color={colors.basics.primary}>
                johnsmith@gmail.com
              </Typography>
            </Box>

            <Box pt={'35px'}>
              <Button variant='contained' sx={{ width: '100%' }} onClick={() => navigate('/new-password')}>
                Open email app
              </Button>
            </Box>
            <Box mt={'52px'}>
              <Typography
                variant='body2'
                fontWeight='400'
                lineHeight={'24px'}
                sx={{ color: colors.gray[300] }}
              >
                Didn’t receive an email?
                <Link
                  to=''
                  style={{ color: colors.basics.primary, marginLeft: '3px', textDecoration: 'none' }}
                >
                  Resend
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default EmailCheck;
