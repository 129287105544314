import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../styles/color';
import TextInput from '../TextInput';
import { EyeOn, EyeOff, CheckedIcon, UnCheckedIcon } from '../../assets/icons';
import { baseURL } from '../../api/url';
import { useDispatch } from 'react-redux';
import { resetDialogState, setDialogState, setLoading, setSignupState } from '../../redux/features';
import { CloseOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import axios from 'axios';
import { ToastMessage } from '../../helpers/ToastMessage';
import { setstatiDataState } from '../../redux/features/staticPage/staticSlice';
import { useCookies } from 'react-cookie';
import { API } from '../../api/services';

interface FormErrors {
  name?: string;
  email?: string;
  password?: string;
  contact?: string;
  business_name?: string;
  terms?: boolean;
}
const RegisterModal = () => {
  const [token] = useCookies();
  const dispatch = useDispatch();

  //! Hooks
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = useState({
    role: '3',
    name: '',
    business_name: '',
    email: '',
    contact: '',
    password: '',
    terms: false,
  });
  const [errors, setErrors] = useState<FormErrors>({});
  //! Variables
  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(25, 'Name must be at most 25 characters')
      .min(3, 'Name must be at least 3 characters')
      .required('Name is required'),
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    contact: yup.string().matches(/^\d{10}$/, 'Contact number is invalid, It must have 10 digits'),
    business_name: yup.string().min(3, 'Business name is required'),
    terms: yup.boolean().isTrue('Please accept Terms and conditions'),
  });

  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;
    name === 'terms'
      ? setFormData({
          ...formData,
          [name]: evt.target.checked,
        })
      : setFormData({
          ...formData,
          [name]: value,
        });
  };

  const SignUpAPICall = async () => {
    setErrors({});
    dispatch(setLoading(true));
    const response = await axios.post(`${baseURL}/signup`, formData);

    if (response?.data?.status) {
      ToastMessage({
        type: 'success',
        message: response.data.message,
      });
      dispatch(setLoading(false));
      dispatch(
        setDialogState({
          loginDialog: false,
          registerDialog: false,
          modal: true,
          otpDialog: { otpDialog: true, fromPageName: 'register' },
          privacyDialog: false,
          resetPassword: false,
          successReset: false,
          termsDialog: false,
          forgotPassword: false,
        })
      );
    } else {
      dispatch(setLoading(false));
      ToastMessage({
        type: 'error',
        message: response.data.message,
      });
    }
    dispatch(setSignupState({ ...formData }));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        SignUpAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      });
  };
  return (
    <Box sx={{ p: { md: '0 10px', xs: '0 0' } }}>
      <Box
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          right: { sm: '39px', xs: '20px' },
          top: { sm: '29px', xs: '20px' },
        }}
        onClick={() => {
          dispatch(resetDialogState());
        }}
      >
        <CloseOutlined
          sx={{
            color: 'white',
            width: '30px',
            height: '30px',
          }}
        />
      </Box>
      <Box sx={{ width: '100%', maxWidth: '449px', m: '8px auto' }}>
        <Box>
          <Box>
            <Typography variant='h1'>Business Register</Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: '20px' }}>
              <TextInput
                title='Business Name'
                name='business_name'
                onChange={handleChange}
                placeholder='Enter business name'
                value={formData.business_name}
              />
              {errors.business_name && (
                <div
                  style={{
                    color: '#d32f2f',
                    textAlign: 'left',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.business_name}
                </div>
              )}
            </Box>
            <Box sx={{ mt: '20px' }}>
              <TextInput
                title='Full Name'
                name='name'
                onChange={handleChange}
                placeholder='Enter your full name'
                value={formData.name}
              />
              {errors.name && (
                <div
                  style={{
                    color: '#d32f2f',
                    textAlign: 'left',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.name}
                </div>
              )}
            </Box>
            <Box sx={{ mt: '17px' }}>
              <TextInput
                title='Email'
                name='email'
                onChange={handleChange}
                placeholder='Enter your email address'
                value={formData.email}
              />
              {errors.email && (
                <div
                  style={{
                    color: '#d32f2f',
                    textAlign: 'left',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.email}
                </div>
              )}
            </Box>
            <Box sx={{ mt: '17px' }}>
              <TextInput
                title='Password'
                onChange={handleChange}
                name={'password'}
                placeholder='Enter your password'
                value={formData.password}
                type={showPassword ? 'text' : 'password'}
                EndIcon={
                  <IconButton onClick={() => setShowPassword((show) => !show)}>
                    {showPassword ? <EyeOn /> : <EyeOff />}
                  </IconButton>
                }
              />
              {errors.password && (
                <div
                  style={{
                    color: '#d32f2f',
                    textAlign: 'left',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.password}
                </div>
              )}
            </Box>
            <Box sx={{ mt: '17px' }}>
              <TextInput
                name='contact'
                title='Contact Number'
                placeholder='Enter your contact number'
                onChange={handleChange}
                value={formData.contact}
              />
              {errors.contact && (
                <div
                  style={{
                    color: '#d32f2f',
                    textAlign: 'left',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.contact}
                </div>
              )}
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={'20px'}>
              <Box>
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-body1': {
                      color: colors.gray[300],
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                    },
                    '& .MuiButtonBase-root': {
                      padding: '3px 9px',
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        alignSelf: 'start',
                        '& span': {
                          width: { xs: '17px', sm: '20px !important' },
                          height: { xs: '17px', sm: '20px !important' },
                        },
                        '& span::before': {
                          width: {
                            xs: '17px !important',
                            sm: '20px !important',
                          },
                          height: {
                            xs: '17px !important',
                            sm: '20px !important',
                          },
                        },
                      }}
                      name='terms'
                      checkedIcon={<CheckedIcon />}
                      icon={<UnCheckedIcon />}
                      disableRipple
                      onChange={handleChange}
                    />
                  }
                  label={
                    <label style={{ display: 'inline' }}>
                      I have read and agreed to the{' '}
                      <Button
                        variant='text'
                        sx={{
                          color: colors.basics.primary,
                          textDecoration: 'underline',
                          fontWeight: '500',
                          pl: '0 !important',
                          pr: '0 !important',
                        }}
                        onClick={async () => {
                          dispatch(
                            setDialogState({
                              loginDialog: false,
                              registerDialog: false,
                              modal: true,
                              otpDialog: { otpDialog: false, fromPageName: '' },
                              privacyDialog: false,
                              resetPassword: false,
                              successReset: false,
                              termsDialog: true,
                              forgotPassword: false,
                            })
                          );
                          const res = await API.get(`/static-page?type=3&page=terms`);
                          if (res?.error) {
                            ToastMessage({
                              type: 'error',
                              message: res.error.message,
                            });
                          }
                          dispatch(setstatiDataState(res.data));
                        }}
                      >
                        Terms & Conditions
                      </Button>{' '}
                      and{' '}
                      <Button
                        variant='text'
                        sx={{
                          color: colors.basics.primary,
                          textDecoration: 'underline',
                          fontWeight: '500',
                          pl: '0 !important',
                          pr: '0 !important',
                        }}
                        onClick={async () => {
                          dispatch(
                            setDialogState({
                              loginDialog: false,
                              registerDialog: false,
                              modal: true,
                              otpDialog: { otpDialog: false, fromPageName: '' },
                              resetPassword: false,
                              successReset: false,
                              termsDialog: false,
                              privacyDialog: true,
                              forgotPassword: false,
                            })
                          );
                          const res = await API.get(`/static-page?type=3&page=privacy`);
                          if (res?.error) {
                            ToastMessage({
                              type: 'error',
                              message: res.error.message,
                            });
                          }
                          dispatch(setstatiDataState(res.data));
                          // };
                        }}
                      >
                        Privacy policy
                      </Button>{' '}
                    </label>
                  }
                />
                {errors.terms && (
                  <div
                    style={{
                      color: '#d32f2f',
                      textAlign: 'left',
                      fontSize: '12px',
                      marginTop: '3px',
                    }}
                  >
                    {errors.terms}
                  </div>
                )}
              </Box>
            </Box>
            <Box pt={'35px'}>
              <Button
                variant='contained'
                sx={{ width: '100%' }}
                type='submit'
                // onClick={handleSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </form>
          <Box mt={{ md: '40px', xs: '20px' }} mb={{ md: 0, xs: '20px' }} textAlign='center'>
            <Typography
              variant='body2'
              fontWeight='400'
              lineHeight={'180%'}
              display={'inline'}
              sx={{ color: colors.gray[300] }}
            >
              Already have an account?{' '}
              <Typography
                sx={{
                  color: colors.basics.primary,
                  textDecoration: 'none',
                  display: 'inline',
                  cursor: 'pointer',
                }}
                variant='body2'
                onClick={() => {
                  dispatch(
                    setDialogState({
                      modal: true,
                      registerDialog: false,
                      loginDialog: true,
                      otpDialog: { otpDialog: false, fromPageName: '' },
                      privacyDialog: false,
                      resetPassword: false,
                      successReset: false,
                      termsDialog: false,
                      forgotPassword: false,
                    })
                  );
                }}
              >
                login
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RegisterModal;
