import { lazy } from 'react';
import CommanChat from '../components/common/CommanChat';

const YouTubeChannelDetails = lazy(() => import('../pages/you-tube-channel/YouTubeChannelDetails'));
const BusinessProtectedRoutes = lazy(() => import('./businessProtectedRoutes'));
const BusinessAndPublicProtectedRoutes = lazy(() => import('./businessAndPublicRoute'));
const SearchInfluencer = lazy(() => import('../pages/searchInfluencer/SearchInfluencer'));
const BusinessHome = lazy(() => import('../components/Business/BusinessHome'));
const BusinessInquiry = lazy(() => import('../pages/BusinessInquiry/BusinessInquiry'));
const BusinessProfile = lazy(() => import('../pages/BusinessProfile/businessProfile'));
const BusinessSetting = lazy(() => import('../pages/BusinessSettings/BusinessSetting'));
const BusinessChangePassword = lazy(() => import('../pages/BusinessChangePassword/BusinessChangePassword'));
const HelpCategarys = lazy(() => import('../pages/settings/HelpCategarys'));

export const BusinessRoutes: any = [
  {
    path: '/youtube-channel/:id',
    element: (
      <BusinessAndPublicProtectedRoutes>
        {' '}
        <YouTubeChannelDetails />{' '}
      </BusinessAndPublicProtectedRoutes>
    ),
  },
  {
    path: '/instagram/:id',
    element: (
      <BusinessAndPublicProtectedRoutes>
        {' '}
        <YouTubeChannelDetails />{' '}
      </BusinessAndPublicProtectedRoutes>
    ),
  },
  {
    path: '/',
    element: (
      <BusinessAndPublicProtectedRoutes>
        <BusinessHome></BusinessHome>
      </BusinessAndPublicProtectedRoutes>
    ),
  },
  {
    path: '/search-channel/:channel',
    element: (
      <BusinessAndPublicProtectedRoutes>
        <SearchInfluencer></SearchInfluencer>
      </BusinessAndPublicProtectedRoutes>
    ),
  },
  {
    path: '/search-channel',
    element: (
      <BusinessAndPublicProtectedRoutes>
        <SearchInfluencer></SearchInfluencer>
      </BusinessAndPublicProtectedRoutes>
    ),
  },
  {
    path: '/inquiry',
    element: (
      <BusinessProtectedRoutes>
        <BusinessInquiry></BusinessInquiry>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/inquiry/:id',
    element: (
      <BusinessProtectedRoutes>
        <CommanChat></CommanChat>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/profile',
    element: (
      <BusinessProtectedRoutes>
        <BusinessProfile></BusinessProfile>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/edit-profile',
    element: (
      <BusinessProtectedRoutes>
        <BusinessProfile></BusinessProfile>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/settings',
    element: (
      <BusinessProtectedRoutes>
        <BusinessSetting></BusinessSetting>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/change-password',
    element: (
      <BusinessProtectedRoutes>
        <BusinessChangePassword></BusinessChangePassword>
      </BusinessProtectedRoutes>
    ),
  },
  {
    path: '/:categary',
    element: (
      <BusinessAndPublicProtectedRoutes>
        <HelpCategarys></HelpCategarys>
      </BusinessAndPublicProtectedRoutes>
    ),
  },
];

export default BusinessRoutes;
