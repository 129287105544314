import { useEffect, useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from '../../styles/color';
import { useNavigate } from 'react-router-dom';
//! Icons And Images
import TextInput from '../../components/TextInput';
import { API } from '../../api/services';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/features/loader/loaderSlice';
import * as yup from 'yup';
import { ToastMessage } from '../../helpers/ToastMessage';
import { EyeOff, EyeOn, ForgotLogo } from '../../assets/icons';
import BreadCrumb from '../../components/common/BreadCrumb';
import { useCookies } from 'react-cookie';
import InfluencerResetDialog from '../../components/InfluencerResetDilog';

interface FormErrors {
  oldpassword?: string;
  newpassword?: string;
  confirmapassword?: string;
}

export const ChangePassword = () => {
  //! Hooks
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [token, __, removeAccessTokenToken] = useCookies([
    'accessToken',
    'refreshToken',
    'googleToken',
    'role',
  ]);

  interface ShowPasswordsState {
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
  }
  const [showPasswords, setShowPasswords] = useState<ShowPasswordsState>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [showResetDialog, setResetDialog] = useState(false);
  const [formData, setFormData] = useState({
    oldpassword: '',
    newpassword: '',
    confirmapassword: '',
  });
  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    oldpassword: yup.string().required('Current password is required'),
    newpassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('New password is required'),
    confirmapassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Confirm password is required')
      .oneOf([yup.ref('newpassword'), ''], 'Passwords must match'),
  });
  const changePasswordAPICall = async () => {
    setFormErrors({});
    try {
      dispatch(setLoading(true));
      const response = await API.post('/change-password', {
        ...formData,
      });
      const changePasswordData = response?.data;
      if (changePasswordData?.status) {
        setOpen(true);
        setResetDialog(true);
        ToastMessage({ type: 'success', message: changePasswordData.message });
      } else {
        setResetDialog(false);
        ToastMessage({ type: 'error', message: 'old password not correct' });
      }
    } catch (error: any) {
      setResetDialog(false);
      ToastMessage({ type: 'error', message: 'Old password not match' });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleChangePassword = (e: any) => {
    e.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        changePasswordAPICall();
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };

  //! Variables
  const navigate = useNavigate();
  //! Dialog
  const [open, setOpen] = useState(false);
  const handleClickShowPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    const passwordField = event.currentTarget.name as keyof ShowPasswordsState;
    setShowPasswords({
      ...showPasswords,
      [passwordField]: !showPasswords[passwordField],
    });
  };

  // useEffect(() => {
  //   if (document.cookie) {
  //   } else {
  //     navigate('/');
  //   }
  // }, [document.cookie]);

  return (
    <Box
      sx={{
        '& .MuiAppBar-root': {
          position: 'sticky',
        },
      }}
    >
      {token.role === '2' && (
        <BreadCrumb
          name1={'Settings'}
          isClickable={true}
          name2={'Change Password'}
          onClick={(e: any) => {
            navigate('/dashboard/settings');
          }}
        />
      )}

      <Box sx={token.role === '2' ? { p: '0px 16px', mt: '16px', mb: '16px' } : {}}>
        <Box bgcolor={token.role === '2' ? colors.dark[800] : ''} borderRadius='12px'>
          <Box
            sx={{
              p: { sm: '0px', xs: '0px 10px' },
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              textAlign: 'center',
            }}
          >
            <Box
              pt={token.role === '2' ? '84px' : 0}
              sx={{
                '& svg': {
                  width: '130px',
                  height: '130px',
                },
              }}
            >
              <ForgotLogo />
            </Box>
            <Box mt={'40px'}>
              <Typography variant='h1'>Create New Password</Typography>
            </Box>
            <form onSubmit={handleChangePassword} method='post'>
              <Box mt={'40px'}>
                <TextInput
                  onChange={handleChange}
                  name='oldpassword'
                  title='Current Password'
                  placeholder='Enter current password'
                  type={showPasswords.oldPassword ? 'text' : 'password'}
                  EndIcon={
                    <IconButton onClick={handleClickShowPassword} name='oldPassword'>
                      {showPasswords.oldPassword ? <EyeOn /> : <EyeOff />}
                    </IconButton>
                  }
                />
                {formErrors.oldpassword && (
                  <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
                    {formErrors.oldpassword}
                  </div>
                )}
              </Box>
              <Box mt={'19px'}>
                <TextInput
                  name='newpassword'
                  onChange={handleChange}
                  title='New Password'
                  placeholder='Enter new password'
                  type={showPasswords.newPassword ? 'text' : 'password'}
                  EndIcon={
                    <IconButton onClick={handleClickShowPassword} name='newPassword'>
                      {showPasswords.newPassword ? <EyeOn /> : <EyeOff />}
                    </IconButton>
                  }
                />
                {formErrors.newpassword && (
                  <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
                    {formErrors.newpassword}
                  </div>
                )}
              </Box>
              <Box mt={'19px'}>
                <TextInput
                  name='confirmapassword'
                  onChange={handleChange}
                  title='Confirm Password'
                  placeholder='Enter confirm password'
                  type={showPasswords.confirmPassword ? 'text' : 'password'}
                  EndIcon={
                    <IconButton onClick={handleClickShowPassword} name='confirmPassword'>
                      {showPasswords.confirmPassword ? <EyeOn /> : <EyeOff />}
                    </IconButton>
                  }
                />
                {formErrors.confirmapassword && (
                  <div style={{ color: '#d32f2f', textAlign: 'left', fontSize: '12px', marginTop: '3px' }}>
                    {formErrors.confirmapassword}
                  </div>
                )}
              </Box>
              <Box width='100%' mt={'40px'} pb={'40px'}>
                <Button sx={{ width: '100%' }} type='submit'>
                  Reset Password
                </Button>
              </Box>
            </form>
          </Box>
          {showResetDialog && <InfluencerResetDialog />}
        </Box>
      </Box>
    </Box>
  );
};
