import { Box, Button, Typography } from '@mui/material';
import { colors } from '../styles/color';
import { useNavigate } from 'react-router-dom';
import { CustomContainer } from '../components/CustomContainer';
import { CompleteLogo } from '../assets/icons';
const ResetPassword = () => {
  //! Variables
  const navigate = useNavigate();

  return (
    <Box>
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '39px 35px', xs: '20px 10px' },
            display: 'block',
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '100px', xs: '50px' },
              mb: { sm: '200px', xs: '30px' },

              textAlign: 'center',
            }}
          >
            <Box>
              <CompleteLogo />
            </Box>
            <Box mt={'39px'}>
              <Typography variant='h1'>Password reset</Typography>
              <Typography variant='body2' color={colors.gray[300]}>
                Your password has been reset successfully. Click below to login.
              </Typography>
            </Box>

            <Box pt={'35px'}>
              <Button variant='contained' sx={{ width: '100%' }} onClick={() => navigate('/dashboard')}>
                Countinue
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default ResetPassword;
