import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo/footer-logo.svg';
import Twitter from '../../assets/images/twitter.jpg';
import { ReactComponent as Facebook } from '../../assets/icons/Facebook.svg';
import { ReactComponent as Instagram } from '../../assets/icons/Instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/LinkedIn.svg';
import { colors } from '../../styles/color';
import * as yup from 'yup';
import { SearchInput } from '../SearchInput';
import { CustomContainer } from '../CustomContainer';
import { useNavigate } from 'react-router-dom';
import ErrorTextBox from '../common/ErrorTextBox';
import { instance } from '../../api/services/axiosClient';
import { baseURL } from '../../api/url';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/features';
import { ToastMessage } from '../../helpers/ToastMessage';
import { makeStyles } from '@mui/styles';
import { useCookies } from 'react-cookie';
import { footerDisplayPages, footerPolicyPages } from '../../constants/menuItems';
import dayjs from 'dayjs';

interface FormErrors {
  email?: string;
}

const useStyles: any = makeStyles({
  displayFlex: {
    width: '182px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '24px',
  },
  bgBlack: {
    backgroundColor: colors.dark[100],
  },
  subscribeText: {
    fontSize: '18px',
    fontWeight: '400',
    color: colors.gray[300],
  },
  footerSubscribeButton: {
    '@media (max-width: 869px)': {
      padding: '10px 0px',
    },
  },
});

const Footer = () => {
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const validTLDs = [
    'com',
    'org',
    'net',
    'gov',
    'edu',
    'mil',
    'int',
    'eu',
    'us',
    'uk',
    'ca',
    'jp',
    'cn',
    'au',
  ];
  const validTLDRegex = new RegExp(`(${validTLDs.join('|')})$`, 'i');

  const schema = yup.object().shape({
    email: yup
      .string()
      .test('valid-email', 'Invalid email address', (value) => {
        if (value) {
          const parts = value.split('@');
          if (parts.length === 2) {
            const username = parts[0];
            const domain = parts[1];

            if (
              username === '' ||
              username.startsWith('.') ||
              domain.startsWith('.') ||
              username.includes('..') ||
              domain.includes('..') ||
              domain.startsWith('-') ||
              username.endsWith('.') ||
              !/^[ -~\t\n\r]+$/u.test(value)
            ) {
              return false;
            } else if (domain.startsWith('[') && domain.endsWith(']')) {
              const ip = domain.substring(1, domain.length - 1);
              if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip)) {
                const parts = ip.split('.');
                for (let part of parts) {
                  if (parseInt(part) < 0 || parseInt(part) > 255) {
                    return false;
                  }
                }
                return true;
              }
            } else if (domain.includes('.')) {
              const domainParts = domain.split('.');
              const tld = domainParts[domainParts.length - 1];

              if (validTLDRegex.test(tld) || tld === 'name') {
                return true;
              }
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required('Email is required'),
  });

  const successApi = async () => {
    setFormErrors({});
    await instance
      .post(baseURL + '/subscribe', { email: email })
      .then((res: any) => {
        if (res.data.status) {
          ToastMessage({
            type: 'success',
            message: res.data.message,
          });
          setEmail('');
          setFormErrors({});
          dispatch(setLoading(false));
        } else {
          ToastMessage({
            type: 'error',
            message: res.data.message,
          });
          dispatch(setLoading(false));
        }
      })
      .catch((err: any) => {
        ToastMessage({
          type: 'error',
          message: err.message,
        });
        dispatch(setLoading(false));
      });
  };

  const subscribers = () => {
    schema
      .validate({ email: email }, { abortEarly: false })
      .then(() => {
        dispatch(setLoading(true));
        successApi();
      })
      .catch((err: any) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      });
  };

  const url = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);

  return (
    <CustomContainer maxWidth={url === 'youtubee-channel' ? '100% !important' : '1352px !important '}>
      <Box sx={{ pt: { md: '60px' } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '420px', }}>
            <Box onClick={() => navigate('/')} sx={{marginTop:{sm:0,xs:"20px"}}}
            >
              <Logo />
            </Box>
            <Typography
              sx={{
                fontWeight: '400',
                color: colors.gray[300],
                marginTop: { sm: '12px', xs: '0' },
              }}
              variant='body2'
            >
              Your Bridge to Brand Brilliance through Influencer Synergy and Authentic Connections.
            </Typography>
            <Box className={classes.displayFlex}>
              <img className='cursor-pointer'
                onClick={() => {
                  window.open('https://twitter.com/i/flow/login', '_blank');
                }} src={Twitter} height={"32px"} width={"32px"} style={{ borderRadius: '4px', objectFit: 'cover' }} />
              <Facebook
                className='cursor-pointer'
                onClick={() => {
                  window.open('https://www.facebook.com/', '_blank');
                }}
              />
              <Instagram
                className='cursor-pointer'
                onClick={() => {
                  window.open('https://www.instagram.com/', '_blank');
                }}
              />
              <LinkedIn
                className='cursor-pointer'
                onClick={() => {
                  window.open('https://in.linkedin.com/', '_blank');
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: '100%',
              display: { sm: 'flex', xs: 'block' },
              margin: { sm: '0 auto', xs: '0' },
              justifyContent: { sm: 'center', xs: '' },
              flexDirection: 'column',
              order: { sm: 1, xs: 2 },
            }}
          >
            {footerDisplayPages?.map((f: any, i: any) => {
              return (
                <Typography
                  key={i}
                  className='cursor-pointer'
                  onClick={() => {
                    navigate(f.path);
                  }}
                  variant='body2'
                  sx={{ color: colors.gray[300], '&:not(:last-child)': { mr: '36px' } }}
                >
                  {f.name}
                </Typography>
              );
            })}
          </Box>
          <Box sx={{ width: '100%', maxWidth: '420px', order: { sm: 2, xs: 0 } }}>
            <Typography
              className={classes.footerSubscribeButton}
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                color: colors.gray[300],
                paddingBottom: '8px',
              }}
              variant='body1'
            >
              Subscribe to our newsletter
            </Typography>
            <Box
              sx={{
                display: { sm: 'block', xs: 'none' },
              }}
            >
              <SearchInput
                value={email}
                onSearchButtonClick={() => {
                  subscribers();
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder='Enter your mail address...'
              />
              <ErrorTextBox>{formErrors.email}</ErrorTextBox>
            </Box>
            <Box
              sx={{
                display: { sm: 'none', xs: 'block' },
              }}
            >
              <SearchInput
                value={email}
                onSearchButtonClick={() => {
                  subscribers();
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder='Enter E-mail...'
              />
              <ErrorTextBox>{formErrors.email}</ErrorTextBox>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ margin: { sm: '82px 0 24px', xs: '20px 0' } }} className={classes.bgBlack} />
        <Box
          sx={{
            display: { md: 'flex', sm: 'block' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            textAlign: { md: '', sm: 'center' },
          }}
        >
          <Box sx={{ display: { sm: 'flex', xs: 'block' }, justifyContent: { sm: 'center', xs: '' } }}>
            {footerPolicyPages?.map((f: any, i: any) => {
              return (
                <Typography
                  key={i}
                  className='cursor-pointer'
                  onClick={() => {
                    navigate(f.path);
                  }}
                  variant='body2'
                  sx={{
                    color: colors.gray[300],
                    textAlign: { sm: 'none', xs: 'center' },
                    '&:not(:last-child)': { mr: { sm: '36px' } },
                  }}
                >
                  {f.name}
                </Typography>
              );
            })}
          </Box>
          <Box>
            <Typography
              variant='body2'
              sx={{ color: colors.gray[300], textAlign: { sm: 'none', xs: 'center' } }}
            >
              © {dayjs().year()} Quickfluence, All rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default Footer;
