import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

// export const fetchContentCategoryDetails: any = async () => {
//   const data = await API.get(`/content-category-list`)
//     .then((response: any) => {
//       return response;
//     })
//     .catch((error: any) => {
//       return error;
//     });
//   return data;
// };

export const fetchContentCategoryDetails: any = createAsyncThunk('/content-category-list', async () => {
  const response = await API.get(`/content-category-list`);
  return response.data?.data;
})

const initialState = {
  contentCategoryData: [],
  status: '',
  // loading: false,
  // isLoggedIn:false,
};

const contentCategorySlice = createSlice({
  name: 'countryDetails',
  initialState,
  reducers: {
    setContentCategory: (state, { payload }) => {
      state.contentCategoryData = payload.payload;
    },
  },
});

export const { setContentCategory } = contentCategorySlice.actions;
export default contentCategorySlice.reducer;
