import { Box, Dialog } from "@mui/material"
import { ResetPassword } from "./Business"
import { colors } from "../styles/color"

const InfluencerResetDialog = () => {
    return (
        <Box>
            <Dialog
                open={true}
                maxWidth={'xl'}
                sx={{
                    '& .MuiDialog-paper': {
                        background: colors.dark[600],
                        borderRadius: '24px',
                    },
                }}
            >
                <ResetPassword />
            </Dialog>
        </Box>
    )
}

export default InfluencerResetDialog