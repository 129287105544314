import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../api/services';

export const fetchAddChannelData: any = createAsyncThunk(
  'AddChannel/fetchList',
  async (channel: any) => {
    const response = await API.post(`/channal`,channel);
    return response.data;
  }
);
const initialState = {
    addChannel: {},
};
const addChannelSlice = createSlice({
  name: 'AddChannel',
  initialState,
  reducers: {
    setaddChannelState: (state, { payload }) => {
      state.addChannel = payload;
    },
  },
});
export const { setaddChannelState } = addChannelSlice.actions;
export default addChannelSlice.reducer;
