export const durationTime = [
  {
    id: 0,
    time: 'Daily',
  },
  {
    id: 1,
    time: 'Alternate days',
  },
  {
    id: 2,
    time: 'Weekly',
  },
  {
    id: 3,
    time: 'By-weekly',
  },
  {
    id: 4,
    time: 'Monthly',
  },
  {
    id: 5,
    time: 'Not fixed',
  },
  {
    id: 6,
    time: 'Sometime',
  },
];

export const channelData = ['Quik', 'QuikTrip', 'Quick Trip'];
export const MediaData: string[] = ['YouTube', 'Instagram'];

export const dummyData = [
  {
    title: 'Tailored Matches : ',
    text: "Discover influencers aligned with your brand's values and target audience.",
  },
  {
    title: 'Streamlined Collaboration : ',
    text: 'Effortlessly plan, execute, and track campaigns within our intuitive platform.',
  },
  {
    title: 'Data-Driven Insights : ',
    text: 'Make informed decisions backed by real-time performance analytics.',
  },
  {
    title: 'Creative Freedom : ',
    text: 'Co-create compelling content that resonates with your audience.',
  },
];
export const QuickFluenceList = [
  {
    title: 'Tailored Matches : ',
    text: 'Our advanced algorithms ensure precise influencer-brand matching.',
  },
  {
    title: 'Efficient Collaboration : ',
    text: 'Streamlined communication and collaboration tools make campaign execution effortless.',
  },
  {
    title: 'Data-Driven Insights : ',
    text: 'Access real-time analytics to optimize your influencer marketing strategy.',
  },
  {
    title: 'Creative Freedom : ',
    text: 'Co-create engaging content that resonates with your target audience.',
  },
];
export const footerDisplayPages = [
  {
    name: 'About Us',
    path: '/about-us',
  },
  {
    name: 'Contact Us',
    path: '/contact-us',
  },
  {
    name: 'Disclaimer',
    path: '/disclaimer',
  },
  {
    name: 'DMCA Policy',
    path: '/dmcapolicy',
  },
];
export const footerPolicyPages = [
  {
    name: 'Terms & Conditions',
    path: '/terms-conditions',
  },
  {
    name: 'Privacy Policy',
    path: '/privacy-policy',
  },
  {
    name: 'Data Safety',
    path: '/data-safety',
  },
];
