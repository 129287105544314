import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';

import { setDrawerState } from '../redux/features/drawer/drawerSlice';
import { colors } from '../styles/color';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface drawerProps {
  direction: Anchor;
  width?: any;
  children: JSX.Element;
  DrawerRightRootClassName?: any;
}

export const DrawerRoot = ({ direction, children, width, DrawerRightRootClassName }: drawerProps) => {
  //! States
  const drawerState = useSelector((state: any) => state.drawer.drawerState);

  //! Variables
  const dispatch = useDispatch();

  //! Functions
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(setDrawerState({ ...drawerState, [anchor]: open }));
  };

  return (
    <Drawer
      anchor={direction}
      open={drawerState[direction]}
      onClose={toggleDrawer(direction, false)}
      className={DrawerRightRootClassName}
      ModalProps={{
        sx: {
          '& .MuiBackdrop-root': {
            opacity: '0 !important',
          },
          '& .MuiPaper-root ': {
            boxShadow: 'none',
            top: drawerState.left ? { xs: '0px' } : drawerState.right ? { xs: '0px' } : '80px',
            background: colors.dark[800],
            overflowY: 'unset',
          },
        },
      }}
    >
      <Box width={direction === 'top' || direction === 'bottom' ? 'auto' : width} role='presentation'>
        {children}
      </Box>
    </Drawer>
  );
};

export default DrawerRoot;
