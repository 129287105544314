import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  message?: string,
  type?: 'success' | 'error' | 'warning' | 'info';
}
export const ToastMessage = ({ message, type }: Props) => {
  // Check if the toast message is already displayed
  // if (type && !toast.isActive(`${type}-toast`)) {
  //   // Show the toast message
  //   toast[type](message, {
  //     toastId: `${type}-toast` // Set a unique ID for the toast message
  //   });
  // }

  // return null;
  if (type) {
    return toast[type](message, {
      // closeOnClick: true,
      autoClose: 2000,
      // toastId: `${type}-toast`,
      // position: toast.POSITION.TOP_RIGHT
    });
  } else {
    return null
  }

};

