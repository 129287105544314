import { Box, Typography } from '@mui/material';
import { colors } from '../../styles/color';
import Image from '../common/Image';
import YouTube from '../../assets/images/YouTube.png';
import { Instagram } from '../../assets/icons';
import { nFormatter } from '../../utils/commanFunctions/commanFunctions';
type Props = {
  el?: any;
  filterData?: any;
  filtervalue?: any;
};

const InfluencerCard = ({ el, filterData, filtervalue }: Props) => {
  return (
    <Box
      className='influencerCard'
      sx={{
        maxWdth: '400px',
        width: '100%',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          paddingBottom: '100%',
        }}
      >
        <Image
          src={`${el?.thumbnail}`}
          className='card-img'
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            objectFit: 'cover',
          }}
          alt=''
        />
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            padding: '10px 12px',
            left: '50%',
            bottom: '34px',
            transform: 'translate(-50%,50%)',
            background: '#1d1d1d99',
            alignItems: 'center',
            borderRadius: '8px',
            '& img': {
              width: { sm: '20px', xs: '18px' },
              height: 'fit-content',
            },
          }}
        >
          {!el.isInstagram ? (
            <Image width={20} height={20} src={YouTube}></Image>
          ) : (
            <Instagram width={20} height={20} />
          )}
          <Typography
            p={'0px 5px'}
            sx={{ fontSize: { sm: '16px', xs: '15px' } }}
            variant='subtitle1'
          >
            {nFormatter(el.subscriber_count, 2)}
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{ fontWeight: 400, fontSize: { sm: '14px', xs: '12px' } }}
          >
            Subscribers
          </Typography>
        </Box>
      </Box>
      <Box
        className='card-disc-wrapper'
        sx={{
          background: colors.dark[600],
          padding: { sm: '20px', xs: '10px' },
          bottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: { sm: 'nowrap', xs: 'wrap' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: { sm: 'start', xs: 'center' },
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            className='card-disc-img'
            sx={{
              height: { md: '56px', xs: '40px' },
              width: { md: '56px', xs: '40px' },
              backgroundImage: `url(${el?.user?.profile})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '50%',
              flexShrink: '0',
            }}
          />
          <Box
            className='card-text-wrapper'
            sx={{ ml: '10px', mr: '10px', width: '100%', overflow: 'hidden' }}
          >
            <Typography
              variant='body1'
              sx={{
                fontSize: { md: '18px', sm: '16px', xs: '14px' },
                fontWeight: '600',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'block',
                paddingBottom: '5px',
                width: '100%',
                lineHeight: '1.5',
              }}
            >
              {el?.channel_name}
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontWeight: '500',
                color: colors.gray[300],
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'block',
                width: '100%',
                lineHeight: '1.5',
                fontSize: { md: '14px', xs: '12px' },
              }}
            >
              By {el?.user?.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flexShrink: '0' }}>
          {
            el.video_type === '1' && (
              (
                (el.minimum_price !== '0.00' && el.minimum_short_price !== '0.00') ||
                (el.minimum_price == '0.00' && el.minimum_short_price !== '0.00') ||
                (el.minimum_price !== '0.00' && el.minimum_short_price == '0.00') ||
                (el.minimum_price == null && el.minimum_short_price !== null) ||
                (el.minimum_price !== null && el.minimum_short_price == null)||
                (el.minimum_price !== null && el.minimum_short_price !=='0.00')||
                (el.minimum_price !== '0.00' && el.minimum_short_price !== null)
              )
            ) ||
           el.video_type === '2' && ((
            (el?.minimum_price !=='0.00')) )||
          el.video_type === '3' && ((
            (el?.minimum_short_price !=='0.00')) )
            ?
         <>
          <Typography
            variant='body1'
            sx={{
              fontSize: { sm: '12px', xs: '10px' },
              fontWeight: '500',
              color: colors.gray[300],
              textAlign: { sm: 'right' },
              lineHeight: { sm: '30px', xs: '1' },
              padding: { sm: '0', xs: '14px 0 5px' },
            }}
          >
            Starting at
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: '500',
              fontSize: { md: '18px', sm: '16px', xs: '14px' },
              color: colors.basics.primary,
              textAlign: { sm: 'right' },
            }}
          >
            {
              el.video_type === '2' && el?.minimum_price !== null ? `₹${Math.floor(el.minimum_price)}` :
                el.video_type === '3' && el?.minimum_short_price !== null ? `₹${Math.floor(el.minimum_short_price)}` :

                  el.video_type === '1' && el?.minimum_price !== null && el?.minimum_short_price !== null  &&
                    Math.min(el?.minimum_price, el?.minimum_short_price) >= filterData?.inputMinMax?.price[0] ?
                    (el?.minimum_price  !== null && el?.minimum_short_price !== null  && el?.minimum_price !== '0.00' && el?.minimum_short_price !== '0.00')  ?
                    `₹${Math.min(
                      el?.minimum_price,
                      el?.minimum_short_price
                    ).toFixed(0)}`:
                    `₹${Math.max(
                      el?.minimum_price,
                      el?.minimum_short_price
                    ).toFixed(0)}` :
                    filtervalue  &&
                    `₹${Math.max(
                      el?.minimum_price,
                      el?.minimum_short_price
                    ).toFixed(0)}` || (el?.minimum_price == null && el?.minimum_short_price !== null  ) || (el?.minimum_price !== null && el?.minimum_short_price == null)  ?
                    `₹${Math.max(
                      el?.minimum_price,
                      el?.minimum_short_price
                    ).toFixed(0)}`: `₹${Math.min(
                      el?.minimum_price,
                      el?.minimum_short_price
                    ).toFixed(0)}`
            }
          </Typography>
          </>
           : ""
           }
        </Box>
      </Box>
    </Box>
  );
};

export default InfluencerCard;
