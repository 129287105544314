import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Calendar } from 'react-multi-date-picker';
import { colors } from '../styles/color';
import { Close } from '../assets/icons';
import { setDrawerState } from '../redux/features/drawer/drawerSlice';
import { useDispatch, useSelector } from 'react-redux';

interface ChildComponentProps {
  visible?: any;
  setVisible?: any;
  filterRadioButton?: any;
  value?: any;
  values?: any;
  setValues?: any;
  open?: any;
  setOpen?: any;
  customApplyClick?: any;
  startDate?: any;
  endDate?: any;
  weekDays?: any;
}

export const FilterData = ({ visible, setVisible, filterRadioButton, value, values, setValues, customApplyClick, open, setOpen, startDate, endDate, weekDays }: ChildComponentProps) => {
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  const dispatch = useDispatch()
  return (
    <Box
      sx={{
        width:"310px",
        flexShrink:"0",
        display: 'flex',
        background: colors.dark[800],
        borderRadius: '12px 0px 0px 0px',
        ml: { xl: '20px', xs: '0px' },
        mr:{sm:"-16px"},
        height: { xl: 'calc(100vh - 110px)', xs: 'calc(100vh - 0px)' },
      }}
    >
      <Box p={'30px 20px'} width='100%' overflow={"auto"} flexDirection='column' flex='1' display={'flex'}>
        <Box
          display={'flex'}
          alignItems='center'
          justifyContent={'space-between'}
          textAlign='center'
          pr={'10px'}
        >
          <Box>
            <Typography>Filters</Typography>
          </Box>
          <Box display={'flex'}>
            {' '}
            <Button
              onClick={() => {
                setVisible(false)
                setOpen(false)
                dispatch(setDrawerState({ ...drawerState, right: false }));
              }}
              variant='text'
              sx={{ background: colors.dark[800], borderRadius: '10px' }}
            >
              <Close />
            </Button>
          </Box>
        </Box>
        <Box mt={'20px'}>
          <Box
            sx={{
              '& .MuiDivider-root': {
                width: { xl: '270px', xs: '250px' },
                mt: '10px',
                mb: '10px',
                borderBottom: `1px solid ${colors.dark[100]}`,
              },
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='all'
                name='radio-buttons-group'
                value={value}
                onChange={(e) => filterRadioButton(e)}
                sx={{
                  '& .MuiRadio-root': {
                    color: colors.gray[500],
                  },
                  '& .Mui-checked': {
                    color: colors.basics.primary,
                    '& svg': {
                      fill: colors.basics.primary,
                    },
                  },
                  '& .MuiFormControlLabel-label': {
                    color: colors.gray[300],
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '21px',
                  },
                }}
              >
                <FormControlLabel value='0' control={<Radio />} label='All' />
                <Divider />
                <FormControlLabel value='1' control={<Radio />} label='Last 7 days' /> <Divider />
                <FormControlLabel value='2' control={<Radio />} label='Last 15 days' /> <Divider />
                <FormControlLabel value='3' control={<Radio />} label='Last Month' /> <Divider />
                <FormControlLabel value='4' control={<Radio />} label='Last 3 Months' /> <Divider />
                <FormControlLabel value='5' control={<Radio />} label='Last 6 Months' /> <Divider />
                <FormControlLabel value='6' control={<Radio />} label='Last Year' /> <Divider />
                <FormControlLabel
                  value='7'
                  control={<Radio />}
                  onClick={() => setOpen(true)}
                  label='Custom'
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            mt: {sm:'auto',xs:"0"},
          }}
        >
          <Button sx={{ width: '100%',mt:"15px" }}
            onClick={() => customApplyClick(startDate, endDate)}
          >
            Apply
          </Button>
        </Box>
      </Box>{' '}
      <Box>
        <Dialog
          open={open}
          maxWidth={'xl'}
          sx={{
            '& .MuiDialog-paper': {
              background: colors.dark[600],
              borderRadius: '12px',
              margin:"15px",
              maxWidth:"719px",
              width:"100%"
            },
          }}
        >
          <Box
            p={{md:'20px',xs:'15px'}}
            justifyContent={{ md: 'space-between', xs: '' }}
            display={{ md: 'flex', xs: 'block' }}
            sx={{
              '& .rmdp-day-picker': {
                width: '100%',
                borderTop: { md: `1px solid ${colors.dark[100]}`, xs: '0' },
                marginTop: '15px',
                p:'0'
              },
              '& .rmdp-calendar': {
                padding: '0',
              },
              '& .rmdp-wrapper': {
                background: colors.dark[600],
                paddingTop: '3px',
              },
              '& .rmdp-border-right': {
                borderRight: '0px',
              },
              '& .rmdp-shadow': {
                boxShadow: 'none',
              },
              '& .arrow': {
                position: 'absolute',
                top: '50%',
                right: '17px',
                cursor: 'pointer',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
              },
              '& .rmdp-arrow': {
                borderColor: ` ${colors.basics.primary}`,
              },
              '& .rmdp-day': {
                color: colors.gray[300],
                height: {sm:'40px',xs:'35px'},
                width: {sm:'40px',xs:'35px'},
                fontWeight: 600,
                fontSize: '17px !important',
                lineHeight: '27px',
              },
              '& .rmdp-week-day': {
                color: colors.basics.white,
                lineHeight: '20px',
                fontWeight: 500,
                fontSize: '12.50px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
              },
              '& .rmdp-week': {
                margin: '8px',
                marginBottom: '0.5rem',
              },
              '& .rmdp-header-values': {
                color: colors.basics.white,
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '17px',
                lineHeight: '27px',
              },
              '& .rmdp-today span': {
                background: 'transparent',
                color: colors.gray[300],
              },
              '& .rmdp-day.rmdp-range ': {
                backgroundColor: colors.dark[100],
                color: colors.basics.white,
                boxShadow: 'none',
              },
              '& .rmdp-day.rmdp-range.start span': {
                backgroundColor: colors.basics.primary,
                left: '0 !important',
                right: '0 !important',
                top: '0 !important',
                bottom: '0 !important',
                color: colors.basics.white,
              },
              ' & .rmdp-day.rmdp-range.end span': {
                backgroundColor: colors.basics.primary,
                left: '0 !important',
                right: '0 !important',
                top: '0 !important',
                bottom: '0 !important',
                color: colors.basics.white,
              },
              '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden).rmdp-in-range:not(:hover) span:hover': {
                backgroundColor: colors.basics.primary,
                borderRadius: '50% !important',

                top: '0 !important',
                left: '0 !important',
                bottom: '0 !important',
                right: '0 !important',
              },
              '& .rmdp-range:not(.rmdp-disabled):not(.rmdp-range-hidden) span:hover': {
                backgroundColor: colors.basics.primary,
                borderRadius: '50% !important',

                top: '0 !important',
                left: '0 !important',
                bottom: '0 !important',
                right: '0 !important',
              },
              '& .rmdp-day-hidden': {
                visibility: 'hidden',
              },
              '& .rmdp-cancel': {
                display: 'none',
              },
            }}
          >
            <Box
              p={{ md: '20px 0px 20px 0px', xs: '10px 20px' }}
              width={{md:'100%',xs:"auto"}}
              borderBottom={{ md: `1px solid ${colors.dark[100]}`, xs: '0' }}
              marginBottom={{ md: '71px', xs: 0 }}
            >
              <Box
                sx={{
                  textAlign: { md: 'left', xs: 'center' },
                  borderBottom: { md: `1px solid ${colors.dark[100]}`, xs: '0' },
                }}
              >
                <Typography variant='body1' marginBottom={{ md: '11px', xs: 0 }}>
                  Filter
                </Typography>
              </Box>
              <Box mt={'10px'} sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                <Typography variant='body2' fontWeight={600} lineHeight='27px'>
                  {startDate} to {endDate}
                </Typography>
              </Box>
            </Box>
            <Box display={{ md: 'none', xs: 'flex' }}>
              <Divider sx={{ borderTop: `1px solid ${colors.dark[100]}`, width: '100%' }} />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Box>
                <Box>
                  <Calendar
                    maxDate={new Date()}
                    numberOfMonths={1}
                    value={values}
                    onChange={(e: any) => {
                      setValues(e)
                    }}
                    range
                    format={'MMM D , YYYY'}
                    weekDays={weekDays}
                  />
                </Box>
                <Box display={{ md: 'flex', xs: 'flex' }}>
                  <Divider sx={{ borderTop: `1px solid ${colors.dark[100]}`, width: '100%', pb: '20px' }} />
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  sx={{
                    '& .cancel-btn': {
                      '&:hover': {
                        background: colors.dark[600],
                      },
                    },
                  }}
                >
                  <Button
                    onClick={() => customApplyClick()}
                    className='cancel-btn'
                    variant='outlined'
                    sx={{
                      mr: {sm:'10px',xs:"6px"},
                      width: '100%',
                      background: colors.dark[600],
                    }}
                  >
                    cancel
                  </Button>
                  <Button sx={{ width: '100%', ml: {sm:'10px',xs:"6px"} }}
                    onClick={() => customApplyClick(startDate, endDate)}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
