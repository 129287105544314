import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import OtpInput from 'react-otp-input';
import { BackButton } from '../../assets/icons';
import { colors } from '../../styles/color';
import { ToastContainer } from 'react-toastify';
import { CustomContainer } from '../CustomContainer';
import { useAppSelector } from '../../hooks/useAppReducer';
import { ApplicationStateType } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { API } from '../../api/services';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import { baseURL } from '../../api/url';
import { resetDialogState, setDialogState, setLoading } from '../../redux/features';
import { ToastMessage } from '../../helpers/ToastMessage';
const OtpModal = () => {
  const { signupData } = useAppSelector((state: ApplicationStateType) => state.signup);
  const {
    dialog: {
      otpDialog: { fromPageName },
    },
  } = useAppSelector((state: ApplicationStateType) => state.dialog);

  const dispatch = useDispatch();
  //! Hooks
  const [otp, setOtp] = useState<string>('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [accessToken, setAccessToken] = useCookies(['accessToken', 'refreshToken', 'role']);
  //! Variables
  const navigate = useNavigate();

  //! Functions
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  const handleOtpVerify = () => {
    if (fromPageName === 'register') {
      handleSignupOtpVerify();
    } else if (fromPageName === 'forgot-password') {
      handleForgetPasswordOtpVerify();
    }
  };
  const handleSignupOtpVerify = async () => {
    dispatch(setLoading(true));

    try {
      const response = await Axios.post(`${baseURL}/signup`, { ...signupData, otp });
      const signUpOtpVerifyData = response?.data;

      if (signUpOtpVerifyData.status) {
        ToastMessage({
          type: 'success',
          message: signUpOtpVerifyData.message,
        });
      } else {
        ToastMessage({
          type: 'error',
          message: signUpOtpVerifyData.message,
        });
      }
      if (signUpOtpVerifyData?.access_token) {
        setOtp('');
        setAccessToken('accessToken', signUpOtpVerifyData?.access_token, {
          path: '/',
          httpOnly: false,
          expires: dayjs().add(signUpOtpVerifyData?.expires_in, 'seconds').toDate(),
          secure: true,
          sameSite: 'strict',
        });
        setAccessToken('refreshToken', signUpOtpVerifyData?.refresh_token, {
          path: '/',
          httpOnly: false,
          expires: dayjs().add(signUpOtpVerifyData?.expires_in, 'seconds').toDate(),
          secure: true,
          sameSite: 'strict',
        });
        setAccessToken('role', signUpOtpVerifyData?.data?.role_id, {
          path: '/',
          httpOnly: false,
          expires: dayjs().add(signUpOtpVerifyData?.expires_in, 'seconds').toDate(),
          secure: true,
          sameSite: 'strict',
        });
        dispatch(resetDialogState());
        dispatch(setLoading(false));
        navigate('/');
      } else {
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
      dispatch(setLoading(false));
    }
  };
  const handleForgetPasswordOtpVerify = async () => {
    if (otp.length < 4) {
      ToastMessage({
        type: 'error',
        message: 'OTP must be 4 digit',
      });
    } else {
      dispatch(setLoading(true));
      try {
        const response = await API.post('/forgot-password', {
          ...signupData,
          otp: otp,
        });
        const forgetPasswordOtpVerifyData = response?.data;
        if (forgetPasswordOtpVerifyData?.status) {
          ToastMessage({
            type: 'success',
            message: forgetPasswordOtpVerifyData.message,
          });
          if (fromPageName === 'forgot-password') {
            dispatch(
              setDialogState({
                loginDialog: false,
                registerDialog: false,
                modal: true,
                otpDialog: { otpDialog: false, fromPageName: '' },
                privacyDialog: false,
                resetPassword: false,
                successReset: false,
                termsDialog: false,
                forgotPassword: false,
                newPassword: true,
              })
            );
          } else {
            navigate('/new-password', {
              state: signupData,
            });
          }
        }
        setOtp('');
      } catch (error: any) {
        ToastMessage({
          type: 'error',
          message: error.message,
        });
      }
      dispatch(setLoading(false));
    }
  };
  const handleResendOtp = () => {
    if (fromPageName === 'register') {
      handleSignupResendOtp();
    } else if (fromPageName === 'forgot-password') {
      handleForgetPasswordResendOtp();
    }
  };
  const handleSignupResendOtp = async () => {
    dispatch(setLoading(true));

    try {
      const response = await API.post('/signup', {
        ...signupData,
      });
      const signupResendOtpData = response?.data;
      if (signupResendOtpData?.status) {
        setMinutes(1);
        setSeconds(0);
        setOtp('');
        ToastMessage({
          type: 'success',
          message: signupResendOtpData.message,
        });
        dispatch(setLoading(false));
      } else {
        ToastMessage({
          type: 'error',
          message: signupResendOtpData.message,
        });
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
      dispatch(setLoading(false));
    }
  };
  const handleForgetPasswordResendOtp = async () => {
    dispatch(setLoading(true));
    try {
      const response = await API.post('/forgot-password', {
        ...signupData,
      });
      const forgetPasswordResendOtpData = response?.data;

      if (forgetPasswordResendOtpData?.status) {
        setMinutes(1);
        setSeconds(0);
      } else {
      }
    } catch (error: any) {
      ToastMessage({
        type: 'error',
        message: error.message,
      });
    }
    setOtp('');
    dispatch(setLoading(false));
  };
  return (
    <Box>
      <ToastContainer />
      <CustomContainer>
        <Box
          sx={{
            height: '100%',
            background: colors.dark[800],
            borderRadius: '24px',
            color: 'white',
            p: { sm: '0 35px', xs: '20px 10px' },
            display: 'block',
          }}
        >
          <Box>
            <Button
              variant="text"
              onClick={() => {
                if (fromPageName === 'forgot-password') {
                  dispatch(
                    setDialogState({
                      loginDialog: false,
                      registerDialog: false,
                      modal: true,
                      otpDialog: { otpDialog: false, fromPageName: '' },
                      privacyDialog: false,
                      resetPassword: false,
                      successReset: false,
                      termsDialog: false,
                      forgotPassword: true,
                    })
                  );
                } else {
                  dispatch(
                    setDialogState({
                      loginDialog: false,
                      registerDialog: true,
                      modal: true,
                      otpDialog: { otpDialog: false, fromPageName: '' },
                      privacyDialog: false,
                      resetPassword: false,
                      successReset: false,
                      termsDialog: false,
                      forgotPassword: false,
                    })
                  );
                }
              }}
            >
              <BackButton />
            </Button>
          </Box>
          <Box
            sx={{
              width: { sm: '100%', xs: 'auto' },
              maxWidth: '438px',
              m: 'auto',
              mt: { sm: '0', xs: '50px' },
              mb: { sm: '0', xs: '30px' },
              textAlign: 'center',
            }}
          >
            <Box mt={'39px'}>
              <Typography variant="h1">OTP Verification</Typography>
              <Typography variant="body2" color={colors.gray[300]}>
                Please enter 4-digit OTP sent to below Email ID
              </Typography>
              <Typography variant="body2" color={colors.basics.primary}>
                {signupData?.email}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: '34px',
                '& input': {
                  maxHeight: '67px !important',
                  maxWidth: '67px !important',
                  height: { sm: '67px !important', xs: '60px !important' },
                  width: { sm: '67px !important', xs: '60px !important' },
                  borderRadius: '13px !important',
                  lineHeight: '0 !important',
                  background: colors.dark[600],
                  border: 'none',
                  color: `${colors.basics.white} !important`,
                  fontSize: '20px',
                  fontWeight: 600,
                },
              }}
            >
              <OtpInput
                isInputNum
                separator={<Box sx={{ width: { sm: 50, xs: 27 } }}></Box>}
                value={otp}
                onChange={(otp: string) => setOtp(otp)}
                inputStyle={{
                  m: '0 auto',
                  borderRadius: 16,
                  maxWidth: '56px',
                  width: '56px',
                  height: '56px',
                  borderStyle: 'solid',
                  fontSize: 20,
                  border: 'none',
                }}
              />
            </Box>
            <Box pt={'49px'} width="100%">
              <Button variant="contained" sx={{ width: '100%' }} onClick={handleOtpVerify}>
                Verify
              </Button>
            </Box>
            <Box display={'flex'} justifyContent={'center'} mt={'20px'}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: colors.basics.primary,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {seconds > 0 || minutes > 0 ? (
                  <Typography display="flex" alignItems={'center'} ml="75px">
                    {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                ) : (
                  <Typography variant="body2" lineHeight={'140%'} color={colors.gray[300]}>
                    Didn't receive code?
                  </Typography>
                )}
              </Typography>
              <Button
                onClick={handleResendOtp}
                variant="text"
                sx={{ color: seconds > 0 || minutes > 0 ? colors.gray[300] : colors.basics.primary }}
                disabled={seconds > 0 || minutes > 0}
              >
                Resend
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default OtpModal;
