import React from 'react';
import { Box, InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';
import { colors } from '../styles/color';

interface textInputProps {
  title?: string;
  textFieldStyle?: SxProps<Theme> | undefined;
  style?: any;
  type?: string;
  onChange?: (e: any) => void;
  value?: string[] | any;
  inputProps?: any;
  helperText?: any;
  name?: string;
  placeholder?: string;
  required?: boolean;
  children?: JSX.Element;
  isFull?: boolean;
  select?: boolean;
  StartIcon?: JSX.Element;
  EndIcon?: JSX.Element;
  onKeyDown?: any;
  id?: any;
  error?: boolean;
  autoComplete?: any;
  drawer?: boolean;
}

export const TextInput = (props: textInputProps) => {
  const {
    title,
    textFieldStyle,
    style,
    type,
    onChange,
    value,
    error,
    helperText,
    name,
    placeholder,
    required,
    children,
    select,
    StartIcon,
    EndIcon,
    onKeyDown,
    inputProps,
    id,
    drawer
  } = props;

  return (
    <Box sx={{ ...style }}>
      {title && (
        <Typography
          sx={{ color: colors.gray[300] }}
          variant='body2'
          lineHeight='140%'
          mb='6px'
          textAlign='left'
          textTransform='capitalize'
        >
          {title}
        </Typography>
      )}
      <TextField
        onKeyDown={onKeyDown}
        sx={{ ...textFieldStyle, caretColor: colors.basics.primary, '& input': { padding: drawer ? '5px' : '', lineHeight: drawer ? '1 !important' : 'unset' } }}
        type={type}
        onChange={(e) => onChange && onChange(e)}
        value={value}
        error={error}
        helperText={helperText}
        name={name}
        placeholder={placeholder}
        required={required}
        select={select}
        id={id}
        inputProps={inputProps}
        InputProps={{
          readOnly: false,
          endAdornment: EndIcon ? (
            <InputAdornment position='end' sx={{ p: '1px' }}>
              <Box display='flex'>{EndIcon}</Box>
            </InputAdornment>
          ) : (
            <></>
          ),
          startAdornment: StartIcon ? (
            <InputAdornment position='end' sx={{ p: '1px' }}>
              <Box display='flex'>{StartIcon}</Box>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      >
        {children}
      </TextField>
    </Box>
  );
};

export default TextInput;
