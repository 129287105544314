import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CkEditoComponent = (props: any) => {
    const { data, setData, placeholder } = props
    return (
        <CKEditor
            config={{
                placeholder: placeholder,
                toolbar: [],
            }}
            // placeholder='Write short description about channel...'
            editor={ClassicEditor}
            data={data}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setData(data)
            }}
        />
    )
}

export default CkEditoComponent