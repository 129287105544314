import { Button, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { colors } from '../../styles/color';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../components/common/Topbar';
import { useDispatch } from 'react-redux';
import NotificationModal from './NotificationModal';
import { fetchProfileDetails, setProfileState } from '../../redux/features/profile/profileSlice';
import { AddChannelPlus, NotificationIcon, YoutubeChannelLogo } from '../../assets/icons';
import {
  fetchChannelSuggestionsList,
  setChannelSuggestionsState,
} from '../../redux/features/channelSuggestionList/channelSuggestionListSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { setLoading } from '../../redux/features';

export const EmptyDashboard = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  // React.useEffect(() => {
  //   const getProfileData = async () => {
  //     const res = await dispatch(fetchProfileDetails());
  //     setTimeout(() => {
  //       dispatch(setProfileState(res?.payload?.data));
  //     }, 200);
  //   };
  //   getProfileData();
  // }, [prof]);
  // suggestion API
  type props = {
    access_token?: any;
    accesstoken?: any;
  };
  const onSuccessData = async (response: props) => {
    const res = await dispatch(fetchChannelSuggestionsList(response?.access_token));
    dispatch(setChannelSuggestionsState(res?.payload?.data));
  };

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      dispatch(setLoading(true))
      await onSuccessData(res);
      dispatch(setLoading(false))
      navigate('/dashboard/add-channel');
    },
    scope: 'https://www.googleapis.com/auth/youtube.readonly',
  });

  const onFailure: any = (response: any) => {
    console.error(response);
  };

  const handleSubmit = async () => {
    login();
  };

  return (
    <Box>
      <Box
        m='auto'
        sx={{
          position: 'absolute',
          top: 'calc(50px + 50%)',
          left: '50%',
          textAlign: 'center',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box>
          <YoutubeChannelLogo />
        </Box>
        <Box mt={'28px'}>
          <Typography variant='body1' color={colors.gray[300]}>
            No channel added yet
          </Typography>
        </Box>
        <Box sx={{ width: '280px', mt: '44px' }}>
          <Button sx={{ width: '100%' }} startIcon={<AddChannelPlus />} onClick={handleSubmit}>
            Add Channel
          </Button>
        </Box>
      </Box>{' '}
      {/* <NotificationModal open={open} setOpen={setOpen} /> */}
    </Box>
  );
};
