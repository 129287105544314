import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from '../styles/color';

export const Loader = () => {
  const { loading } = useSelector((state: any) => state.loader);

  return loading ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: '#00000080',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack sx={{ color: colors.basics.primary }}>
        <svg
          className='loader__icon__svg'
          width='120'
          height='30'
          viewBox='0 0 120 30'
          xmlns='http://www.w3.org/2000/svg'
          fill='#A175FF'
        >
          <circle cx='15' cy='15' r='15'>
            <animate
              attributeName='r'
              from='15'
              to='15'
              begin='0s'
              dur='0.8s'
              values='15;9;15'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
            <animate
              attributeName='fillOpacity'
              from='1'
              to='1'
              begin='0s'
              dur='0.8s'
              values='1;.5;1'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
          </circle>
          <circle cx='60' cy='15' r='9' fillOpacity='.3'>
            <animate
              attributeName='r'
              from='9'
              to='9'
              begin='0s'
              dur='0.8s'
              values='9;15;9'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
            <animate
              attributeName='fillOpacity'
              from='.5'
              to='.5'
              begin='0s'
              dur='0.8s'
              values='.5;1;.5'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
          </circle>
          <circle cx='105' cy='15' r='15'>
            <animate
              attributeName='r'
              from='15'
              to='15'
              begin='0s'
              dur='0.8s'
              values='15;9;15'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
            <animate
              attributeName='fillOpacity'
              from='1'
              to='1'
              begin='0s'
              dur='0.8s'
              values='1;.5;1'
              calcMode='linear'
              repeatCount='indefinite'
            ></animate>
          </circle>
        </svg>
      </Stack>
    </Box>
  ) : null;
};
