import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api/services';
export const fetchDashboardDetails: any = createAsyncThunk('dashboardDetails/fetchDashboardDetails', async (channelId: any) => {
    const response = await API.post(`/dashboard`, channelId, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    },);
    return response?.data;
});

const initialState = {
    dashboardData: {},
};

const dashboardSlice = createSlice({
    name: 'dashboardDetails',
    initialState,
    reducers: {
        setDashboardState: (state, { payload }) => {
            state.dashboardData = payload;
        },
    },

});

export const { setDashboardState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
