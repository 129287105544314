import { Box, Button, Typography } from "@mui/material"
import { colors } from "../../styles/color"
import { EditProfileIcon } from "../../assets/icons"
import TextInput from "../TextInput"
import ErrorTextBox from "./ErrorTextBox"
import CkEditoComponent from "./CkEditorComponent"

const ProfileEdit = (props: any) => {
    const {
        column1,
        column2,
        column3,
        column4,
        file,
        handleFormChange,
        formErrors,
        buttonName,
        handleFormSubmit,
        handleChange,
        placeholder1,
        placeholder2,
        placeholder3,
        placeholder4,
        value1,
        value2,
        value3,
        value4,
        value5,
        name1,
        name2,
        name3,
        name4,
        name5,
        isTextInput,
        readOnly,
        buttonSvg,
        buttonStyles,
        cancelButtonClick,
        isViewCancelButton,
        setCkEditor
    } = props

    return (
        <Box
            sx={{
                width: { sm: '100%', xs: 'auto' },
                maxWidth: '438px',
                m: 'auto',
                textAlign: 'center',
                p: '10px',
            }}
        >
            <Box >
                <img
                    src={file ? URL?.createObjectURL(file) : value1}
                    alt='profile'
                    width='112px'
                    height='112px'
                    style={{ borderRadius: '50%', minHeight: "112px", minWidth: "112px", border: `2px solid ${colors.basics.primary}`, padding: '5px', objectFit: "cover", objectPosition: "center" }}
                />
                <Box>
                    <input
                        accept='image/jpeg, image/png, image/jpg'
                        height='112px'
                        type='file'
                        width='112px'
                        style={{ display: 'none' }}
                        name={name1}
                        id='multiFiles'
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            {!readOnly.col1 && <Box mt={'-40px'} ml={'70px'} sx={{
                "& svg": {
                    width: "40px",
                    height: "40px"
                }
            }}>
                <label htmlFor='multiFiles' style={{ cursor: 'pointer' }}>
                    <EditProfileIcon />{' '}
                </label>
            </Box>}

            <Box mt={'48px'}>
                <TextInput placeholder={placeholder1} title={column1} onChange={handleFormChange} value={value2} name={name2} inputProps={{ readOnly: readOnly.col2 }} />
                <ErrorTextBox>
                    {formErrors[column1]}
                </ErrorTextBox>
            </Box>
            <Box
                mt={'20px'}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        color: colors.gray[500],
                    },
                }}
            >
                <TextInput placeholder={placeholder2} title={column2} value={value3} inputProps={{ readOnly: readOnly.col3 }} name={name3} />
                <ErrorTextBox>
                    {formErrors[column2]}
                </ErrorTextBox>
            </Box>
            <Box mt={'20px'}>
                <TextInput
                    inputProps={{ readOnly: readOnly.col4 }}
                    placeholder={placeholder3}
                    title={column3}
                    onChange={handleFormChange}
                    value={value4}
                    name={name4}
                />
                <ErrorTextBox>
                    {formErrors[column3]}
                </ErrorTextBox>
            </Box>
            <Box sx={isTextInput ? {
                "& .ck-editor ": {
                    backgroundColor: colors.dark[600],
                    padding: "20px",
                    borderRadius: "10px",
                    color: "white"
                },
                ".ck.ck-editor__main>.ck-editor__editable": {
                    backgroundColor: "transparent",
                    border: 0
                },
                "& .ck-editor__top ": {
                    display: "none"
                },

                "& .ck-content p ": {
                    background: "none",
                    border: "none",
                    marginTop: "0 !important",
                    marginBottom: "15px !important"
                },
                "& .ck-content p a": {
                    color: "#A175FF !important",
                    textDecoration: "none"
                },
                "& .ck-content p:last-child ": {
                    marginBottom: "0 !important"
                }
            } : {}} mt={'20px'}>
                {isTextInput ?
                    <>
                        <Typography
                            sx={{ color: colors.gray[300] }}
                            variant='body2'
                            lineHeight='140%'
                            mb='6px'
                            textAlign='left'
                            textTransform='capitalize'
                        >
                            {column4}
                        </Typography>

                        <CkEditoComponent data={value5} setData={setCkEditor} name={name5} placeholder={placeholder4} />
                         </> :
                    <TextInput
                        inputProps={{ readOnly: readOnly.col5 }} placeholder={placeholder4} title={column4} onChange={(e: any) => 
                            setCkEditor(e.target.value)} value={value5} name={name5}
                    />
                }
                <ErrorTextBox>
                    {formErrors[column4]}
                </ErrorTextBox>
            </Box>

            <Box mt={'40px'} sx={{pb:{sm:"20px", xs:"0"}}}>
                <Button startIcon={buttonSvg} sx={buttonStyles ? buttonStyles : { width: '100%' }} onClick={handleFormSubmit}>
                    {buttonName}
                </Button>
            </Box>
            {isViewCancelButton && <Box mt={'10px'} pb={'20px'}>
                <Typography className="cursor-pointer" variant="body2" sx={{ color: colors.basics.primary }} onClick={() => cancelButtonClick()}>Cancel</Typography>
            </Box>}
        </Box>
    )
}

export default ProfileEdit