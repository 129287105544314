import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastMessage } from '../../helpers/ToastMessage';
import { instance } from '../../api/services/axiosClient';
import { setLoading } from '../../redux/features';
import { useDispatch } from 'react-redux';
import { baseURL } from '../../api/url';
import { Box } from '@mui/material';
import { colors } from '../../styles/color';
import * as yup from 'yup';
import Image from './Image';
import { ImageIcon, MoreOption, SendIcon, VideoIcon, FileTypeIcon } from '../../assets/icons';
import ChatFilesDisplayContent from './ChatFilesDisplayContent';
import AddLink from './AddLinks';
import LinkImage from '../../assets/images/Link.png';
import { isValidUrl } from '../../utils/commanFunctions/commanFunctions';
import CkEditoComponent from './CkEditorComponent';

const ReplyChat = (props: any) => {
  const { fetchList, profileImage } = props;
  const [inputText, setInputText] = useState('');
  const [openLink, setOpenLink] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [url, setUrl] = useState('');
  const [formErrorsUrl, setFormErrorsUrl] = useState('');
  const [multipleUrls, setMultipleUrls] = useState<any>([]);

  const urlSchema = yup.object().shape({
    url: yup.string().required('Please enter Url'),
  });

  const params = useParams();
  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: any = e.target.files;
    let isValid: boolean = true;

    for (var i = 0; i < fileList.length; i++) {
      const fileSize = fileList[i].size;
      var fileSizes = Math.round(fileSize / 1024);
      if (fileSizes > 1024 * 5) {
        isValid = false;
      }
    }

    if (isValid) {
      setFiles(Array.prototype.slice.call(fileList));
    } else {
      ToastMessage({
        type: 'error',
        message: 'Please select a file less than 5MB.',
      });
    }
  };

  const linkModalClose = () => {
    setOpenLink(false);
    setUrl('');
    setFormErrorsUrl('');
  };

  const linkSuccess = () => {
    const obj = {
      url: url,
    };
    urlSchema
      .validate(obj, { abortEarly: false })
      .then(async () => {
        if (isValidUrl(url)) {
          const urlArr = [...multipleUrls];
          urlArr.push(url);
          setMultipleUrls(urlArr);
          setOpenLink(false);
          setUrl('');
          setFormErrorsUrl('');
        } else {
          setFormErrorsUrl('Enter Valid Url');
        }
      })
      .catch((err: any) => {
        const errors: any = {};
        err?.inner?.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrorsUrl(errors.url);
      });
  };

  const scrollToBottom = () => {
    var objDiv = document.getElementById('boxData');
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  };

  const handleDeleteClick = (index: any) => {
    const newUploadedFiles = [...files];
    newUploadedFiles.splice(index, 1);
    setFiles(newUploadedFiles);
  };

  const handleSendClick = () => {
    const formData: any = new FormData();

    if (files.length > 0) {
      files.map((f, i) => {
        formData.append(`attachment[${i}]`, f);
        return f;
      });
    }
    if (multipleUrls.length > 0) {
      formData.append(`link`, multipleUrls.toString());
    }

    formData.append('id', params.id || '');
    formData.append('message', inputText);

    if (files.length > 5) {
      ToastMessage({
        type: 'error',
        message: 'Only 5 Attachment at a time',
      });
      dispatch(setLoading(false));
    } else if (inputText.length === 0) {
      ToastMessage({
        type: 'error',
        message: 'Message Required',
      });
      dispatch(setLoading(false));
    } else if (multipleUrls.length > 1) {
      ToastMessage({
        type: 'error',
        message: 'Only 1 url send at a time',
      });
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
      instance
        .post(baseURL + '/inquiry-message-send', formData)
        .then((res: any) => {
          if (res.data.status) {
            fetchList();
            setInputText('');
            setFiles([]);
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          } else {
            ToastMessage({
              type: 'error',
              message: res.data.message,
            });
            dispatch(setLoading(false));
          }
        })
        .catch((e: any) => {
          ToastMessage({
            type: 'error',
            message: e.message,
          });
          dispatch(setLoading(false));
        });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendClick();
    }
  };

  const handleDeleteUrl = (index: any) => {
    const newUploadedFiles = [...multipleUrls];
    newUploadedFiles.splice(index, 1);
    setMultipleUrls(newUploadedFiles);
  };

  return (
    <Box sx={{ bottom: 0, position: 'sticky', mt: 'auto' }}>
      <Box
        sx={{
          background: colors.dark[600],
          borderRadius: '10px',
          '& .MuiOutlinedInput-input': {
            pl: '10px',
          },
          '& .MuiTextField-root ': {
            maxHeight: 'none',
          },
        }}
      >
        <Box
          sx={{
            '& .ck-editor ': {
              backgroundColor: colors.dark[600],
              padding: '10px',
              borderRadius: '10px',
              color: 'white',
            },
            '.ck.ck-editor__main>.ck-editor__editable': {
              backgroundColor: 'transparent',
              border: 0,
            },
            '& .ck-editor__top ': {
              display: 'none',
            },

            '& .ck-content p ': {
              background: 'none',
              border: 'none',
              marginTop: '0 !important',
              marginBottom: '15px !important',
            },
            '& .ck-content p a': {
              color: '#A175FF !important',
              textDecoration: 'none',
            },
            '& .ck-content p:last-child ': {
              marginBottom: '0 !important',
            },
          }}
          mt={'20px'}
        >
          {
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 10px 10px 15px',
                position: 'relative',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box pr={'14px'} height='36px' width='36px'>
                  <Image
                    width={36}
                    height={36}
                    src={profileImage}
                    style={{ objectFit: 'cover' }}
                    alt='user-img'
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  maxHeight: '190px',
                  overflow: 'auto',
                  marginRight: '20px',
                  '& p': { wordBreak: 'break-word' },
                  '& .ck-editor': {
                    paddingLeft: '5px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#FFFFFF',
                  },
                  '& .ck.ck-editor__editable_inline': { padding: '0' },
                }}
              >
                <CkEditoComponent
                  data={inputText}
                  setData={setInputText}
                  name={'inputText'}
                  placeholder={'Add a Reply...'}
                />
              </Box>
              <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                <Box onChange={handleFileInputChange}>
                  <label htmlFor='multiFiles' style={{ cursor: 'pointer' }}>
                    <Box display={'flex'}>
                      {' '}
                      <Box>
                        <MoreOption />
                      </Box>
                      <Box display={files.length > 0 ? 'flex' : 'none'}>
                        <Box
                          sx={{
                            background: colors.basics.primary,
                            borderRadius: '50px',
                            width: '17px',
                            height: '17px',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '10px',
                            display: 'flex',
                            fontWeight: 800,
                            color: colors.basics.white,
                            m: '-6px',
                          }}
                        >
                          {files.length}
                        </Box>
                      </Box>
                    </Box>
                  </label>
                </Box>
                <Box className='cursor-pointer' sx={{ padding: '0 20px' }}>
                  <Image
                    onClick={() => {
                      setOpenLink(true);
                    }}
                    src={LinkImage}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      transform: 'translate(0%, -118%)',
                      width: { xs: '248px', sm: '290px' },
                      borderRadius: '8px',
                      '& > .MuiBox-root': {
                        border: '1px solid #3e3e3e',
                      },
                    }}
                  >
                    {openLink && (
                      <AddLink
                        label={'Link Url'}
                        error={formErrorsUrl}
                        value={url}
                        onChange={(e: any) => setUrl(e.target.value)}
                        onSuccess={() => {
                          linkSuccess();
                        }}
                        onClose={() => linkModalClose()}
                      />
                    )}
                  </Box>
                </Box>
                <Box display='none'>
                  <input onChange={handleFileInputChange} multiple type='file' id='multiFiles' />
                </Box>
                <Box pr={'7px'} onClick={handleSendClick}>
                  <SendIcon />
                </Box>
              </Box>
            </Box>
          }
        </Box>
        {files.length > 0 ? (
          <Box
            borderTop={files.length > 0 ? `1px solid ${colors.dark[100]}` : ''}
            display='flex'
            sx={{
              width: '100%',

              overflowY: 'hidden',
              '&::-webkit-scrollbar ': {
                height: '3px',
              },
              '&::-webkit-scrollbar-track': {
                color: 'red',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '10px',
              },
            }}
          >
            {files?.map((file: any, index) => {
              let extension = file.type.split('/');
              let icon;
              if (['png', 'jpg', 'jpeg', 'svg', 'webp'].includes(extension[extension.length - 1]))
                icon = <ImageIcon />;
              else if (['mp4', 'mp3', 'wav'].includes(extension[extension.length - 1])) icon = <VideoIcon />;
              else icon = <FileTypeIcon />;
              return (
                <ChatFilesDisplayContent
                  image={icon}
                  name={file.name}
                  handleDeleteClick={(index: any) => handleDeleteClick(index)}
                  index={index}
                />
              );
            })}
          </Box>
        ) : null}

        <Box
          borderTop={multipleUrls.length > 0 ? `1px solid ${colors.dark[100]}` : ''}
          display='flex'
          sx={{
            width: '100%',

            overflowY: 'hidden',
            '&::-webkit-scrollbar ': {
              height: '3px',
            },
            '&::-webkit-scrollbar-track': {
              color: 'red',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
            },
          }}
        >
          {multipleUrls.length > 0 &&
            multipleUrls.map((file: any, index: any) => {
              return (
                <ChatFilesDisplayContent
                  image={<Image src={LinkImage} />}
                  name={file}
                  handleDeleteClick={(index: any) => handleDeleteUrl(index)}
                  index={index}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default ReplyChat;
