import { Box, Typography } from "@mui/material"
import Topbar from "./Topbar"
import { ArrowLeftGray } from "../../assets/icons"
import { colors } from "../../styles/color"

const BreadCrumb = (props: any) => {
  const { name1, name2, onClick, isClickable, name3, isClickable2, onClick2 } = props
  return (
    <Topbar>
      <Box
        display={'flex'}
        alignItems={'center'}
        textAlign={'center'}
        width='100%'
        sx={{ pl: { xl: 0, xs: '6px' } }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box margin={"auto 15px auto auto"} >
            <Typography sx={{ cursor: isClickable ? "pointer" : "" }} onClick={(e) => {
              if (isClickable) {
                onClick(e)
              }
            }} variant='body1' fontSize={{ xs: "14px", sm: '18px' }} lineHeight='27px' color={colors.gray[300]}>
              {name1}
            </Typography>
          </Box>
          {name2 && <>
            <Box width={{ xs: "7px", sm: '10px' }} display={'flex'} alignItems={'center'} mr={'15px'}>
              <ArrowLeftGray />
            </Box>
            <Box>
              <Typography onClick={(e) => {
                if (isClickable2) {
                  onClick2(e)
                }
              }} variant='body1' sx={{ cursor: isClickable2 ? "pointer" : "" }} margin={"auto"} fontSize={{ xs: "14px", sm: '18px' }} lineHeight='27px'>
                {name2}
              </Typography>
            </Box>
          </>}
          {name3 && <>
            <Box width={{ xs: "7px", sm: '10px' }} display={'flex'} alignItems={'center'} m={" 0 15px"} >
              <ArrowLeftGray />
            </Box>
            <Box>
              <Typography variant='body1' fontSize={{ xs: "14px", sm: '18px' }} lineHeight='27px'>
                {name3}
              </Typography>
            </Box>
          </>}
        </Box>
      </Box>
    </Topbar>
  )
}

export default BreadCrumb